<template>
  <div :class="navid == 1 && page == 1 && type == 1 && is_grey == 1 ? 'bgh' : ''">
    <!-- 改首页 -->
    <!-- top -->
    <div class="shopTop flex-c a-center fs-12 cup" v-if="navid == 1 && istopnav">
      <ul>
        <li
          class="flex-c a-center j-center"
          :class="cNumTop != index ? 'col-FF0000' : 'bg-ff0000 col-ffffff'"
          v-for="(item, index) in topList"
          :key="index + 'top1'"
          @click="handleNumTop(index)"
        >
          <span v-if="item.t1">{{ item.t1 }}</span>
          <span v-if="item.t2">{{ item.t2 }}</span>
          <span v-if="item.t3">{{ item.t3 }}</span>
        </li>
        <li
          class="flex-c a-center j-center"
          :class="cNumTop != topList.length ? 'col-FF0000' : 'bg-ff0000 col-ffffff'"
          @click="handleNumTop(topList.length)"
        >
          <div>Top</div>
          <div>^</div>
        </li>
      </ul>
    </div>

    <!-- 转店、找店、banner、案例、客户 -->
    <div class="flex max-w1200 mrauto mt30" v-if="navid == 1">
      <div class="per-w100 mr10 cup flex">
        <!-- 转店、找店 -->
        <div class="per-w100 h371 per-w24 mr10 bor-DADADA">
          <ul class="per-w100 flex col-333333 borb-d43030">
            <li
              class="per-w50 h46 flex j-center a-center"
              v-for="(item, index) in find"
              :key="index + 'find'"
              :class="findShop == index ? 'bg-E6E6E6' : ''"
              @click="handlezhaodian(index)"
            >
              {{ item }}
            </li>
          </ul>
          <div class="pt31b46lr16 fs-12">
            <div class="flex a-center j-center per-w100">
              <span>区&emsp;域：</span>
              <!-- <input
                style="outline: none"
                class="fs-12 bor-DADADA w148h38 text-center"
                placeholder="请选择"
              /> -->
              <div class="w148h38">
                <el-select placeholder="请选择" v-model="region">
                  <el-option
                    v-for="item in areaList"
                    :key="item.city_area + 'area'"
                    :label="item.city_area ? item.city_area : '不限'"
                    :value="item.city_area"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="flex a-center j-center per-w100 mt12">
              <span>面&emsp;积：</span>
              <!-- <input
                style="outline: none"
                class="fs-12 bor-DADADA w148h38 text-center"
                placeholder="请选择"
              /> -->
              <div class="flex a-center">
                <input
                  type="number"
                  placeholder="最小面积"
                  name=""
                  v-model="area_min"
                  class="w64h27 ip-bor fs-12 col-333333 plr4 bor-DADADA"
                />
                ~
                <input
                  type="number"
                  placeholder="最大面积"
                  name=""
                  v-model="area_max"
                  class="w64h27 ip-bor fs-12 col-333333 plr4 bor-DADADA"
                />
              </div>
            </div>
            <div class="flex a-center j-center per-w100 mt12">
              <span>行&emsp;业：</span>
              <!-- <input
                style="outline: none"
                class="fs-12 bor-DADADA w148h38 text-center"
                placeholder="请选择"
              /> -->
              <div class="w148h38">
                <el-cascader
                  v-model="classify_twoarr"
                  :options="industryList"
                  :props="{
                    expandTrigger: 'hover',
                    value: 'id',
                    label: 'name',
                    children: 'child',
                  }"
                  @change="handleChange"
                ></el-cascader>
              </div>
            </div>
            <!-- <div class="flex a-center j-center per-w100 mt12">
              <span>关键词：</span>
              <input
                style="outline: none"
                class="fs-12 bor-DADADA w148h38 text-center"
                placeholder="请选择"
                type="text"
                v-model="keyword"
              />
            </div> -->

            <div class="flex a-center j-center mt22 mb15">
              <div
                class="w207h38 l-h38 col-ffffff bg-ff0000 flex a-center j-center fs-14 cup"
                @click="matchingClick"
              >
                快速精确匹配
              </div>
            </div>
            <div class="flex a-center j-center mb15">
              <div
                class="w207h38 l-h38 col-ffffff bg-999999 flex a-center j-center fs-14 cup"
                @click="WinportClick4"
              >
                重置
              </div>
            </div>
          </div>
        </div>
        <!-- banner -->
        <div class="per-w47 cup">
          <el-carousel height="23.1875rem">
            <el-carousel-item v-for="(item, index) in index_ad" :key="index + 'banner'">
              <div class="h371 per-w100 por">
                <img
                  :src="item.img"
                  alt=""
                  class="h371 per-w100"
                  @click="bannercut(item)"
                />
                <div class="bannertitle" v-if="item.title">
                  {{ item.title}}
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <!-- 案例、客户 -->
        <div class="per-w27 ml10 h371 bor-DADADA">
          <div class="borb-DADADA h178">
            <div class="h50 l-h50 plr15" @click="goSuccessList">已成交案例</div>
            <div class="plr15 h96 ovflow-h">
              <ul class="fs-12 col-666666 per-w100" id="bargain">
                <li
                  class="flex a-center j-sb h24 l-h24"
                  v-for="(item, index) in bargain"
                  :key="index + 'bargin'"
                  @click="detailsCut(item)"
                  @mouseover="hoverbargain"
                  @mouseout="leavebargain"
                >
                  <div class="per-w70 ft-1 hover-cc0000 cup">{{ item.title }}</div>
                  <div class="per-w30 ft-1">
                    {{ item.clinch_time_text ? item.clinch_time_text : "" }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="" style="height: calc(11.625rem - 1.25rem)">
            <div class="mt20 ml20 mr20">已开通客户</div>
            <div class="mt17 ml20 mr20 ovflow-h h133">
              <ul class="per-w100 h133 fs-12 col-666666" id="openUser">
                <li
                  class="flex a-center mb30"
                  v-for="(item, index) in openUser"
                  :key="index + 'open'"
                  @click="detailsCut(item)"
                  @mouseover="hoveropenUser"
                  @mouseout="leaveopenUser"
                >
                  <div class="wh38 mr10">
                    <img src="../../assets/img/huojian.png" alt="" class="wh38" />
                  </div>
                  <div class="flex a-center j-sb flex1">
                    <div class="per-w70">
                      <!-- <div class="col-333333 fs-14">
                        {{ item.name + " " + item.mobile }}
                      </div> -->
                      <div class="fs-12 col-333333 ft-2 per-w100 hover-cc0000 cup">
                        {{ item.title }}
                      </div>
                    </div>
                    <div class="fs-12 col-666666 per-w30 ft-2">
                      {{ item.create_time_text ? item.create_time_text : "" }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 立即咨询 -->
    <ul class="max-w1200 mrauto ptb20 bg-F4F4F4 flex mt14" v-if="navid == 1">
      <li
        class="per-w20 flex-c a-center j-sb"
        :class="index != contactlist.length - 1 ? 'borr-DADADA' : ''"
        v-for="(item, index) in contactlist"
        :key="index + 'contact'"
      >
        <div class="wh30 mrauto mb10">
          <img :src="item.icon" alt="" class="wh30" />
        </div>
        <div class="mb20 fs-18 fwb text-center">{{ item.title }}</div>
        <div class="fs-14 text-center col-666666 plr15">{{ item.text }}</div>
        <div class="fs-14 text-center col-666666">{{ item.phone }}</div>
        <div
          class=" cup bg-ff0000 col-ffffff fs-14 flex a-center j-center mrauto mt23 br100"
          :class="isMobile()?'w120h29':'w96h29'"
        >
          <div class="flex a-center text-nowrap" @click="tocall(item)">
            <div class="wh20">
              <img src="../../assets/img/icon_phone.png" alt="" class="wh20" />
            </div>
            立即咨询
          </div>
        </div>
      </li>
    </ul>
    <!-- 重点推荐 -->
    <div id="zdtj" class="max-w1200 mrauto mt30" v-if="navid == 1">
      <div class="flex per-w100 por borb-DADADA">
        <div class="fs-25 pb9">重点推荐</div>
        <div class="borb2-d43030 poa-lb"></div>
      </div>
      <div class="per-w100 flex j-sb a-center bor-DADADA mt10 fs-14">
        <ul class="per-w80 ptb10lr30 flex f-w ml30 col-333333">
          <li
            class="text-left per-w16 flex a-center l-h37 h37 cup"
            v-for="(item, index) in recomnav"
            :key="index + 'recomnav'"
            @click="changerecomnav(item.id, index)"
          >
            <span class="per-w80 ft-1 hover-cc0000">{{ item.name }}</span>
          </li>
        </ul>
        <div class="mr30 col-666666 cup" @click="changerecomnav('', index)">更多></div>
      </div>
      <div class="per-w100 mt17">
        <!-- <ul class="per-w100 flex f-w">
          <li
            class="per-w20 mb20"
            v-for="item in recomlist"
            :key="item.id"
            @click="detailsCut(item)"
          >
            <div class="w224h170 por">
              <img class="w224h170" :src="item.img" alt="" />
              <div
                class="poa-lb bg-000000a5 per-w100 h32 l-h32 fs-12 col-ffffff text-center ft-1"
              >
                {{ item.title }}
              </div>
            </div>
            <div class="borblr-efefef w198h96 ptb0lr12 col-666666 fs-14">
              <div class="h32">
                <span class="l-h32 h32"
                  ><span class="col-D8252D">{{ item.area }}</span
                  ><span>平米 </span></span
                >
                <span class="l-h32 h32">
                  <span>{{ item.rent_price==0?'租金面议':item.rent_price }}</span>
                  <span v-if="item.rent_price">
                    <span>{{ item.money_unit }}/</span
                    ><span v-if="item.rent_time == 1">年</span
                    ><span v-if="item.rent_time == 2">月</span
                    ><span v-if="item.rent_time == 3">平方</span>
                  </span>
                </span>
                <span class="l-h1-6 h32">
                  <span class="col-D8252D"> {{ 0 }}</span>
                  <span>人关注</span>
                </span>
              </div>
              <div class="">
                <div class="l-h1-6">
                  区域：{{ item.city_area }} {{ item.street }}
                </div>
                <div class="ft-2">适合经营：{{ String(item.fit_industry) }}</div>
              </div>
            </div>
          </li>
        </ul> -->
        <el-carousel trigger="click" :height="isMobile()?'39.625rem':'35.625rem'" indicator-position="none">
          <el-carousel-item
            v-for="(m, index) in Math.ceil(recomAll.length / 10)"
            :key="m + 'recomAll'"
          >
            <ul class="per-w100 flex f-w">
              <li
                class="per-w20 flex-c a-center mb20"
                v-for="item in recomAll.slice(index * 10).length <= 10
                  ? recomAll.slice(index * 10)
                  : recomAll.slice(index * 10, (index + 1) * 10)"
                :key="item.id + 'recom'"
                @click="detailsCut(item)"
              >
                <div class="per-w92 h170 por">
                  <div class="per-w100 h170 ovflow-h">
                    <img class="per-w100 h170 hover-img" :src="item.custom_type==99 ? item.cover : item.img" alt="" />
                  </div>
                  <div
                    class="poa-lb bg-000000a5 per-w100 h32 l-h32 fs-12 col-ffffff text-center ft-1"
                  >
                    {{ item.title }}
                  </div>
                </div>
                <div class="borblr-efefef per-w80 flex1 ptb0lr12 col-666666 fs-14" v-if="item.custom_type==99">
                  <div class="ft-4" style="padding-top:0.5rem;line-height:1.2rem;white-space: break-spaces;word-break: break-all;">
                    {{item.describe}}
                  </div>
                </div>
                <div class="borblr-efefef per-w80 flex1 ptb0lr12 col-666666 fs-14" v-else>
                  <div class="h32 ft-1">
                    <span class="l-h32 h32"
                      ><span class="col-D8252D">{{ item.area }}</span
                      ><span>平米 </span></span
                    >
                    <span class="l-h32 h32">
                      <span>{{
                        item.rent_price == 0 ? "租金面议" : item.rent_price
                      }}</span>
                      <span v-if="item.rent_price">
                        <span>{{ item.money_unit }}/</span
                        ><span v-if="item.rent_time == 1">年</span
                        ><span v-if="item.rent_time == 2">月</span
                        ><span v-if="item.rent_time == 3">平方</span>
                      </span>
                    </span>
                    <!-- <span class="l-h1-6 h32">
                      <span class="col-D8252D"> {{ 0 }}</span>
                      <span>人关注</span>
                    </span> -->
                  </div>
                  <div class="">
                    <div class="l-h1-6 ft-1">区域：{{ item.city_area }} {{ item.street }}</div>
                    <div class="ft-2">适合经营：{{ String(item.fit_industry) }}</div>
                  </div>
                </div>
              </li>
            </ul>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="per-w100 flex j-center a-center pt17b30lr0 moreBtn">
        <div
          class="bor-DADADA ptb0lr20 h42 l-h42 br20 cup hover-cc0000"
          @click="changerecomnav('', 0)"
        >
          更多商铺转让/出租招商信息
        </div>
      </div>
      <div class="per-w100 h80 bg-9B8DB0 flex a-center">
        <img class="w400 h80" src="../../assets/img/li.png" alt="" />
        <div class="flex1 fs-40 col-ffffff flex a-center j-center">
          店铺经营 一站式全流程搞定！
        </div>
      </div>
    </div>
    <!-- 生意转让 -->
    <div id="syzr" class="max-w1200 mrauto mt30" v-if="navid == 1">
      <div class="per-w100 flex j-sb pb20">
        <div class="flex1">
          <div class="flex per-w100 por borb-DADADA">
            <div class="fs-25 pb9">生意转让</div>
            <div class="borb2-d43030 poa-lb"></div>
          </div>
          <ul class="pt25 per-w100 flex f-w">
            <li
              :class="index % 2 == 1 ? 'h95 per-w47 ml20' : 'per-w47 h95'"
              v-for="(item, index) in classList"
              :key="item.id + 'class'"
            >
              <div class="flex1 h95 flex j-sb a-center">
                <div class="flex1 h86 borb-DADADA flex j-sb">
                  <!-- <span class="w60 h25 l-h25 col-333333 pl25 fs-12 fwb">{{item.name}}</span> -->
                  <div class="w85 h25 l-h25 flex a-center">
                    <img class="wh17 mr5" :src="item.child[0].img" alt="" />
                    <span class="w60 h25 l-h25 col-333333 fs-12 fwb cup">{{
                      item.name
                    }}</span>
                  </div>

                  <ul class="flex1 h75 flex f-w ovflow-h">
                    <li
                      class="plr5 h25 l-h25 col-333333 fs-12 cup hover-cc0000"
                      v-for="(ite, ind) in item.child"
                      :key="ind + 'classchild'"
                      @click="classclick(item.id, ite.id)"
                    >
                      <!-- @click="changenav2(item.id,index,ite.id,ite.name)" -->
                      {{ ite.name }}
                    </li>
                    <li
                      class="plr5 h25 l-h25 fs-12 col-666666 cup"
                      @click="changerecomnav(item.id, 0)"
                    >
                      更多>
                    </li>
                  </ul>
                </div>
                <div class="w100h75 ovflow-h">
                  <img class="w100h75 hover-img" :src="item.img" />
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="w288">
          <!-- 品牌合作伙伴 -->
          <!-- <div class="pt12b0lr5 mb14 bor-DADADA">
            <div class="flex j-sb a-center por borb-DADADA">
              <div class="fs-25 pb9">品牌合作伙伴</div>
              <div class="fs-14">更多></div>
              <div class="borb2-d43030 poa-lb"></div>
            </div>
            <ul class="per-w100 pt18 flex f-w j-sb">
              <li class="h84 plr5" v-for="(item,index) in 8" :key="index">
                <div class="w126 h72 bor-DADADA">
                  <img class="w126 h72" :src="item.img" />
                </div>
              </li>
            </ul>
          </div> -->
          <!-- 店铺动态 -->
          <div class="pt12b0lr5 bor-DADADA">
            <div class="flex j-sb a-center por borb-DADADA">
              <div class="fs-25 pb9">店铺动态</div>
              <div class="fs-14 cup" @click="morerecomnav">更多></div>
              <div class="borb2-d43030 poa-lb"></div>
            </div>
            <ul class="w260 pt17b12lr8">
              <li
                class="flex j-sb w260 h96"
                v-for="item in shopShow.slice(0, 4)"
                :key="item.id + 'show'"
                @click="detailsCut(item)"
              >
                <img class="w114h76" :src="item.img" alt="" />
                <div class="w130h76">
                  <div class="fs-14 l-h24 ft-2 hover-cc0000 cup">{{ item.title }}</div>
                  <!-- <div class="fs-12 h20 l-h20 col-666666" :class="isMobile()?'mt10':'mt5'">
                    {{ item.create_time_text }}
                  </div> -->
                </div>
              </li>
              <li
                class="flex a-center j-sb w260 h30 l-h30"
                v-for="item in shopShow.slice(4, 14)"
                :key="item.id + 'shop'"
                @click="detailsCut(item)"
              >
                <span class="fs-12 col-666666 ft-1  hover-cc0000 cup">{{
                  "· " + item.title
                }}</span>
                <!-- <span class="fs-12 col-666666" :class="isMobile()?'per-w30 ft-1':''">{{ item.create_time_text }}</span> -->
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="per-w100 h80 bg-9B8DB0 flex a-center">
        <img class="w400 h80" src="../../assets/img/li.png" alt="" />
        <div class="flex1 fs-40 col-ffffff flex a-center j-center">
          店铺经营 一站式全流程搞定！
        </div>
      </div>
    </div>
    <!-- 写字楼/厂房/库房 -->
    <div id="xzl" class="max-w1200 mrauto mt30" v-if="navid == 1">
      <div class="flex per-w100 por borb-DADADA">
        <div class="fs-25 pb9">写字楼/厂房/库房</div>
        <div class="borb2-d43030 poa-lb"></div>
      </div>
      <div class="per-w100 flex a-center bor-DADADA mt10 fs-14">
        <ul class="per-w100 ptb10lr30 flex f-w ml30 col-333333">
          <li
            class="text-left per-w6 flex a-center l-h37 h37 cup"
            v-for="(item, index) in roomnav"
            :key="index + 'roomnav'"
            @click="tocutCity(item.city_area)"
          >
            <span class="per-w80 ft-1 hover-cc0000">{{ item.city_area }}</span>
          </li>
          <li
            class="text-left per-w6 flex a-center l-h37 h37 col-666666 cup"
            @click="tocutCity('不限')"
          >
            更多>
          </li>
        </ul>
      </div>
      <div class="per-w100 mt17">
        <ul class="per-w100 flex f-w pt17">
          <li
            class="h170 per-w15"
            v-for="(item, index) in roomlist"
            :key="item.id + 'room'"
            :class="index % 6 == 5 ? '' : 'mr16'"
            @click="detailsCut(item)"
          >
            <div class="per-w100 h126 ovflow-h cup">
              <img class="per-w100 h126 hover-img" :src="item.img" alt="" mode="" />
            </div>
            <div class="text-center h35 l-h35 per-w100 ft-1 fs-12 hover-cc0000 cup">
              {{ item.title }}
            </div>
          </li>
        </ul>
      </div>
      <div class="per-w100 flex j-center a-center pt17b30lr0 moreBtn">
        <div
          class="bor-DADADA ptb0lr20 h42 l-h42 br20 cup hover-cc0000"
          @click="tocutCity('不限')"
        >
          更多写字楼/厂房/库房信息
        </div>
      </div>
      <div class="per-w100 h80 bg-9B8DB0 flex a-center">
        <img class="w400 h80" src="../../assets/img/li.png" alt="" />
        <div class="flex1 fs-40 col-ffffff flex a-center j-center">
          店铺经营 一站式全流程搞定！
        </div>
      </div>
    </div>
    <!-- 找店选址 -->
    <div id="zdxz" class="max-w1200 mrauto mt30" v-if="navid == 1">
      <div class="flex per-w100 por borb-DADADA">
        <div class="fs-25 pb9">找店选址</div>
        <div class="borb2-d43030 poa-lb"></div>
      </div>
      <div class="per-w100 flex j-sb a-center bor-DADADA mt10 fs-14">
        <ul class="per-w80 ptb10lr30 flex f-w ml30 col-333333">
          <li
            class="text-left per-w16 flex a-center l-h37 h37 cup"
            v-for="(item, index) in recomnav"
            :key="index + 'zdxz'"
            @click="changefindnav(item.id)"
          >
            <span class="per-w80 ft-1 hover-cc0000">{{
              index == 0 ? item.name : "找" + item.name
            }}</span>
          </li>
        </ul>
        <div class="mr30 col-666666 cup" @click="morefindnav">更多></div>
      </div>
      <div class="per-w100 pt13 flex j-sb">
        <div class="w242h384 pt34b0lr18 bor-DADADA">
          <div class="w242 h180 bordb-DADADA flex-c a-center col-666666">
            <div class="h63 l-h63 fs-44 col-D8252D">
              {{ all_find_shop_nums }}
            </div>
            <div class="fs-18 l-h1-8 h48">找店客户数（位）</div>
            <div class="h37 l-h37">
              新增
              <span class="bg-ff0000 fs-30 col-ffffff">{{ today_find_shop_nums }}</span>
              条新找店信息
            </div>
          </div>
          <div class="mt15 per-w100 ovflow-h h165">
            <ul class="per-w100" id="findshow">
              <li
                class="flex j-sb a-center"
                v-for="(item, index) in findshow"
                :key="index + 'findshow'"
                @click="detailsCut(item)"
                @mouseover="hoverfindshow"
                @mouseout="leavefindshow"
              >
                <div class="h32 l-h32 fs-14 col-333333 per-w70 ft-1 hover-cc0000 cup">
                  {{ item.title }}
                </div>
                <div class="h32 l-h32 fs-12 col-666666 per-w30 ft-1">
                  {{ item.create_time_text }}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <ul class="w920 flex j-sb f-w">
          <li
            class="per-w33 h220 pl24"
            v-for="item in find_list"
            :key="item.id + 'findlist'"
            @click="detailsCut(item)"
          >
            <div class="per-w100 h198 bor-DADADA cup">
              <div class=" pt20b0lr20 h128 fs-14 col-666666" :class="isMobile()?'l-h24':'l-h1-8'">
                <div class="ft-1">{{ item.name }}，正在找{{ item.classify_name }}</div>
                <div class="ft-1">面积最好在<span class="col-D8252D">{{ item.area_min }}~{{ item.area_max }}{{ item.area_unit_name }}</span>之间。</div>
                <div class="" :class="isMobile()?'ft-1':''">{{ item.title }}</div>
                <div class="col-333333" :class="isMobile()?'ft-1':''">电话：{{ item.phone }}</div>
              </div>
              <div class="bg-F4F4F4 ptb0lr20 h50 flex a-center j-sb">
                <div></div>
                <div
                  class=" h25 l-h25 mt13 text-center fs-14 col-ffffff bg-d8252d br15"
                  :class="isMobile()?'w80':'w65'"
                >
                  邀约TA
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="per-w100 flex j-center a-center pt17b30lr0 moreBtn">
        <div
          class="bor-DADADA ptb0lr20 h42 l-h42 br20 cup hover-cc0000"
          @click="morefindnav"
        >
          更多找店选址信息
        </div>
      </div>
      <div class="per-w100 h80 bg-9B8DB0 flex a-center">
        <img class="w400 h80" src="../../assets/img/li.png" alt="" />
        <div class="flex1 fs-40 col-ffffff flex a-center j-center">
          店铺经营 一站式全流程搞定！
        </div>
      </div>
    </div>
    <!-- 招商出售 -->
    <div id="zscs" class="max-w1200 mrauto mt30" v-if="navid == 1">
      <div class="per-w100 flex j-sb">
        <!-- 招商 -->
        <div class="w595">
          <div class="flex per-w100 por borb-DADADA">
            <div class="fs-25 pb9">招商</div>
            <div class="borb2-d43030 poa-lb"></div>
          </div>
          <div class="per-w100 flex a-center bor-DADADA mt10 fs-14">
            <ul class="per-w100 ptb15lr0 flex f-w col-333333">
              <li
                class="text-left flex a-center l-h25 h25 ptb0lr10 cup"
                v-for="(item, index) in sale_list_onenav"
                :key="index + 'onenav'"
                @click="saleonenav(item.city_area)"
              >
                <span class="hover-cc0000">{{ item.city_area }}</span>
              </li>
              <li
                class="text-left flex a-center l-h25 h25 ptb0lr10 col-666666 cup"
                @click="moresale_list"
              >
                更多>
              </li>
            </ul>
          </div>
          <div class="per-w100 mt17">
            <ul class="per-w100 flex f-w pt17">
              <li
                :class="index % 3 == 2 ? '' : 'mr20'"
                class="per-w33 h170"
                v-for="(item, index) in sale_list_one.slice(0, 9)"
                :key="item.id + 'zs'"
                @click="detailsCut(item)"
              >
                <div class="per-w100 h126 ovflow-h cup">
                  <img class="per-w100 h126 hover-img" :src="item.img" alt="" mode="" />
                </div>
                <div class="text-center h35 l-h35 per-w100 ft-1 fs-12 hover-cc0000 cup">
                  {{ item.title }}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- 出售 -->
        <div class="w595">
          <div class="flex per-w100 por borb-DADADA">
            <div class="fs-25 pb9">出售</div>
            <div class="borb2-d43030 poa-lb"></div>
          </div>
          <div class="per-w100 flex a-center bor-DADADA mt10 fs-14">
            <ul class="per-w100 ptb15lr0 flex f-w col-333333">
              <li
                class="text-left flex a-center l-h25 h25 ptb0lr10 cup"
                v-for="(item, index) in sale_list_twonav"
                :key="index + 'twonav'"
                @click="saletwonav(item.city_area)"
              >
                <span class="hover-cc0000">{{ item.city_area }}</span>
              </li>
              <li
                class="text-left flex a-center l-h25 h25 ptb0lr10 col-666666 cup"
                @click="moresale_list"
              >
                更多>
              </li>
            </ul>
          </div>
          <div class="per-w100 mt17">
            <ul class="per-w100 flex f-w pt17" :class="sale_list_two.length<3?'':'j-sb'">
              <li
                :class="sale_list_two.length<3?'mr20':''"
                class="per-w32 h170"
                v-for="(item) in sale_list_two.slice(0, 9)"
                :key="item.id + 'cs'"
                @click="detailsCut(item)"
              >
                <div class="per-w100 h126 ovflow-h cup">
                  <img class="per-w100 h126 hover-img" :src="item.img" alt="" mode="" />
                </div>
                <div class="text-center h35 l-h35 per-w100 ft-1 fs-12 hover-cc0000 cup">
                  {{ item.title }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- <div class="per-w100 flex j-center a-center pt17b30lr0">
        <div
          class="bor-DADADA ptb0lr20 h42 l-h42 br20 cup hover-cc0000"
          @click="moresale_list"
        >
          更多招商出售信息
        </div>
      </div> -->
      <!-- <div class="per-w100 h80 bg-9B8DB0 flex a-center">
        <img class="w400 h80" src="../../assets/img/li.png" alt="" />
        <div class="flex1 fs-40 col-ffffff flex a-center j-center">
          店铺经营 一站式全流程搞定！
        </div>
      </div> -->
    </div>
    <!-- 友情链接 -->
    <!-- <div class="max-w1200 mrauto mt30" v-if="navid == 1">
      <div class="flex per-w100 por borb-DADADA">
        <div class="fs-25 pb9">友情链接</div>
        <div class="borb2-d43030 poa-lb"></div>
      </div>
    </div> -->

    <!-- 改首页↑ -->

    <!-- 原首页广告 -->
    <!-- <div class="flex max-w1200 mrauto mt30" v-if="navid == 1">
      <div class="h371 per-w20 ovflow-y mr10 classlist">
        <el-popover
          placement="right-start"
          width="325"
          trigger="hover"
          v-for="(item, index) in classList"
          :key="index"
        >
          <div class="w325h300 pt5b15lr15 box-b ovflow-y">
            <ul class="flex f-w">
              <li
                class="per-w25 mb15 cup"
                v-for="(items, indexs) in item.child"
                :key="indexs"
                @click="classclick(item.id, items.id)"
              >
                <div class="wh50 mrauto">
                  <img :src="items.img" class="wh50" alt="" />
                </div>
                <div class="fs-12 text-center mt5">
                  {{ items.name }}
                </div>
              </li>
            </ul>
          </div>
          <div slot="reference">
            <div class="flex a-center fs-12 bg-fafafa bor-DADADA plr12 box2">
              <div class="wh20 mr10">
                <img :src="item.img" alt="" class="wh20" />
              </div>
              <div class="l-h40 fwb mr10">
                {{ item.name }}
              </div>
              <div class="l-h40">
                {{ item.child[0].name }}
              </div>
            </div>
          </div>
        </el-popover>
      </div>
      <div class="per-w60 mr10 cup">
        <el-carousel height="23.1875rem">
          <el-carousel-item v-for="(item, index) in list" :key="index">
            <img :src="item.img" alt="" class="h371 per-w100" @click="bannercut(item)" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="h371 per-w20 bor-DADADA ovflow-y ptb0lr16 screen">
        <div class="mb15 mt20">
          <div class="flex a-center">
            <div class="fs-12 mr5 w60">区域：</div>
            <el-select placeholder="请选择" v-model="region">
              <el-option
                v-for="item in areaList"
                :key="item.city_area"
                :label="item.city_area ? item.city_area : '不限'"
                :value="item.city_area"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="mb15" v-if="type == 1">
          <div class="flex a-center">
            <div class="fs-12 mr5 w60">行业：</div>
            <el-cascader
              v-model="classify_twoarr"
              :options="industryList"
              :props="{
                expandTrigger: 'hover',
                value: 'id',
                label: 'name',
                children: 'child',
              }"
              @change="handleChange"
            ></el-cascader>
          </div>
        </div>
        <div class="mb15">
          <div class="flex a-center">
            <div class="fs-12 mr5 w60">面积：</div>
            <div class="flex a-center">
              <input
                type="number"
                placeholder="最小面积"
                name=""
                v-model="area_min"
                class="w64h27 ip-bor fs-12 col-333333 plr4 bor-DADADA"
              />
              ~
              <input
                type="number"
                placeholder="最大面积"
                name=""
                v-model="area_max"
                class="w64h27 ip-bor fs-12 col-333333 plr4 bor-DADADA"
              />
            </div>
          </div>
          <div class="flex a-center j-sb mt14">
            <div></div>
            <div>
              <el-radio v-model="area_unit" label="1">平方米</el-radio>
              <el-radio v-model="area_unit" label="2">亩</el-radio>
            </div>
          </div>
        </div>
        <div class="mb15">
          <div class="flex a-center">
            <div class="fs-12 mr5 w60">
              租金：
              <div class="fs-10 col-999999">（元）</div>
            </div>
            <div class="flex a-center">
              <input
                type="number"
                placeholder="最低价"
                name=""
                v-model="rent_money_min"
                class="w64h27 ip-bor fs-12 col-333333 plr4 bor-DADADA"
              />
              ~
              <input
                type="number"
                placeholder="最高价"
                name=""
                v-model="rent_money_max"
                class="w64h27 ip-bor fs-12 col-333333 plr4 bor-DADADA"
              />
            </div>
          </div>
          <div class="flex a-center j-sb mt14">
            <div></div>
            <div>
              <el-radio v-model="rent_time" label="1">年</el-radio>
              <el-radio v-model="rent_time" label="2">月</el-radio>
              <el-radio v-model="rent_time" label="3">平方</el-radio>
            </div>
          </div>
        </div>
        <div class="mb15" v-if="type == 1">
          <div class="flex a-center">
            <div class="fs-12 mr5 w60">
              转让费：
              <div class="fs-10 col-999999">（万元）</div>
            </div>
            <div class="flex a-center">
              <input
                type="number"
                placeholder="最低价"
                name=""
                v-model="sell_min"
                class="w64h27 ip-bor fs-12 col-333333 plr4 bor-DADADA"
              />
              ~
              <input
                type="number"
                placeholder="最高价"
                name=""
                v-model="sell_max"
                class="w64h27 ip-bor fs-12 col-333333 plr4 bor-DADADA"
              />
            </div>
          </div>
        </div>
        <div class="flex a-center j-center mb15">
          <div
            class="w207h38 l-h38 col-ffffff bg-ff0000 flex a-center j-center fs-14 cup"
            @click="matchingClick"
          >
            快速精确匹配
          </div>
        </div>
        <div class="flex a-center j-center mb15">
          <div
            class="w207h38 l-h38 col-ffffff bg-999999 flex a-center j-center fs-14 cup"
            @click="WinportClick4"
          >
            重置
          </div>
        </div>
      </div>
    </div> -->

    <!-- navtop -->
    <div
      class="flex-c a-center fs-12 cup"
      style="position: fixed; bottom: 8%; right: 2.5rem; z-index: 100"
      v-if="navid == 2 && isshow && !showmore && istopnav"
    >
      <ul class="bor-DADADA">
        <li
          class="flex-c a-center j-center pd4 borb-DADADA"
          :class="cNumTop1 != index ? 'col-FF0000' : 'bg-ff0000 col-ffffff'"
          v-for="(item, index) in topList1"
          :key="index + 'toplist1'"
          @click="handleNumTop1(index)"
        >
          <span v-if="item.t1">{{ item.t1 }}</span>
          <span v-if="item.t2">{{ item.t2 }}</span>
        </li>
        <li
          class="flex-c a-center j-center pd4"
          :class="cNumTop1 != topList1.length ? 'col-FF0000' : 'bg-ff0000 col-ffffff'"
          @click="handleNumTop1(topList1.length)"
        >
          <div>Top</div>
        </li>
      </ul>
    </div>
    <!-- 改 转让出租页 -->
    <div class="flex max-w1200 mrauto mt30" v-if="navid == 2 && isshow && !showmore">
      <!-- 分类列表 -->
      <div class="per-w20 mr10 classlist">
        <el-popover
          placement="right-start"
          :width="isMobile()?'120':'400'"
          trigger="hover"
          v-for="(item, index) in classT"
          :key="index + 'sonclass'"
        >
          <div class="w325h300 pt5b15lr15 box-b ovflow-y">
            <ul class="flex f-w">
              <li
                class="per-w25 mb15 cup"
                v-for="(items, indexs) in item.child"
                :key="indexs + 'childclass'"
                @click="classclick(item.id, items.id)"
              >
                <div class="wh50 mrauto">
                  <img :src="items.img" class="wh50" alt="" />
                </div>
                <div class="fs-12 text-center mt5">
                  {{ items.name }}
                </div>
              </li>
            </ul>
          </div>
          <div slot="reference">
            <div class="flex a-center fs-12 bg-fafafa borblr-DADADA plr12 box2" :class="index==0?'bort-DADADA':''">
              <div class="wh20 mr10">
                <img :src="item.img" alt="" class="wh20" />
              </div>
              <div class="l-h34 fwb mr10 ft-1">
                {{ item.name }}
              </div>
              <div class="l-h34 ft-1">
                {{ item.child[0].name }}
              </div>
            </div>
          </div>
        </el-popover>
      </div>
      <div class="pr10" style="width: 60%">
        <!-- 首页广告 -->
        <div class="cup per-w100">
          <el-carousel height="23.1875rem">
            <el-carousel-item
              v-for="(item, index) in index_ad"
              :key="index + 'bannercut'"
            >
              <div class="h371 per-w100 por">
                <img
                  :src="item.img"
                  alt=""
                  class="h371 per-w100"
                  @click="bannercut(item)"
                />
                <div class="bannertitle" v-if="item.title">
                  {{ item.title}}
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <!-- 头版 -->
        <div id="toubanbox" class="mt14 flex ovflow-h per-w100" style="height: 11.375rem">
          <div class="flex per-w100" style="height: 11.375rem" id="touban">
            <div
              class="cup"
              :class="index != touban.length - 1 ? 'mr10' : ''"
              style="width: 33.33%"
              v-for="(item, index) in touban"
              :key="index + 'touban'"
            >
        <!-- <div id="toubanbox" class="flex per-w100">
            <div class="flex per-w100" style="flex-wrap: wrap;" id="touban">
              <div
                class="cup"
                :class="index != touban.length - 1 ? 'mr10' : ''"
                style="width: calc(33% - 0.625rem);height: 11.375rem;"
                :style="index>2?'margin-top:0.875rem;':''"
                v-for="(item, index) in touban"
                :key="index + 'touban'"
              > -->
              <div class="per-w100 flex-c" style="height: 11.375rem">
                <img
                  :src="item.img"
                  alt=""
                  class="per-w100"
                  style="height: 9.1875rem"
                  @click="detailsCut(item)"
                />
                <div
                  class="text-center h32 l-h32 ft-1 fs-12 hover-cc0000 borb-DADADA borl-DADADA borr-DADADA"
                  style="width: cale(100% - 2px)"
                >
                  {{ item.title }}
                </div>
                <!-- <div
                  class="h23 fs-12 l-h23 bg-000000a5 col-ffffff ft-1 poa-lt plr5 box-b text-center"
                  v-if="item.name"
                >
                  {{ item.name }}
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 优选 -->
      <div class="per-w20 ovflow-h">
        <ul class="per-w100 flex-c fs-12" id="youxuan">
          <li
            class="p8 per-w100 borb-DADADA borl-DADADA borr-DADADA cup"
            style="padding-bottom: 0"
            :class="index == 0 ? 'bort-DADADA' : ''"
            v-for="(item, index) in youxuan"
            :key="index + 'youxuan'"
            @click="detailsCut(item)"
          >
            <div class="flex a-center per-w100">
              <div class="per-w55" style="height: 4.75rem">
                <img class="per-w100" style="height: 4.75rem" :src="item.img" />
              </div>
              <div class="ml5" style="height: 4.75rem">
                <div class="mt5">
                  面积：<span class="col-FF0000" v-if="item.area">{{ item.area }}</span
                  >平米
                </div>
                <div class="mt14" v-if="item.rent_price > 0">
                  租金：<span class="col-FF0000" v-if="item.rent_price">{{
                    item.rent_price
                  }}</span
                  >元
                </div>
                <div class="mt14" v-else>租金：0万元/年</div>
              </div>
            </div>
            <!-- <el-tooltip effect="light" :content="item.title" placement="bottom">
              <div class="text-center per-w100 ft-1 fs-14">
                {{ item.title }}
              </div>
            </el-tooltip> -->
            <div class="text-center per-w100 h32 l-h32 ft-1 fs-14 hover-cc0000" style="height: 1.75rem;">
              {{ item.title }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 列表 -->
    <div class="max-w1200 mrauto" v-if="navid == 2 && isshow && !showmore">
      <!-- 急转商铺推荐 -->
      <div id="jizhuan" class="per-w100 mt30">
        <div class="flex per-w100 por borb-DADADA mb30">
          <div class="fs-25 pb9">急转商铺推荐</div>
          <div class="borb2-d43030 poa-lb"></div>
        </div>
        <div class="flex per-w100 pt28b16lr0 mb30" v-if="sharp_turn1.length > 0">
          <div class="per-w100 h360 flex">
            <div class="">
              <div class="zxl_div_l" @click="detailsCut(sharp_turn1[0])">
                <div class="fl s1">{{ sharp_turn1[0].title }}</div>
                <div class="s2 fl">
                  <img :src="sharp_turn1[0].img" />
                </div>
                <div class="s3 fl">
                  面积：{{ sharp_turn1[0].area
                  }}{{ sharp_turn1[0].area_unit != 2 ? "平米" : "亩" }}<br />
                  <span v-if="sharp_turn1[0].phone"
                    >电话：{{ sharp_turn1[0].phone }}</span
                  >
                </div>
              </div>
            </div>
            <div class="flex1">
              <div class="flex1 h356" style="overflow: hidden">
                <!-- <ul class="flex-c f-w h356">
                  <li
                    class="jizhuan-li"
                    v-for="(item, index) in sharp_turn1.slice(1)"
                    :key="index"
                    @click="bannercut(item)"
                  >
                    <img :src="item.img" />
                    <span class="sp">{{ item.title }}</span>
                  </li>
                </ul> -->
                <el-carousel
                  trigger="click"
                  height="22.25rem"
                  arrow="always"
                  indicator-position="none"
                >
                  <el-carousel-item
                    v-for="(m, index) in Math.ceil((sharp_turn1.length - 1) / 10)"
                    :key="m + 'turn1'"
                  >
                    <ul class="flex f-w h356">
                      <li
                        class="jizhuan-li"
                        v-for="(item, ind) in sharp_turn1.slice(index * 10 + 1).length <=
                        10
                          ? sharp_turn1.slice(index * 10 + 1)
                          : sharp_turn1.slice(index * 10 + 1, (index + 1) * 10 + 1)"
                        :key="ind + 'liturn1'"
                        @click="detailsCut(item)"
                      >
                        <img :src="item.img" />
                        <span class="sp">{{ item.title }}</span>
                      </li>
                    </ul>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </div>
        </div>
        <div class="per-w100 h80 bg-9B8DB0 flex a-center">
          <img class="w400 h80" src="../../assets/img/li.png" alt="" />
          <div class="flex1 fs-40 col-ffffff flex a-center j-center">
            店铺经营 一站式全流程搞定！
          </div>
        </div>
      </div>
      <!-- 热门商铺推荐 -->
      <div id="remen" class="per-w100 mt30">
        <div class="flex a-center j-sb per-w100 por borb-DADADA mb30">
          <div class="fs-25 pb9">热门商铺推荐</div>
          <ul class="flex a-center f-w per-w85">
            <li
              class="cup mr10 fs-12 hover-cc0000"
              :class="sharp_turn_one == item.id ? 'col-cc0000' : 'col-333333'"
              v-for="(item, index) in industryHot"
              :key="index + 'remen'"
              @click="cutsharp_turn2(item.id, index)"
            >
              {{ item.name }}
            </li>
          </ul>
          <div class="borb2-d43030 poa-lb"></div>
        </div>
        <div class="mb30" v-if="sharp_turn2.length > 0">
          <ul class="flex f-w">
            <li
              class="remen-li"
              v-for="(item, index) in sharp_turn2"
              :key="index + 'liremen'"
              @click="detailsCut(item)"
            >
              <img :src="item.img" />
              <span class="sp" v-if="item.title">{{ item.title }}</span>
            </li>
          </ul>
        </div>
        <div class="per-w100 h80 bg-9B8DB0 flex a-center">
          <img class="w400 h80" src="../../assets/img/li.png" alt="" />
          <div class="flex1 fs-40 col-ffffff flex a-center j-center">
            店铺经营 一站式全流程搞定！
          </div>
        </div>
      </div>
      <!-- 精选商铺推荐 -->
      <div id="jingxuan" class="per-w100 mt30">
        <div class="flex a-center j-sb per-w100 por borb-DADADA mb30">
          <div class="fs-25 pb9">精选商铺推荐</div>
          <ul class="flex a-center f-w per-w85">
            <li
              class="cup mr10 fs-12 hover-cc0000"
              :class="sharp_turn_two == item.id ? 'col-cc0000' : 'col-333333'"
              v-for="(item, index) in industryFeature"
              :key="index + 'jingxuan'"
              @click="cutsharp_turn3(item.id, index)"
            >
              {{ item.name }}
            </li>
          </ul>
          <div class="borb2-d43030 poa-lb"></div>
        </div>
        <div class="per-w100 mb30" v-if="sharp_turn3.length > 0">
          <ul class="per-w100 flex f-w fs-12">
            <li
              class="jingxuan-li flex mb20"
              v-for="(item, index) in sharp_turn3"
              :key="index + 'lijingxuan'"
              @click="detailsCut(item)"
            >
              <div class="" style="">
                <img :src="item.img" />
              </div>
              <div class="a_img_r">
                <div class="" :class="isMobile()?'s1-2':'s1'">
                  {{ item.title }}
                </div>
                <div class="s2">
                  <div class="s4">
                    <span class="col-FF0000">{{ item.area }}</span
                    ><span>{{ item.area_unit == 2 ? "亩" : "平米" }}</span>
                  </div>
                  <div class="s3">
                    <span v-if="item.rent_price != '0.00'">
                      <span class="col-FF0000">{{ item.rent_price }}</span
                      >元/月
                    </span>
                    <span v-else> <span class="col-FF0000">0</span>万元/年 </span>
                    <!-- <span>{{ item.money_unit }}</span
                    ><span v-if="item.rent_time == 1">年</span
                    ><span v-if="item.rent_time == 2">月</span
                    ><span v-if="item.rent_time == 3">平米</span> -->
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="per-w100 h80 bg-9B8DB0 flex a-center mb30">
          <img class="w400 h80" src="../../assets/img/li.png" alt="" />
          <div class="flex1 fs-40 col-ffffff flex a-center j-center">
            店铺经营 一站式全流程搞定！
          </div>
        </div>
      </div>

      <!-- 分类 -->
      <ul id="forclass" class="per-w100">
        <li
          class="per-w100"
          v-for="(itemm, indexx) in classList"
          :key="indexx + '-' + itemm.id"
          :id="'class-' + indexx"
        >
          <div class="flex a-center j-sb per-w100 por borb-DADADA">
            <div class="borb2-d43030 poa-lb"></div>
            <div class="per-w95 flex">
              <div class="flex">
                <div class="w30 h50 por flex-c a-center">
                  <img class="wh30" src="../../assets/img/shield.png" />
                  <span
                    class="fs-14 col-ffffff wh30 l-h30 text-center"
                    style="margin-top: -1.875rem"
                    >{{ indexx + 1 }}F</span
                  >
                </div>
                <div class="fs-25 pb9">{{ itemm.name }}</div>
              </div>
              <ul class="flex a-center f-w ml20 flex1">
                <li
                  class="cup mr10 fs-12 hover-cc0000"
                  :class="
                    classname == item.name && classIndex == indexx
                      ? 'col-cc0000'
                      : 'col-333333'
                  "
                  v-for="(item, index) in itemm.child"
                  :key="index + 'classchange'"
                  @click="classchange(itemm, indexx, item)"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
            <div class="col-666666 cup fs-12" @click="classmore(itemm, indexx)">
              查看更多
            </div>
          </div>
          <div class="per-w100 pt30b23lr0 flex j-sb">
            <div class="w226">
              <div class="w210 h285 bor-DADADA pd6">
                <div class="fs-18 fwb h38 l-h38">热点区域</div>
                <ul class="flex f-w">
                  <li
                    class="pr10 h20 l-h20 fs-12 cup hover-cc0000"
                    :class="
                      classIndex == indexx && region == item.city_area
                        ? 'col-cc0000'
                        : 'col-666666'
                    "
                    v-for="item in areaList.slice(1)"
                    :key="item.city_area + 'cityarea'"
                    @click="changeregion(itemm, indexx, item.city_area)"
                  >
                    <span>{{ item.city_area }}</span>
                  </li>
                </ul>
                <div class="fs-18 fwb h38 l-h38">面积</div>
                <ul class="flex f-w">
                  <li
                    class="pr10 h20 l-h20 fs-12 cup hover-cc0000"
                    :class="
                      classIndex == indexx &&
                      area_min == item.area_min &&
                      area_max == item.area_max
                        ? 'col-cc0000'
                        : 'col-666666'
                    "
                    v-for="(item, index) in acreage"
                    :key="index + 'acreage'"
                    @click="changearea(itemm, indexx, item)"
                  >
                    <span v-if="item.area_min == 0">{{ item.area_max }}平米以下</span>
                    <span v-else-if="item.area_max == 0"
                      >{{ item.area_min }}平米以上</span
                    >
                    <span v-else>{{ item.area_min }} - {{ item.area_max }}平米</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="w961">
              <ul class="per-w100 flex f-w" v-if="allclass[indexx]">
                <li
                  class="p8 per-w20 bor-DADADA cup"
                  v-for="(item, index) in allclass[indexx].list"
                  :key="index"
                  @click="bannercut(item)"
                >
                  <div class="flex a-center per-w100">
                    <div class="per-w40" style="height: 5.25rem;width: 55%;">
                      <img class="per-w100" style="height: 5.25rem" :src="item.img" />
                    </div>
                    <div class="ml10 fs-12" style="height: 5.25rem">
                      <div class="mt5 text-nowrap ft-1">
                        <span class="col-FF0000">{{ item.area }}</span
                        >平米
                      </div>
                      <div class="mt14 text-nowrap ft-1">
                        <span class="col-FF0000">{{ item.rent_price }}</span
                        >元
                        <!-- {{ item.money_unit }}/<span v-if="item.rent_time == 1">年</span
                        ><span v-if="item.rent_time == 2">月</span
                        ><span v-if="item.rent_time == 3">平米</span> -->
                      </div>
                    </div>
                  </div>
                  <div class="text-center per-w100 ft-1 fs-14">
                    {{ item.title }}
                  </div>
                </li>
              </ul>
              <div class="per-w100 pt20">
                <ul class="per-w100 flex f-w" v-if="allclass[indexx]">
                  <li
                    class="per-w30 h34 ml20 borr-DADADA flex a-center cup hover-cc0000"
                    :class="index % 3 == 0 ? 'm0' : ''"
                    :style="index % 3 == 2 ? 'border:0;' : ''"
                    v-for="(item, index) in allclass[indexx].more_list"
                    :key="index"
                    @click="bannercut(item)"
                  >
                    <div class="per-w90 flex j-sb a-center">
                      <div class="fs-12 ft-1 per-w100">.{{ item.title }}</div>
                      <!-- <div class="fs-12 col-999999" v-if="item.area">
                        {{ item.area }}平米
                      </div> -->
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="per-w100 h80 bg-9B8DB0 flex a-center mb30">
            <img class="w400 h80" src="../../assets/img/li.png" alt="" />
            <div class="flex1 fs-40 col-ffffff flex a-center j-center">
              店铺经营 一站式全流程搞定！
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- 分类-更多 -->
    <div class="max-w1200 mt20lrautob0" v-if="navid == 2 && isshow && showmore">
      <div class="flex a-center j-sb per-w100 por borb-DADADA">
        <div class="borb2-d43030 poa-lb"></div>
        <div class="flex1 flex">
          <div class="flex">
            <div class="w30 h50 por flex-c a-center">
              <img class="wh30" src="../../assets/img/shield.png" />
              <span
                class="fs-14 col-ffffff wh30 l-h30 text-center"
                style="margin-top: -1.875rem"
                >{{ classIndex + 1 }}F</span
              >
            </div>
            <div class="fs-25 pb9">{{ classObj.name }}</div>
          </div>
          <ul class="flex a-center f-w ml20 flex1">
            <li
              class="cup mr10 fs-12 hover-cc0000"
              :class="classname == item.name ? 'col-cc0000' : 'col-333333'"
              v-for="(item, index) in classObj.child"
              :key="index"
              @click="classchange(classObj, classIndex, item)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="col-666666 cup fs-12" @click="classless">收起</div>
      </div>
      <div class="per-w100 pt30b23lr0 flex j-sb">
        <div class="w226">
          <div class="w210 h285 bor-DADADA pd6">
            <div class="fs-18 fwb h38 l-h38">热点区域</div>
            <ul class="flex f-w">
              <li
                class="pr10 h20 l-h20 fs-12 cup hover-cc0000"
                :class="region == item.city_area ? 'col-cc0000' : 'col-666666'"
                v-for="item in areaList.slice(1)"
                :key="item.city_area"
                @click="changeregion(classObj, classIndex, item.city_area)"
              >
                <span>{{ item.city_area }}</span>
              </li>
            </ul>
            <div class="fs-18 fwb h38 l-h38">面积</div>
            <ul class="flex f-w">
              <li
                class="pr10 h20 l-h20 fs-12 cup hover-cc0000"
                :class="
                  area_min == item.area_min && area_max == item.area_max
                    ? 'col-cc0000'
                    : 'col-666666'
                "
                v-for="(item, index) in acreage"
                :key="index"
                @click="changearea(classObj, classIndex, item)"
              >
                <span v-if="item.area_min == 0">{{ item.area_max }}平米以下</span>
                <span v-else-if="item.area_max == 0">{{ item.area_min }}平米以上</span>
                <span v-else>{{ item.area_min }} - {{ item.area_max }}平米</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="w961">
          <ul class="per-w100 flex f-w" v-if="classObj">
            <li
              class="p8 per-w20 bor-DADADA cup"
              v-for="(item, index) in classObj.list"
              :key="index"
              @click="bannercut(item)"
            >
              <div class="flex a-center per-w100">
                <div class="per-w40" style="height: 5.25rem;width: 55%;">
                  <img class="per-w100" style="height: 5.25rem" :src="item.img" />
                </div>
                <div class="ml10 fs-12" style="height: 5.25rem">
                  <div class="mt5">
                    <span class="col-FF0000">{{ item.area }}</span
                    >平米
                  </div>
                  <div class="mt14">
                    <span class="col-FF0000">{{ item.rent_price }}</span
                    >元
                    <!-- {{ item.money_unit }}/<span v-if="item.rent_time == 1">年</span
                        ><span v-if="item.rent_time == 2">月</span
                        ><span v-if="item.rent_time == 3">平米</span> -->
                  </div>
                </div>
              </div>
              <div class="text-center per-w100 ft-1 fs-14">
                {{ item.title }}
              </div>
            </li>
          </ul>
          <div class="per-w100 pt20">
            <ul class="per-w100 flex f-w" v-if="classObj">
              <li
                class="per-w31 h34 ml20 borr-DADADA flex a-center cup hover-cc0000"
                :class="index % 3 == 0 ? 'm0' : ''"
                :style="index % 3 == 2 ? 'border:0;' : ''"
                v-for="(item, index) in classObj.more_list"
                :key="index"
                @click="bannercut(item)"
              >
                <div class="per-w90 flex j-sb a-center">
                  <div class="fs-12 ft-1 per-w100">.{{ item.title }}</div>
                  <!-- <div class="fs-12 col-999999" v-if="item.area">
                        {{ item.area }}平米
                      </div> -->
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- 广告位 -->
    <div
      class="max-w1200 mrauto mt30"
      v-if="(navid == 2 && !isshow) || navid == 3 || navid == 4 || navid == 7"
    >
      <div class="per-w100">
        <el-carousel height="40rem">
          <el-carousel-item v-for="(item, index) in index_ad" :key="index">
            <div class="h640 per-w100 por">
                <img
                  :src="item.img"
                  alt=""
                  class="h640 per-w100"
                  @click="bannercut(item)"
                />
                <div class="bannertitle" v-if="item.title">
                  {{ item.title}}
                </div>
              </div>
            <!-- <img :src="item.img" alt="" class="h640 per-w100" @click="bannercut(item)" /> -->
          </el-carousel-item>
        </el-carousel>
      </div>
      <!--<div class="per-w100 mr10 cup flex">
        <div
          class="h400 per-w20 ovflow-y mr10 classlist2"
          id="scrollId1"
          style="scrollbar-width: none; -ms-overflow-style: none"
        >
          <div>
            <div v-for="(item, index) in ad_oneList" :key="index">
              <div class="por" style="width: 100%; height: 8.3331rem">
                <img
                  :src="item.img"
                  alt=""
                  style="width: 100%; height: 8.3331rem"
                  @click="bannercut(item)"
                />
                <div
                  class="h23 fs-12 l-h23 bg-000000a5 col-ffffff ft-1 poa-lt plr5 box-b text-center"
                  v-if="item.name"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="per-w60 cup">
          <el-carousel height="25rem">
            <el-carousel-item v-for="(item, index) in index_ad" :key="index">
              <img
                :src="item.img"
                alt=""
                class="h400 per-w100"
                @click="bannercut(item)"
              />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div
          class="per-w20 ml10 classlist2 h400"
          style="overflow-y: scroll"
          id="scrollId2"
        >
          <div>
            <div v-for="(item, index) in ad_twoList" :key="index">
              <div class="por" style="width: 100%; height: 8.3331rem">
                <img
                  :src="item.img"
                  alt=""
                  style="width: 100%; height: 8.3331rem"
                  @click="bannercut(item)"
                />
                <div
                  class="h23 fs-12 l-h23 bg-000000a5 col-ffffff ft-1 poa-lt plr5 box-b text-center"
                  v-if="item.name"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="mt14 flex ovflow-w classlist2"
        style="width: 100%"
        id="scrollId3"
      >
        <div class="per-w20" v-for="(item, index) in ad_threeList" :key="index">
          <div class="por" style="width: 100%; height: 10.4375rem">
            <img
              :src="item.img"
              alt=""
              style="width: 100%; height: 10.4375rem"
              @click="bannercut(item)"
            />
            <div
              class="h23 fs-12 l-h23 bg-000000a5 col-ffffff ft-1 poa-lt plr5 box-b text-center"
              v-if="item.name"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>-->
    </div>

    <!-- <ul class="max-w1200 mrauto ptb20 bg-F4F4F4 flex mt14" v-if="navid == 1">
      <li class="per-w20 borr-DADADA" v-for="(item, index) in contactlist" :key="index">
        <div class="wh30 mrauto mb10">
          <img :src="item.icon" alt="" class="wh30" />
        </div>
        <div class="mb20 fs-18 fwb text-center">{{ item.title }}</div>
        <div class="fs-14 text-center col-666666">{{ item.text }}</div>
        <div class="fs-14 text-center col-666666">{{ item.phone }}</div>
        <div
          class="w96h29 cup bg-ff0000 col-ffffff fs-14 flex a-center j-center mrauto mt23 br100"
        >
          <div class="flex a-center">
            <div class="wh20">
              <img src="../../assets/img/icon_phone.png" alt="" class="wh20" />
            </div>
            立即咨询
          </div>
        </div>
      </li>
    </ul> -->
    <div class="max-w1200 mrauto ptb30">
      <div class="per-w100">
        <!-- <div class="pb38 borb-DADADA">
       
        <div
          class="flex mb15"
          v-if="(navid == 1 && type == 1) || navid == 2 || navid == 3 || navid == 4"
        >
          <div class="per-w10 fs-18 col-505050">行业：</div>
          <div class="per-w90">
            <ul class="flex a-center f-w mb15">
              <li
                class="w88h27 plr4 cup br5 flex a-center j-center mr10 mb10 fs-14"
                :class="
                  item.id == classify_one
                    ? 'bg-d43030 col-ffffff'
                    : 'bor-e5e5e5 col-383838'
                "
                v-for="(item, index) in industryList"
                :key="index"
                @click="cutIndustry(item.id, index)"
              >
                {{ item.name }}
              </li>
            </ul>
            <ul
              class="ptb15lr20 flex a-center f-w bg-f1f1f1"
              v-if="industryList2 && industryList2.length"
            >
              <li
                class="w88h27 plr4 cup br5 flex a-center j-center mr10 mb10 fs-14"
                :class="
                  item.id == classify_two
                    ? 'bg-d43030 col-ffffff'
                    : 'bg-ffffff bor-e5e5e5 col-383838'
                "
                v-for="item in industryList2"
                :key="item.name"
                @click="cutIndustry2(item.id, item.name)"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
        </div>
        <div class="flex mb15">
          <div class="per-w10 fs-18 col-505050">区域：</div>
          <div class="per-w90">
            <ul class="flex a-center f-w mb15">
              <li
                class="w88h27 cup br5 flex a-center j-center mr10 mb10 fs-14"
                :class="
                  item.city_area == region
                    ? 'bg-d43030 col-ffffff'
                    : 'bor-e5e5e5 col-383838'
                "
                v-for="(item, index) in areaList"
                :key="index"
                @click="cutCity(item.city_area)"
              >
                {{ item.city_area ? item.city_area : "不限" }}
              </li>
            </ul>
          </div>
        </div>
        <div class="flex mb15">
          <div class="per-w10 fs-18 col-505050">建筑面积：</div>
          <div class="per-w90 flex a-center">
            <div class="mr15">
              <input
                type="number"
                placeholder="最小面积"
                name=""
                v-model="area_min"
                class="w64h27 ip-bor fs-12 col-333333 plr4 bor-DADADA"
              />
              —
              <input
                type="number"
                placeholder="最大面积"
                name=""
                v-model="area_max"
                class="w64h27 ip-bor fs-12 col-333333 plr4 bor-DADADA"
              />
            </div>
            <div class="mr26">
              <el-radio v-model="area_unit" label="1">平方米</el-radio>
              <el-radio v-model="area_unit" label="2">亩</el-radio>
            </div>
            <div
              class="bor-DADADA col-666666 fs-12 flex a-center j-center cup w88h27 br5"
              @click="screenClick('mj')"
            >
              筛选
            </div>
          </div>
        </div>
        <div class="flex mb15">
          <div class="per-w10 fs-18 col-505050">租金(元)：</div>
          <div class="per-w90 flex a-center">
            <div class="mr15">
              <input
                type="number"
                placeholder="最低价"
                name=""
                v-model="rent_money_min"
                class="w64h27 ip-bor fs-12 col-333333 plr4 bor-DADADA"
              />
              —
              <input
                type="number"
                placeholder="最高价"
                name=""
                v-model="rent_money_max"
                class="w64h27 ip-bor fs-12 col-333333 plr4 bor-DADADA"
              />
            </div>
            <div class="mr26">
              <el-radio v-model="rent_time" label="1">年</el-radio>
              <el-radio v-model="rent_time" label="2">月</el-radio>
              <el-radio v-model="rent_time" label="3">平方</el-radio>
            </div>
            <div
              class="bor-DADADA col-666666 fs-12 flex a-center j-center cup w88h27 br5"
              @click="screenClick('zj')"
            >
              筛选
            </div>
          </div>
        </div>
        <div
          class="flex mb15"
          v-if="(navid == 1 && type == 1) || navid == 2 || navid == 3 || navid == 4"
        >
          <div class="per-w10 fs-18 col-505050">转让费(万元)：</div>
          <div class="per-w90 flex a-center">
            <div class="mr26">
              <input
                type="number"
                placeholder="最低价"
                name=""
                v-model="sell_min"
                class="w64h27 ip-bor fs-12 col-333333 plr4 bor-DADADA"
              />
              —
              <input
                type="number"
                placeholder="最高价"
                name=""
                v-model="sell_max"
                class="w64h27 ip-bor fs-12 col-333333 plr4 bor-DADADA"
              />
            </div>
            <div
              class="bor-DADADA col-666666 fs-12 flex a-center j-center cup w88h27 br5"
              @click="screenClick('zr')"
            >
              筛选
            </div>
          </div>
        </div>
      </div>
      <div class="mt28 flex a-center f-w">
        <div class="per-w10 fs-18 col-505050">你正在查询：</div>
        <div class="flex a-center">
          <el-tag
            closable
            hit
            effect="plain"
            type="info"
            class="mr23"
            v-if="classify_twoName"
            @close="close('hy')"
          >
            {{ classify_twoName }}
          </el-tag>
          <el-tag
            closable
            hit
            effect="plain"
            type="info"
            class="mr23"
            v-if="region"
            @close="close('qy')"
          >
            {{ region }}
          </el-tag>
          <el-tag
            closable
            hit
            effect="plain"
            type="info"
            class="mr23"
            v-if="mjtext"
            @close="close('mj')"
          >
            {{ mjtext }}
          </el-tag>
          <el-tag
            closable
            hit
            effect="plain"
            type="info"
            class="mr23"
            v-if="zjtext"
            @close="close('zj')"
          >
            {{ zjtext }}
          </el-tag>
          <el-tag
            closable
            hit
            effect="plain"
            type="info"
            class="mr23"
            v-if="zrtext"
            @close="close('zr')"
          >
            {{ zrtext }}
          </el-tag>
        </div>
      </div> -->
        <div
          id="shaixuan"
          class="bor-DADADA pt17"
          v-if="(navid == 2 && !isshow) || navid == 3 || navid == 4 || navid == 7"
        >
          <!-- 行业 -->
          <div class="flex ml15 mb15" v-if="navid != 7">
            <div class="w80 fs-12 col-333333 mr10">行业：</div>
            <div>
              <ul class="flex a-center f-w">
                <li
                  class="cup mr10 mb10 fs-12"
                  :class="item.id == classify_one ? 'col-CC0000' : 'col-333333'"
                  v-for="(item, index) in industryList"
                  :key="index"
                  @click="cutIndustry(item.id, index)"
                >
                  {{ item.name }}
                </li>
              </ul>
              <div class="mt5">
                <ul
                  class="flex a-center f-w bg-F2F2F2"
                  v-if="industryList2 && industryList2.length"
                >
                  <li
                    class="cup mar10 fs-12"
                    :class="item.id == classify_two ? 'col-CC0000' : 'col-333333'"
                    v-for="item in industryList2"
                    :key="item.name"
                    @click="cutIndustry2(item.id, item.name)"
                  >
                    {{ item.name }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- 区域 -->
          <div class="flex ml15">
            <div class="mr10 fs-12 col-333333 w80">区域：</div>
            <div>
              <ul class="flex a-center f-w mb15">
                <li
                  class="cup mr10 mb10 fs-12"
                  :class="item.city_area == region ? 'col-CC0000' : 'col-333333'"
                  v-for="item in areaList"
                  :key="item.city_area"
                  @click="cutCity(item.city_area)"
                >
                  {{ item.city_area ? item.city_area : "不限" }}
                </li>
              </ul>
            </div>
          </div>
          <!-- 面积 -->
          <div class="flex mb15 a-center ml15" v-if="navid != 7">
            <div class="mr10 fs-12 col-333333 w80">建筑面积：</div>
            <div class="flex a-center">
              <div class="mr15">
                <input
                  type="number"
                  placeholder="最小面积"
                  name=""
                  v-model="area_min"
                  class="w64h27 ip-bor fs-12 col-333333 plr4 bor-DADADA"
                />
                —
                <input
                  type="number"
                  placeholder="最大面积"
                  name=""
                  v-model="area_max"
                  class="w64h27 ip-bor fs-12 col-333333 plr4 bor-DADADA"
                />
              </div>
              <div class="mr26">
                <el-radio v-model="area_unit" label="1">平方米</el-radio>
                <el-radio v-model="area_unit" label="2">亩</el-radio>
              </div>
              <div
                class="bor-DADADA col-666666 fs-12 flex a-center j-center cup w88h27 br5"
                @click="screenClick('mj')"
              >
                筛选
              </div>
            </div>
          </div>
          <!-- 租金 -->
          <div class="flex mb15 a-center ml15" v-if="navid != 7">
            <div class="mr10 fs-12 col-333333 w80">租金(元)：</div>
            <div class="flex a-center">
              <div class="mr15">
                <input
                  type="number"
                  placeholder="最低价"
                  name=""
                  v-model="rent_money_min"
                  class="w64h27 ip-bor fs-12 col-333333 plr4 bor-DADADA"
                />
                —
                <input
                  type="number"
                  placeholder="最高价"
                  name=""
                  v-model="rent_money_max"
                  class="w64h27 ip-bor fs-12 col-333333 plr4 bor-DADADA"
                />
              </div>
              <div class="mr26">
                <el-radio v-model="rent_time" label="1">年</el-radio>
                <el-radio v-model="rent_time" label="2">月</el-radio>
                <el-radio v-model="rent_time" label="3">平方</el-radio>
              </div>
              <div
                class="bor-DADADA col-666666 fs-12 flex a-center j-center cup w88h27 br5"
                @click="screenClick('zj')"
              >
                筛选
              </div>
            </div>
          </div>
          <!-- 转让费 -->
          <div class="flex mb15 a-center ml15" v-if="navid != 7">
            <div class="mr10 fs-12 col-333333 w80">转让费(万元)：</div>
            <div class="flex a-center">
              <div class="mr26">
                <input
                  type="number"
                  placeholder="最低价"
                  name=""
                  v-model="sell_min"
                  class="w64h27 ip-bor fs-12 col-333333 plr4 bor-DADADA"
                />
                —
                <input
                  type="number"
                  placeholder="最高价"
                  name=""
                  v-model="sell_max"
                  class="w64h27 ip-bor fs-12 col-333333 plr4 bor-DADADA"
                />
              </div>
              <div
                class="bor-DADADA col-666666 fs-12 flex a-center j-center cup w88h27 br5"
                @click="screenClick('zr')"
              >
                筛选
              </div>
            </div>
          </div>
          <div
            class="flex a-center f-w bg-F2F2F2 ptb10lr16"
            v-if="navid == 2 || navid == 3 || navid == 4"
          >
            <div class="fs-12 col-666666 mr10">你正在查询：</div>
            <div class="flex a-center">
              <el-tag
                closable
                hit
                effect="plain"
                type="info"
                class="mr23"
                v-if="classify_twoName"
                @close="close('hy')"
              >
                {{ classify_twoName }}
              </el-tag>
              <el-tag
                closable
                hit
                effect="plain"
                type="info"
                class="mr23"
                v-if="region"
                @close="close('qy')"
              >
                {{ region }}
              </el-tag>
              <el-tag
                closable
                hit
                effect="plain"
                type="info"
                class="mr23"
                v-if="mjtext"
                @close="close('mj')"
              >
                {{ mjtext }}
              </el-tag>
              <el-tag
                closable
                hit
                effect="plain"
                type="info"
                class="mr23"
                v-if="zjtext"
                @close="close('zj')"
              >
                {{ zjtext }}
              </el-tag>
              <el-tag
                closable
                hit
                effect="plain"
                type="info"
                class="mr23"
                v-if="zrtext"
                @close="close('zr')"
              >
                {{ zrtext }}
              </el-tag>
            </div>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="per-w100">
          <div
            class="flex a-center j-sb"
            :class="
              (navid == 2 && !isshow) || navid == 3 || navid == 4 || navid == 7
                ? 'mt50 borb-d43030'
                : ''
            "
          >
            <!-- <div class="flex a-center" v-if="navid == 1">
              <div
                class="h60 w140 cup flex a-center j-center fs-18 fwb"
                :class="item.id == type ? 'bg-d43030 col-ffffff' : 'col-383838'"
                v-for="(item, index) in typeList"
                :key="index"
                @click="WinportClick(item.id)"
              >
                {{ item.name }}
              </div>
            </div> -->
            <div v-if="navid == 2 && !isshow" class="flex a-center">
              <div
                class="h60 w140 cup flex a-center j-center fs-18 fwb"
                :class="item.id == type2 ? 'bg-d43030 col-ffffff' : 'col-383838'"
                v-for="(item, index) in typeList2"
                :key="index"
                @click="WinportClick2(item.id)"
              >
                {{ item.name }}
              </div>
            </div>
            <div v-if="navid == 3" class="flex a-center">
              <div
                class="h60 w140 cup flex a-center j-center fs-18 fwb"
                :class="item.id == type3 ? 'bg-d43030 col-ffffff' : 'col-383838'"
                v-for="(item, index) in typeList3"
                :key="index"
                @click="WinportClick3(item.id)"
              >
                {{ item.name }}
              </div>
            </div>
            <div v-if="navid == 4">
              <div
                class="h60 w140 cup flex a-center j-center fs-18 fwb"
                :class="item.id == type5 ? 'bg-d43030 col-ffffff' : 'col-383838'"
                v-for="(item, index) in typeList5"
                :key="index"
              >
                {{ item.name }}
              </div>
            </div>
            <div v-if="navid == 7" class="flex a-center">
              <div
                class="h60 w140 cup flex a-center j-center fs-18 fwb"
                :class="item.id == type4 ? 'bg-d43030 col-ffffff' : 'col-383838'"
                v-for="(item, index) in typeList4"
                :key="index"
                @click="WinportClick5(item.id)"
              >
                {{ item.name }}
              </div>
            </div>
            <div class="flex a-center" v-if="navid != 1 && !isshow && navid != 5">
              <div
                class="ptb4lr6 cup br5 fs-18 mr10 flex a-center"
                :class="
                  item.id == 3 && auto_sort == 4
                    ? 'bor-d43030 col-d43030'
                    : item.id == auto_sort
                    ? 'bor-d43030 col-d43030 '
                    : 'bor-505050 col-505050'
                "
                v-for="(item, index) in screenList"
                :key="index"
                @click="cutAutosort(item.id)"
              >
                {{ item.name }}
                <div
                  class="ml5"
                  :class="auto_sort == 4 ? 'sxjx' : 'sxjs'"
                  v-if="item.name == '佣金'"
                ></div>
              </div>
            </div>
            <!-- <div class="flex a-center" v-if="navid == 1 && type != 1">
              <div
                class="ptb4lr6 cup br5 fs-18 mr10 flex a-center"
                :class="
                  item.id == 3 && auto_sort == 4
                    ? 'bor-d43030 col-d43030'
                    : item.id == auto_sort
                    ? 'bor-d43030 col-d43030 '
                    : 'bor-505050 col-505050'
                "
                v-for="(item, index) in screenList2"
                :key="index"
              >
                {{ item.name }}
              </div>
            </div> -->
            <!-- <div class="flex a-center" v-if="navid == 1 && type == 1">
              <div
                class="ptb4lr6 cup br5 fs-18 mr10 flex a-center"
                :class="
                  item.id == 3 && auto_sort == 4
                    ? 'bor-d43030 col-d43030'
                    : item.id == auto_sort
                    ? 'bor-d43030 col-d43030 '
                    : 'bor-505050 col-505050'
                "
                v-for="(item, index) in screenList"
                :key="index"
                @click="cutAutosort(item.id)"
              >
                {{ item.name }}
                <div
                  class="ml5"
                  :class="auto_sort == 4 ? 'sxjx' : 'sxjs'"
                  v-if="item.name == '佣金'"
                ></div>
              </div>
            </div> -->
          </div>

          <!-- <div class="" v-if="navid == 1">
          <ul class="pt10" v-if="type == 1 || type == 2 || type == 4">
            <li
              v-for="(item, index) in store_list"
              :key="index"
              class="flex a-center pb22 pt17 borb-DADADA"
              @click="detailsCut(item)"
            >
              <div class="w182 h110 mr23 ycj">
                <img :src="item.img" alt="" class="w182 h110" />
                <div class="yichengjiao" v-if="type == 4">
                  <img
                    src="../../assets/img/yichengjiao.png"
                    alt=""
                    class="yichengjiao"
                  />
                </div>
                <div
                  class="videobox"
                  @click.stop="videoplay(item.video)"
                  v-if="item.video"
                >
                  <div class="video">
                    <img
                      src="https://pt.baipubang.com/uploads/img/index/video.png"
                      alt=""
                      class="video"
                    />
                  </div>
                </div>
              </div>
              <div class="flex a-center j-sb per-w100">
                <div class="per-w50 flex a-center">
                  <div>
                    <div class="flex a-center fs-16 col-333333">
                      <div class="max-w372">
                        {{ item.title }}
                      </div>
                      <div class="w20h22 ml5 mr20">
                        <img src="../../assets/img/hudun.png" alt="" class="w20h22" />
                      </div>
                      <div
                        class="w46h24 flex a-center j-center br100 bg-3FBCEF fs-12 col-ffffff mr10"
                      >
                        多图
                      </div>

                      <div
                        class="w46h24 flex a-center j-center br100 bg-19be6b fs-12 col-ffffff"
                        v-if="item.ad_loc == 2"
                      >
                        优选
                      </div>
                      <div
                        class="w46h24 flex a-center j-center br100 bg-FE8D55 fs-12 col-ffffff"
                        v-if="item.ad_loc == 1"
                      >
                        头版
                      </div>
                    </div>
                    <div class="mt23 fs-12 col-666666">
                      {{ item.address }}
                    </div>
                    <div class="flex a-center mt5 fs-12 col-666666 f-w">
                      适合经营：
                      <span
                        class="mr7"
                        v-for="(items, indexs) in item.fit_industry"
                        :key="indexs"
                        >{{ items }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="per-w30 flex a-center j-center fs-12 col-333333">
                  <div>
                    <div>
                      <span class="fs-24 col-FF0000">{{ item.area }} </span>
                      <span v-if="item.area_unit == 1">平方米</span>
                      <span v-if="item.area_unit == 2">亩</span>
                    </div>
                    <div class="mt5" v-if="item.s_type == 1">
                      <div v-if="item.type == 1">
                        <div
                          v-if="!item.sell_price || item.sell_price == 0"
                          class="zj_cont"
                        >
                          转让费：面议
                        </div>
                        <div v-else-if="item.sell_price == '-1'">无转让费</div>
                        <div v-else>
                          转让费：<span>{{ item.sell_price }}</span
                          >万元
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="per-w20 flex a-center j-center col-333333 fs-16 text-center"
                  v-if="item.s_type == 1"
                >
                  <div>
                    <div
                      v-if="item.rent_price == 0 || !item.rent_price"
                      class="fs-16 col-333333 fwb"
                    >
                      面议
                    </div>
                    <div v-else>
                      {{ item.rent_price }}{{ item.money_unit }}/
                      <span v-if="item.rent_time == 1">年</span>
                      <span v-if="item.rent_time == 2">月</span>
                      <span v-if="item.rent_time == 3">平方</span>
                    </div>
                    <div class="fx" v-if="type == 2">
                      立即分享可赚 ￥{{ item.commission }}
                    </div>
                  </div>
                </div>
                <div
                  v-if="item.s_type == 2"
                  class="per-w20 flex a-center j-center col-333333 fs-16 text-center"
                >
                  <div>
                    <div>
                      <div v-if="item.sell_type == 0" class="fs-16 col-333333 fwb">
                        面议
                      </div>
                      <div v-if="item.sell_type == 1 || item.sell_type == 2">
                        <span>{{ item.sell_price }}{{ item.money_unit }}</span
                        >/
                        <span v-if="item.sell_type == 1">总价</span>
                        <span v-if="item.sell_type == 2">平方</span>
                      </div>
                    </div>
                    <div class="fx" v-if="type == 2">
                      立即分享可赚 ￥{{ item.commission }}
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <ul class="pt10" v-if="type == 3">
            <li
              v-for="(item, index) in store_list"
              :key="index"
              class="flex a-center pb22 pt17 borb-DADADA"
              @click="detailsCut(item)"
            >
              <div class="flex a-center j-sb per-w100">
                <div class="per-w50 flex a-center">
                  <div>
                    <div class="flex a-center fs-16 col-333333">
                      <div class="max-w372">
                        {{ item.title }}
                      </div>
                      <div class="ml5 w20h22 mr10">
                        <img src="../../assets/img/hudun.png" alt="" class="w20h22" />
                      </div>
                      <div class="w24h24" v-if="item.ad_loc == 2">
                        <img src="../../assets/img/you.png" alt="" class="w24h24" />
                      </div>
                      <div class="w24h24" v-if="item.ad_loc == 1">
                        <img src="../../assets/img/touban.png" alt="" class="w24h24" />
                      </div>
                    </div>
                    <div class="flex a-center mt23 fs-12 col-666666 f-w">
                      求租行业：<span
                        v-for="(items, indexs) in item.classify_two"
                        :key="indexs"
                        class="mr7"
                        >{{ items }}</span
                      >
                    </div>
                    <div class="mt5 fs-12 col-666666">
                      求租区域：{{ item.expect_area }}
                    </div>
                  </div>
                </div>
                <div class="per-w30 flex a-center j-center fs-12 col-333333">
                  <div>
                    <div>
                      <span class="fs-24 col-FF0000"
                        >{{ item.area_min }}~{{ item.area_max }}
                      </span>
                      <span v-if="item.area_unit == 1">平方米</span>
                      <span v-if="item.area_unit == 2">亩</span>
                    </div>
                    <div class="mt5 fs-12 col-666666 text-center">求租面积</div>
                  </div>
                </div>
                <div class="per-w20 flex a-center j-center col-333333 fs-16 text-center">
                  <div>
                    <div>
                      <div v-if="item.rent_price_list == 0" class="fs-16 col-333333 fwb">
                        面议
                      </div>
                      <div v-if="item.rent_price_list == 1">
                        <span>{{ item.rent_min }}~{{ item.rent_max }}元</span>/
                        <span v-if="item.rent_type == 1">年</span>
                        <span v-if="item.rent_type == 2">月</span>
                        <span v-if="item.rent_type == 3">平方</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div> -->
          <!-- <div class="mt17" v-if="navid == 1">
            <ul class="flex max-w1200 f-w" v-if="type == 1 || type == 2 || type == 4">
              <li
                v-for="(item, index) in store_list"
                :key="index"
                @click="detailsCut(item)"
                class="per-w20 mb15 cup"
              >
                <div class="w224h170 por mrauto">
                  <img :src="item.img" alt="" class="w224h170" />
                  <div
                    v-if="item.ad_loc == 1"
                    class="fs-12 text-center col-ffffff br100 l-h25"
                    style="
                      width: 3.125rem;
                      height: 1.5625rem;
                      background-color: #fe8d55;
                      position: absolute;
                      top: 2%;
                      left: 3%;
                    "
                  >
                    头版
                  </div>
                  <div
                    v-if="item.ad_loc == 2"
                    class="fs-12 text-center col-ffffff br100 l-h25"
                    style="
                      width: 3.125rem;
                      height: 1.5625rem;
                      background-color: #19be6b;
                      position: absolute;
                      top: 2%;
                      left: 3%;
                    "
                  >
                    优选
                  </div>
                  <div
                    class="h23 fs-12 l-h23 per-w100 bg-000000a5 col-ffffff ft-1 poa-bl plr5 box-b text-center"
                  >
                    {{ item.title }}
                  </div>
                </div>
                <div class="bor-efefef w224 mrauto ptb10lr12 box-b">
                  <div class="flex a-center">
                    <div class="fs-14 col-666666 mr5">
                      <span class="fwb col-D8252D">{{ item.area }} </span>
                      <span v-if="item.area_unit == 1">平方米</span>
                      <span v-if="item.area_unit == 2">亩</span>
                    </div>
                    <div class="mr5">
                      <div
                        v-if="item.rent_price == 0 || !item.rent_price"
                        class="fs-14 col-666666"
                      >
                        面议
                      </div>
                      <div v-else class="fs-14 col-666666">
                        {{ item.rent_price }}{{ item.money_unit }}/
                        <span v-if="item.rent_time == 1">年</span>
                        <span v-if="item.rent_time == 2">月</span>
                        <span v-if="item.rent_time == 3">平方</span>
                      </div>
                    </div>
                    <div>
                      <div v-if="item.s_type == 1">
                        <div v-if="item.type == 1" class="fs-14 col-666666">
                          <div v-if="!item.sell_price || item.sell_price == 0">面议</div>
                          
                          <div v-else>
                            <span>{{ item.sell_price }}</span
                            >万元
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="type == 2" class="mt5 text-center">
                    <div
                      style="
                        padding: 4px 6px;
                        color: #11a0a0;
                        border: 1px solid #11a0a0;
                        border-radius: 5px;
                        font-size: 12px;
                        font-weight: bold;
                      "
                    >
                      立即分享可赚￥{{ item.commission }}
                    </div>
                  </div>
                  <div class="flex a-center j-sb mt14">
                    <div class="col-666666 fs-14">
                      <div>
                        {{ item.contacts }}
                      </div>
                      <div class="mt5">
                        {{ item.phone }}
                      </div>
                    </div>
                    <div
                      class="w65h25 l-h25 fs-14 br100 bg-eea236 col-ffffff text-center"
                    >
                      看详情
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="flex max-w1200 f-w" v-if="type == 3">
              <li
                v-for="(item, index) in store_list"
                :key="index"
                class="per-w20 mb15 cup fs-14 cup"
                @click="detailsCut(item)"
              >
                <div class="bor-DADADA w224 mrauto">
                  <div class="pd20 box-b">
                    <div class="ft-1 mb5">
                      {{ item.contacts }},正在找{{ item.classify_two[0] }}
                    </div>
                    <div class="mb5 ft-1">
                      面积大概在
                      <span class="col-D8252D"
                        >{{ item.area_min }}~{{ item.area_max }}
                      </span>
                      <span v-if="item.area_unit == 1">平方米</span>
                      <span v-if="item.area_unit == 2">亩</span>
                    </div>
                    <div class="ft-1 mb5">求租{{ item.title }}</div>
                    <div class="ft-1 mb5">电话：{{ item.phone }}</div>
                  </div>
                  <div class="ptb6lr20 bg-F4F4F4 flex a-center j-sb">
                    <div></div>
                    <div
                      class="w65h25 l-h25 fs-14 br100 bg-eea236 col-ffffff text-center"
                    >
                      看详情
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div> -->

          <div class="" v-if="(navid == 2 && !isshow) || navid == 3">
            <ul class="pt10">
              <li
                v-for="(item, index) in store_list"
                :key="index"
                class="flex a-center pb22 pt17 borb-DADADA cup"
                @click="detailsCut(item)"
              >
                <div class="w230 h150 mr23 ycj">
                  <img :src="item.img" alt="" class="w230 h150" />
                  <div
                    class="videobox"
                    @click.stop="videoplay(item.video)"
                    v-if="item.video"
                  >
                    <div class="video">
                      <img
                        src="https://pt.baipubang.com/uploads/img/index/video.png"
                        alt=""
                        class="video"
                      />
                    </div>
                  </div>
                </div>
                <div class="flex a-center j-sb per-w100">
                  <div class="per-w50 flex a-center">
                    <div>
                      <div class="flex a-center fs-16 col-333333">
                        <div class="max-w372">
                          {{ item.title }}
                        </div>
                        <div class="w20h22 mr20 ml5">
                          <img src="../../assets/img/hudun.png" alt="" class="w20h22" />
                        </div>
                        <div
                          class="w46h24 flex a-center j-center br100 bg-3FBCEF fs-12 col-ffffff mr10"
                        >
                          多图
                        </div>

                        <div
                          class="w46h24 flex a-center j-center br100 bg-19be6b fs-12 col-ffffff"
                          v-if="item.ad_loc == 2"
                        >
                          优选
                        </div>
                        <div
                          class="w46h24 flex a-center j-center br100 bg-FE8D55 fs-12 col-ffffff"
                          v-if="item.ad_loc == 1"
                        >
                          头版
                        </div>
                        <!-- <div class="w24h24" v-if="item.ad_loc == 2">
                      <img src="../../assets/img/you.png" alt="" class="w24h24" />
                    </div>
                    <div class="w24h24" v-if="item.ad_loc == 1">
                      <img src="../../assets/img/touban.png" alt="" class="w24h24" />
                    </div> -->
                      </div>
                      <div class="flex fs-12 col-666666 mt23">
                        <div>区域：{{ item.city_area }}</div>
                        <div class="ml5">
                          {{ item.street }}
                        </div>
                      </div>
                      <div class="flex a-center mt5 fs-12 col-666666 f-w">
                        适合经营：{{ item.class_name }}
                      </div>
                      <div class="flex f-w mt5 fs-12 col-666666">
                        <div
                          v-for="(items, indexs) in item.label"
                          :key="indexs"
                          class="mar2 plr4 bor-505050 bor-009999 br5 col-009999 fs-10"
                        >
                          {{ items }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="per-w30 flex a-center j-center fs-12 col-333333">
                    <div>
                      <div>
                        面积：<span class="fs-16 col-FF0000">{{ item.area }} </span>
                        <span v-if="item.area_unit == 1">平方米</span>
                        <span v-if="item.area_unit == 2">亩</span>
                      </div>
                      <div class="mt5" v-if="item.s_type == 1">
                        <div v-if="item.type == 1">
                          <div
                            v-if="!item.sell_price || item.sell_price == 0"
                            class="zj_cont"
                          >
                            转让费：<span class="fs-16 col-FF0000">面议</span>
                          </div>
                          <div v-else-if="item.sell_price == '-1'">无转让费</div>
                          <div v-else>
                            转让费：<span class="fs-16 col-FF0000">{{
                              item.sell_price
                            }}</span
                            >万元
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="per-w20 col-333333 fs-16 text-center"
                    v-if="item.s_type == 1"
                  >
                    <div>
                      租金：
                      <div
                        v-if="item.rent_price == 0 || !item.rent_price"
                        class="fs-16 col-FF0000 fwb"
                      >
                        面议
                      </div>
                      <div v-else>
                        <span class="fs-16 col-FF0000 fwb">{{ item.rent_price }}</span>
                        {{ item.money_unit }}/
                        <span v-if="item.rent_time == 1">年</span>
                        <span v-if="item.rent_time == 2">月</span>
                        <span v-if="item.rent_time == 3">平方</span>
                      </div>
                    </div>
                    <div
                      class="w65h25 mrauto mt30 l-h25 fs-14 br100 bg-eea236 col-ffffff text-center cup"
                    >
                      看详情
                    </div>
                  </div>
                  <div
                    v-if="item.s_type == 2 && item.type == 2"
                    class="per-w20 col-333333 fs-16 text-center"
                  >
                    <div>
                      <div>
                        费用：
                        <div v-if="item.sell_type == 0" class="fs-16 col-333333 fwb">
                          面议
                        </div>
                        <div v-if="item.sell_type == 1 || item.sell_type == 2">
                          <span>{{ item.sell_price }}{{ item.money_unit }}</span
                          >/
                          <span v-if="item.sell_type == 1">总价</span>
                          <span v-if="item.sell_type == 2">平方</span>
                        </div>
                      </div>
                    </div>
                    <div
                      class="w65h25 mrauto mt30 l-h25 fs-14 br100 bg-eea236 col-ffffff text-center cup"
                    >
                      看详情
                    </div>
                  </div>
                  <div v-if="item.s_type == 2 && item.type == 1"></div>
                </div>
              </li>
            </ul>
          </div>
          <div class="" v-if="navid == 7">
            <ul class="pt10">
              <li
                v-for="(item, index) in store_list"
                :key="index"
                class="flex a-center pb22 pt17 borb-DADADA cup"
                @click="detailsCut(item)"
              >
                <div class="w230 h150 mr23 ycj">
                  <img :src="item.cover" alt="" class="w230 h150" />
                </div>
                <div class="flex a-center j-sb per-w100">
                  <div class="per-w80 flex a-center">
                    <div>
                      <div class="flex a-center fs-16 col-333333">
                        <div class="max-w372">
                          {{ item.title }}
                        </div>
                        <div class="w20h22 mr20 ml5">
                          <img src="../../assets/img/hudun.png" alt="" class="w20h22" />
                        </div>
                        <div
                          class="w46h24 flex a-center j-center br100 bg-3FBCEF fs-12 col-ffffff mr10"
                        >
                          多图
                        </div>

                        <div
                          class="w46h24 flex a-center j-center br100 bg-19be6b fs-12 col-ffffff"
                          v-if="item.ad_loc == 2"
                        >
                          优选
                        </div>
                        <div
                          class="w46h24 flex a-center j-center br100 bg-FE8D55 fs-12 col-ffffff"
                          v-if="item.ad_loc == 1"
                        >
                          头版
                        </div>
                        <!-- <div class="w24h24" v-if="item.ad_loc == 2">
                      <img src="../../assets/img/you.png" alt="" class="w24h24" />
                    </div>
                    <div class="w24h24" v-if="item.ad_loc == 1">
                      <img src="../../assets/img/touban.png" alt="" class="w24h24" />
                    </div> -->
                      </div>
                      <div class="mt23 fs-12 col-666666">区域：{{ item.city_area }}</div>
                      <div class="fs-12 col-666666 mt5" v-if="item.category">
                        品类：{{ item.category }}
                      </div>
                      <div class="flex a-center mt5 fs-12 col-666666 f-w">
                        {{ item.contacts }}： {{ item.phone }}
                      </div>
                    </div>
                  </div>
                  <div class="per-w20 col-333333 fs-16 text-center">
                    <div
                      class="w65h25 mrauto mt30 l-h25 fs-14 br100 bg-eea236 col-ffffff text-center cup"
                    >
                      看详情
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="" v-if="navid == 4">
            <ul class="pt10">
              <li
                v-for="(item, index) in store_list"
                :key="index"
                class="flex a-center pb22 pt17 borb-DADADA"
                @click="detailsCut(item)"
              >
                <div class="flex a-center j-sb per-w100">
                  <div class="per-w50 flex a-center">
                    <div>
                      <div class="flex a-center fs-16 col-333333">
                        <div class="max-w372">
                          {{ item.title }}
                        </div>
                        <div class="ml5 w20h22 mr10">
                          <img src="../../assets/img/hudun.png" alt="" class="w20h22" />
                        </div>
                        <div class="w24h24" v-if="item.ad_loc == 2">
                          <img src="../../assets/img/you.png" alt="" class="w24h24" />
                        </div>
                        <div class="w24h24" v-if="item.ad_loc == 1">
                          <img src="../../assets/img/touban.png" alt="" class="w24h24" />
                        </div>
                      </div>
                      <div class="flex a-center mt23 fs-12 col-666666 f-w">
                        求租行业：<span
                          v-for="(items, indexs) in item.classify_two"
                          :key="indexs"
                          class="mr7"
                          >{{ items }}</span
                        >
                      </div>
                      <div class="mt5 fs-12 col-666666">
                        求租区域：{{ item.expect_area }}
                      </div>
                    </div>
                  </div>
                  <div class="per-w30 flex a-center j-center fs-12 col-333333">
                    <div>
                      <div>
                        <span class="fs-16 col-FF0000"
                          >{{ item.area_min }}~{{ item.area_max }}
                        </span>
                        <span v-if="item.area_unit == 1">平方米</span>
                        <span v-if="item.area_unit == 2">亩</span>
                      </div>
                      <div class="mt5 fs-12 col-666666 text-center">求租面积</div>
                    </div>
                  </div>
                  <div
                    class="per-w20 flex a-center j-center col-333333 fs-16 text-center"
                  >
                    <div>
                      <div>
                        租金：
                        <div
                          v-if="item.rent_price_list == 0"
                          class="fs-16 col-333333 fwb"
                        >
                          面议
                        </div>
                        <div v-if="item.rent_price_list == 1">
                          <span>{{ item.rent_min }}~{{ item.rent_max }}元</span>/
                          <span v-if="item.rent_type == 1">年</span>
                          <span v-if="item.rent_type == 2">月</span>
                          <span v-if="item.rent_type == 3">平方</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- 分类广告位置 -->
        <ul class="ml10 mt50" v-show="navid != 1 && !isshow">
          <!-- <li
            class="mb5 cup"
            v-if="ad_img_list_two && ad_img_list_two.length"
            style="width: 16.9375rem; height: 14rem"
          >
            <el-carousel height="14rem">
              <el-carousel-item v-for="(items, index) in ad_img_list_two" :key="index">
                <div class="por" style="width: 16.9375rem; height: 14rem">
                  <img
                    :src="items.img"
                    alt=""
                    style="width: 16.9375rem; height: 14rem"
                    @click="bannercut(items)"
                  />
                  <div
                    class="h23 fs-12 l-h23 bg-000000a5 col-ffffff ft-1 poa-lt plr5 box-b text-center"
                    v-if="items.name"
                  >
                    {{ items.name }}
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </li>
          <li
            class="mb5 cup"
            v-if="ad_img_list_three && ad_img_list_three.length"
            style="width: 16.9375rem; height: 14rem"
          >
            <el-carousel height="14rem">
              <el-carousel-item v-for="(items, index) in ad_img_list_three" :key="index">
                <div class="por" style="width: 16.9375rem; height: 14rem">
                  <img
                    :src="items.img"
                    alt=""
                    style="width: 16.9375rem; height: 14rem"
                    @click="bannercut(items)"
                  />
                  <div
                    class="h23 fs-12 l-h23 bg-000000a5 col-ffffff ft-1 poa-lt plr5 box-b text-center"
                    v-if="items.name"
                  >
                    {{ items.name }}
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </li> -->
          <li class="w271h169 mb5 cup" v-for="(item, index) in classimgList" :key="index">
            <el-carousel height="10.5625rem">
              <el-carousel-item v-for="(items, index) in item" :key="index">
                <div class="w271h169 por">
                  <img
                    :src="items.img"
                    alt=""
                    class="w271h169"
                    @click="bannercut(items)"
                  />
                  <div
                    class="h23 fs-12 l-h23 bg-000000a5 col-ffffff ft-1 poa-lt plr5 box-b text-center"
                    v-if="items.name"
                  >
                  {{ item.name?item.name:item.title }}
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="flex a-center j-center mb40"
      v-if="(navid == 2 && !isshow) || navid == 3 || navid == 4 || navid == 7"
    >
      <el-pagination
        background
        layout="prev, pager, next"
        :page-count="page_nums"
        @current-change="currentChange"
        :current-page="page"
      >
      </el-pagination>
    </div>

    <el-dialog title="视 频 播 放" :visible.sync="dialogVisible" :width="isMobile()?'85%':'50%'">
      <div>
        <video
          width="100%"
          height="25rem"
          :muted="!dialogVisible"
          :src="video"
          controls
          autoplay
        ></video>
      </div>
    </el-dialog>

    <!-- 广告弹窗 -->
    <el-dialog :show-close="false" :visible.sync="ispop" :width="isMobile()?'85%':'50%'" center>
      <div class="per-w100 h-60vh por">
        <div class="per-wh100">
          <el-image
            style="width: 100%; height: 100%"
            :src="index_pop.img"
            fit="contain"
          ></el-image>
        </div>
        <div class="wh30 poa-rt" style="margin-top: -1.875rem" @click="ispop = false">
          <el-button type="info" icon="el-icon-close" circle></el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 立即咨询 -->
    <el-dialog :show-close="false" :visible.sync="isconcat" :width="isMobile()?'85%':'50%'" center>
      <div class="per-w100 h-60vh por">
        <!-- <div class="maxw-vw35">
          <img class="per-wh100" :src="show_contact" alt="">
        </div> -->
        <div class="per-wh100">
          <el-image
            style="width: 100%; height: 100%"
            :src="show_contact"
            fit="contain"
          ></el-image>
        </div>
        <div class="wh30 poa-rt" style="margin-top: -1.875rem" @click="isconcat = false">
          <el-button type="info" icon="el-icon-close" circle></el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  get_consultant_list,
  get_index_office,
  index,
  lease_list,
  lease_list_one,
  lease_list_two,
  ivn_sale_list_one,
  ivn_sale_list_two,
  find_shop_list,
  classify_list,
  old_goods_list,
  acquisition_list,
  set_grey,
  // ad_one,
  ad_two,
  ad_three,
  index_pc_sharp_turn,
  index_pc_hot,
  index_pc_featured,
  category_ad,
  home_index,
  get_add,
} from "../../utils/api.js";
import { Loading } from "element-ui";
export default {
  data() {
    return {
      istopnav: false,
      scrollTop: 0,
      isclass: false,
      onepop: false,
      ispop: false, //弹窗
      index_pop: {}, //首页弹窗广告
      allclass: [], //分类所有数据
      acreage: [], //面积
      youxuanTimer: null,
      youxuanEl: "", //优选滚动
      youxuan: [],
      toubanTimer: null,
      toubanEl: "", //头版滚动
      touban: [],
      isshow: true, //true转让出租为广告，false转让出租为列表
      bargainEl: "", //成功案例滚动
      openUserEl: "", //开通用户滚动
      findshowEl: "", //找店滚动
      sharp_turn1: [], //急转
      sharp_turn2: [], //热门
      industryHot: [],
      sharp_turn3: [], //精选
      industryFeature: [],
      cNumTop1: 0,
      topList1: [],
      cNumTop: 0,
      topList: [
        {
          t1: "重点",
          t2: "推荐",
        },
        {
          t1: "生意",
          t2: "转让",
        },
        {
          t1: "写字楼",
          t2: "厂房",
          t3: "库房",
        },
        {
          t1: "找店",
          t2: "选址",
        },
        {
          t1: "招商",
          t2: "出售",
        },
      ],
      findShop: 0,
      find: ["快速转店", "我要找店"],
      openUser: [], //开通用户
      openUserTimer: null,
      bargain: [], //成交案例
      bargainTimer: null,
      recomnav: [], //重点推荐nav
      recomlist: [], //重点推荐list
      recomAll: [], //重点推荐所有
      shopShowTimer: null,
      shopShow: [], //生意转让-店铺动态
      roomnav: [], //写字楼
      roomlist: [],
      all_find_shop_nums: null,
      today_find_shop_nums: null,
      findshow: [],
      findshowTimer: null,
      find_list: [], //找店选址
      sale_list_onenav: [],
      sale_list_one: [], //招商
      sale_list_twonav: [],
      sale_list_two: [], //出售

      scrollTimer: null, // 滚动定时器
      pauseTimer: null, // 暂停定时器
      scrollDirection: "down", // 滚动方向 up向上 down向下
      scrollTimer2: null, // 滚动定时器
      pauseTimer2: null, // 暂停定时器
      scrollDirection2: "down", // 滚动方向 up向上 down向下
      scrollTimer3: null, // 滚动定时器
      pauseTimer3: null, // 暂停定时器
      scrollDirection3: "down", // 滚动方向 up向上 down向下
      is_grey: "",
      index_ad: [],
      isconcat: false,
      show_contact: "",
      consultant_list: [], //立即咨询的顾问
      contactlist: [
        {
          title: "转让参考",
          text: "去看看周边同行的店多久转出去？",
          phone: "咨询电话：18582135678",
          icon: require("../../assets/img/icon5.png"),
        },
        {
          title: "电话评估",
          text: "教您怎么转的更快、价格更高",
          phone: "咨询电话：18582135678",
          icon: require("../../assets/img/icon4.png"),
        },
        {
          title: "到店评估",
          text: "赚点专家到店现场考察、分析",
          phone: "咨询电话：18582135678",
          icon: require("../../assets/img/icon3.png"),
        },
        {
          title: "快转服务",
          text: "热情为您的需求服务",
          phone: "咨询电话：18582135678",
          icon: require("../../assets/img/icon2.png"),
        },
        {
          title: "精准选址",
          text: "免费找店，找到您满意为止！",
          phone: "咨询电话：18582135678",
          icon: require("../../assets/img/icon1.png"),
        },
      ],
      video: "",
      dialogVisible: false,
      keyword: "",
      screenList: [
        { id: "", name: "距离优先" },
        { id: 1, name: "推荐优先" },
        { id: 2, name: "新店优先" },
        { id: 3, name: "佣金" },
      ],
      screenList2: [{ id: "", name: "距离优先" }],
      typeList: [
        { id: 1, name: "旺铺推荐" },
        { id: 2, name: "分享返佣" },
        { id: 3, name: "看谁找店" },
        { id: 4, name: "已成交" },
      ],
      typeList2: [
        { id: 0, name: "推荐" },
        { id: 1, name: "转让" },
        { id: 2, name: "出租" },
      ],
      typeList3: [
        { id: 0, name: "推荐" },
        { id: 1, name: "招商" },
        { id: 2, name: "出售" },
      ],
      typeList4: [
        { id: 2, name: "二手货品收购" },
        { id: 1, name: "二手货品转让" },
      ],
      typeList5: [{ id: 1, name: "找店选址" }],
      type: 1,
      type2: 0,
      type3: 0,
      type4: 2,
      type5: 1,
      list: [],
      navid: 1,
      city_area: "", //区
      city: "", //城市
      province: "", //省
      page: 1,
      lat: "",
      lng: "",
      sharp_turn_one: "",
      sharp_turn_two: "",
      classify_one: "",
      classify_two: "",
      classify_twoName: "",
      classify_twoarr: ["", ""],
      auto_sort: "",
      region: "",
      rent_money_min: "",
      rent_money_max: "",
      rent_time: "1",
      zjtext: "",
      sell_min: "",
      sell_max: "",
      zrtext: "",
      area_min: "",
      area_max: "",
      area_unit: "1",
      mjtext: "",
      industryList: [],
      industryList2: [],
      areaList: "",
      page_nums: 1,
      priceFlag: true,
      store_list: "",
      classT: [],
      classimgList: [],
      classList: [],
      classname: "",
      showmore: false, //更多
      classObj: {
        list: [],
        more_list: [],
      },
      classIndex: 0,
      ad_img_list_three: [],
      ad_img_list_two: [],
      ad_oneList: [],
      ad_twoList: [],
      ad_threeList: [],
    };
  },
  watch: {
    // youxuan: {
    //   handler() {
    //     this.youxuanEl = document.getElementById("youxuan")
    //     if (this.youxuanTimer == null && this.isshow) {
    //       if (this.youxuan.length > 6) {
    //         this.youxuanTimer = setInterval(this.scrollyouxuan, 2000)
    //       } else {
    //         return
    //       }
    //     } else {
    //       clearInterval(this.youxuanTimer)
    //       this.youxuanTimer = null
    //     }
    //   },
    // },
    // touban: {
    //   handler() {
    //     // let toubanbox = document.getElementById("toubanbox");
    //     this.toubanEl = document.getElementById("touban")
    //     if (this.toubanTimer == null && this.isshow) {
    //       if (this.touban.length > 3 && this.navid == 2) {
    //         this.toubanTimer = setInterval(this.scrolltouban, 2000)
    //       } else {
    //         return
    //       }
    //     } else {
    //       clearInterval(this.toubanTimer)
    //       this.toubanTimer = null
    //     }
    //   },
    // },
    bargain: {
      handler() {
        if (this.bargainTimer == null) {
          if (this.bargain.length > 4) {
            this.bargainTimer = setInterval(this.scrollBar, 2000);
          } else {
            return;
          }
        } else {
          clearInterval(this.bargainTimer);
          this.bargainTimer = null;
        }
      },
    },
    openUser: {
      handler() {
        if (this.openUserTimer == null) {
          if (this.openUser.length > 2) {
            this.openUserTimer = setInterval(this.scrollopenUser, 2000);
          } else {
            return;
          }
        } else {
          clearInterval(this.openUserTimer);
          this.openUserTimer = null;
        }
      },
    },
    findshow: {
      handler() {
        if (this.findshowTimer == null) {
          if (this.findshow.length > 5) {
            this.findshowTimer = setInterval(this.scrollfindshow, 2000);
          } else {
            return;
          }
        } else {
          clearInterval(this.findshowTimer);
          this.findshowTimer = null;
        }
      },
    },
    // 被侦听的变量count
    $route: {
      handler() {
        this.isclass = false;
        this.showmore = false;
        // clearInterval(this.youxuanTimer)
        //   this.youxuanTimer = null

        //   clearInterval(this.toubanTimer)
        //   this.toubanTimer = null

        clearInterval(this.bargainTimer);
        this.bargainTimer = null;

        clearInterval(this.openUserTimer);
        this.openUserTimer = null;

        clearInterval(this.shopShowTimer);
        this.shopShowTimer = null;

        clearInterval(this.findshowTimer);
        this.findshowTimer = null;

        // 清理定时器
        // clearTimeout(this.pauseTimer)
        // this.pauseTimer = null
        // clearInterval(this.scrollTimer)
        // this.scrollTimer = null

        // clearTimeout(this.pauseTimer2)
        // this.pauseTimer2 = null
        // clearInterval(this.scrollTimer2)
        // this.scrollTimer2 = null

        // clearTimeout(this.pauseTimer3)
        // this.pauseTimer3 = null
        // clearInterval(this.scrollTimer3)
        // this.scrollTimer3 = null

        let navid = this.$route.query.navid;
        if (navid) {
          if (navid == 2 && this.navid != navid) {
            if (this.navid != 1 || this.classify_one == "") {
              if (this.classify_one == "" && this.navid == 1 && this.isshow == false) {
                this.isshow = false;
              } else {
                this.isshow = true;
              }
            } else {
              this.isshow = false;
            }
          }
          if (navid != 2 && this.navid != 1) {
            this.classify_one = "";
            this.classify_two = "";
            this.classify_twoName = "";
          }
          this.navid = navid;
        }
        let keyword = this.$route.query.keyword;
        if (keyword) {
          this.keyword = keyword;
        } else {
          this.keyword = "";
        }
        let city = this.$route.query.city;
        if (city) {
          this.city = city;
        }
        this.get_grey();
        // this.type = 1;
        // this.type2 = 1;
        // this.type3 = 1;
        this.rent_money_min = "";
        this.rent_money_max = "";
        this.rent_time = "1";
        this.zjtext = "";
        this.sell_min = "";
        this.sell_max = "";
        this.zrtext = "";
        this.area_min = "";
        this.area_max = "";
        this.area_unit = "1";
        this.mjtext = "";
        this.sharp_turn_one = "";
        this.sharp_turn_two = "";
        // this.classify_one = ""
        // this.classify_two = ""
        // this.classify_twoName = ""
        this.auto_sort = "";
        this.region = "";
        let classfid = this.$route.query.classfid;
        let classid = this.$route.query.classid;
        if (classfid && classid) {
          this.isshow = false;
          this.classcut(classfid, classid);
        } else {
          this.isshow = true;
          this.page = 1;
          this.getdata();
        }
        if (this.navid != 1) {
          this.get_ad();
        }
        this.getclassdata();
      },
      deep: true,
    },
  },
  destroyed() {
    // clearInterval(this.youxuanTimer)
    // this.youxuanTimer = null

    // clearInterval(this.toubanTimer)
    // this.toubanTimer = null

    clearInterval(this.bargainTimer);
    this.bargainTimer = null;

    clearInterval(this.openUserTimer);
    this.openUserTimer = null;

    clearInterval(this.shopShowTimer);
    this.shopShowTimer = null;

    clearInterval(this.findshowTimer);
    this.findshowTimer = null;

    // 清理定时器
    // clearTimeout(this.pauseTimer)
    // this.pauseTimer = null
    // clearInterval(this.scrollTimer)
    // this.scrollTimer = null

    // clearTimeout(this.pauseTimer2)
    // this.pauseTimer2 = null
    // clearInterval(this.scrollTimer2)
    // this.scrollTimer2 = null

    // clearTimeout(this.pauseTimer3)
    // this.pauseTimer3 = null
    // clearInterval(this.scrollTimer3)
    // this.scrollTimer3 = null
    // 清理点击监听
    // window.document.removeEventListener("click", this.pauseScroll);
    // window.document.removeEventListener("click", this.pauseScroll2);
    // window.document.removeEventListener("click", this.pauseScroll3);
  },
  created() {
    let lng = window.localStorage.getItem("lng");
    let lat = window.localStorage.getItem("lat");
    let province = window.localStorage.getItem("province");
    let city = window.localStorage.getItem("city");
    if (city && lng && lat && province) {
      this.city = city;
      this.getclassdata();
    }
  },
  mounted() {
    // window.addEventListener('scroll', function() {
    //   this.screenTop = window.scrollY;
    //   // console.log('页面正在滚动',this.screenTop);
    //   if(this.scrollTop > 400){
    //     this.istopnav = true;
    //   }else{
    //     this.istopnav = false;
    //   }
    // });

    this.bargainEl = document.querySelector("#bargain");
    this.bargainEl.classList.add("transaction2");
    this.openUserEl = document.querySelector("#openUser");
    this.openUserEl.classList.add("transaction2");
    this.findshowEl = document.querySelector("#findshow");
    this.findshowEl.classList.add("transaction2");

    this.get_grey();
    let navid = this.$route.query.navid;
    if (navid) {
      this.navid = navid;
    }
    let keyword = this.$route.query.keyword;
    if (keyword) {
      this.keyword = keyword;
    } else {
      this.keyword = "";
    }

    let area = window.localStorage.getItem("city_area");
    this.city_area = area;

    let lng = window.localStorage.getItem("lng");
    let lat = window.localStorage.getItem("lat");
    let province = window.localStorage.getItem("province");
    let city = window.localStorage.getItem("city");
    if (city && lng && lat && province) {
      this.lng = lng;
      this.lat = lat;
      this.city = city;
      this.province = province;
      this.getdata();
      if (this.navid !== 1) {
        this.get_ad();
      }
      // this.getclassdata()
    } else {
      this.getLocation();
    }

    this.acreage = [
      {
        area_max: 20,
        area_min: 0,
      },
      {
        area_max: 50,
        area_min: 20,
      },
      {
        area_max: 100,
        area_min: 50,
      },
      {
        area_max: 200,
        area_min: 100,
      },
      {
        area_max: 500,
        area_min: 200,
      },
      {
        area_max: 0,
        area_min: 500,
      },
    ];
  },
  methods: {
    // 判断是否为移动设备
    isMobile() {
      // 获取userAgent字符串
      var userAgent = navigator.userAgent || navigator.vendor || window.opera
 
      // 使用正则表达式来检查userAgent字符串中是否包含某些关键字
      // 这些关键字通常与移动设备相关
      var mobile = /windows phone|iphone|ipad|ipod|android|blackberry|mini|windows ce|palm/i.test(
        userAgent.toLowerCase()
      )
      return mobile;
    },
    // 去已成交案例列表
    goSuccessList(){
      this.$router.push({
        path: "/successList",
      });
    },
    // 切换shopTop
    handleNumTop1(index) {
      this.cNumTop1 = index;
      let cid = "";
      switch (index) {
        case 0:
          cid = "jizhuan";
          break;
        case 1:
          cid = "remen";
          break;
        case 2:
          cid = "jingxuan";
          break;
        default:
          if (index > 2 && index != this.topList1.length) {
            cid = "class-" + (index - 3);
          }
          break;
      }
      if (index != this.topList1.length) {
        var el = document.getElementById(cid);
        this.$nextTick(function () {
          window.scrollTo({
            behavior: "smooth",
            top: el.offsetTop,
          });
        });
      } else {
        this.toTop();
      }
    },
    // 分类
    toclassclick(itemmid, itemid) {
      this.classclick(itemmid, itemid);
      setTimeout(() => {
        this.toshaixuan();
      }, 10);
    },
    // 搜地区
    changeregion(mm, ii, area) {
      this.classIndex = ii;
      this.isshow = true;
      this.region = area;
      this.classify_one = mm.id;
      this.page = 1;
      if (this.classify_one && !this.classify_two) {
        this.classify_two = this.industryList[ii].child[0].id;
        this.classify_twoName = this.industryList[ii].child[0].name;
        this.industryList2 = this.industryList[ii].child;
      }

      this.isshow = false;
      this.getdata();
      setTimeout(() => {
        this.toshaixuan();
      }, 10);

      // this.isshow = true;
      // if(this.classname=='优选'){
      //   // 优选
      //   ad_three({
      //     city:this.city,
      //     classify_id: this.classify_one,
      //     classify_two: this.classify_two,
      //     region: this.region,
      //     rent_money_min: this.rent_money_min,
      //     rent_money_max: this.rent_money_max,
      //     sell_min: this.sell_min,
      //     sell_max: this.sell_max,
      //     area_min: this.area_min,
      //     area_max: this.area_max,
      //     keywords: this.keyword,
      //   })
      //     .then((res) => {
      //       if(this.showmore){
      //         this.classObj.list = [];
      //         this.classObj.list = res.data.ad_img_list_three;
      //         this.$forceUpdate();
      //       }else{
      //         let tempclass = this.allclass;
      //         tempclass.map(itemm=>{
      //           if(itemm.id == mm.id){
      //             itemm.list = [];
      //             let temp = {
      //               list:res.data.ad_img_list_three.length>30?res.data.ad_img_list_three.slice(0,30):res.data.ad_img_list_three,
      //             }
      //             itemm.list = temp.list;

      //             this.allclass = [];
      //             this.allclass = tempclass
      //           }
      //         })
      //       }
      //     })
      //     .catch((err) => {
      //       console.log(err)
      //     })
      // }else if(this.classname=='头版'){
      //   // 头版
      //   ad_two({
      //     city:this.city,
      //     classify_id: this.classify_one,
      //     classify_two: this.classify_two,
      //     region: this.region,
      //     rent_money_min: this.rent_money_min,
      //     rent_money_max: this.rent_money_max,
      //     sell_min: this.sell_min,
      //     sell_max: this.sell_max,
      //     area_min: this.area_min,
      //     area_max: this.area_max,
      //     keywords: this.keyword,
      //   })
      //     .then((res) => {
      //       if(this.showmore){
      //         this.classObj.list = [];
      //         this.classObj.list = res.data.ad_img_list_two;
      //         this.$forceUpdate();
      //       }else{
      //         let tempclass = this.allclass;
      //         tempclass.map(itemm=>{
      //           if(itemm.id == mm.id){
      //             itemm.list = [];
      //             let temp = {
      //               list:res.data.ad_img_list_two.length>30?res.data.ad_img_list_two.slice(0,30):res.data.ad_img_list_two,
      //             }
      //             itemm.list = temp.list;

      //             this.allclass = [];
      //             this.allclass = tempclass
      //           }
      //         })
      //       }
      //     })
      //     .catch((err) => {
      //       console.log(err)
      //     })
      // }else if(this.classname=='急转'){
      //   // 急转
      //   index_pc_sharp_turn({
      //     page: this.page,
      //     city: this.city,
      //     user_lat: this.lat,
      //     user_lng: this.lng,
      //     classify_id: this.classify_one,
      //     classify_two: this.classify_two,
      //     auto_sort: this.auto_sort,
      //     region: this.region,
      //     rent_money_min: this.rent_money_min,
      //     rent_money_max: this.rent_money_max,
      //     rent_time: this.rent_time,
      //     sell_min: this.sell_min,
      //     sell_max: this.sell_max,
      //     area_min: this.area_min,
      //     area_max: this.area_max,
      //     area_unit: this.area_unit,
      //     keywords: this.keyword,
      //     money_unit: this.money_unit,
      //   })
      //     .then((res) => {
      //       if(this.showmore){
      //         this.classObj.list = [];
      //         this.classObj.list = res.data.index_ad;
      //         this.$forceUpdate();
      //       }else{
      //         let tempclass = this.allclass;
      //         tempclass.map(itemm=>{
      //           if(itemm.id == mm.id){
      //             itemm.list = [];
      //             let temp = {
      //               list:res.data.index_ad.length>30?res.data.index_ad.slice(0,30):res.data.index_ad,
      //             }
      //             itemm.list = temp.list;

      //             this.allclass = [];
      //             this.allclass = tempclass
      //           }
      //         })
      //       }
      //     })
      //     .catch((err) => {
      //       console.log(err)
      //     })
      // }else if(this.classname=='热门'){
      //   // 热门
      //   index_pc_hot({
      //     page: this.page,
      //     city: this.city,
      //     user_lat: this.lat,
      //     user_lng: this.lng,
      //     classify_id: this.classify_one,
      //     classify_two: this.classify_two,
      //     auto_sort: this.auto_sort,
      //     region: this.region,
      //     rent_money_min: this.rent_money_min,
      //     rent_money_max: this.rent_money_max,
      //     rent_time: this.rent_time,
      //     sell_min: this.sell_min,
      //     sell_max: this.sell_max,
      //     area_min: this.area_min,
      //     area_max: this.area_max,
      //     area_unit: this.area_unit,
      //     keywords: this.keyword,
      //     money_unit: this.money_unit,
      //   })
      //     .then((res) => {
      //       if(this.showmore){
      //         this.classObj.list = [];
      //         this.classObj.list = res.data.index_ad;
      //         this.$forceUpdate();
      //       }else{
      //         let tempclass = this.allclass;
      //         tempclass.map(itemm=>{
      //           if(itemm.id == mm.id){
      //             itemm.list = [];
      //             let temp = {
      //               list:res.data.index_ad.length>30?res.data.index_ad.slice(0,30):res.data.index_ad,
      //             }
      //             itemm.list = temp.list;

      //             this.allclass = [];
      //             this.allclass = tempclass
      //           }
      //         })
      //       }
      //     })
      //     .catch((err) => {
      //       console.log(err)
      //     })
      // }else if(this.classname=='精选'){
      //   // 精选
      //   index_pc_featured({
      //     page: this.page,
      //     city: this.city,
      //     user_lat: this.lat,
      //     user_lng: this.lng,
      //     classify_id: this.classify_one,
      //     classify_two: this.classify_two,
      //     auto_sort: this.auto_sort,
      //     region: this.region,
      //     rent_money_min: this.rent_money_min,
      //     rent_money_max: this.rent_money_max,
      //     rent_time: this.rent_time,
      //     sell_min: this.sell_min,
      //     sell_max: this.sell_max,
      //     area_min: this.area_min,
      //     area_max: this.area_max,
      //     area_unit: this.area_unit,
      //     keywords: this.keyword,
      //     money_unit: this.money_unit,
      //   })
      //     .then((res) => {
      //       if(this.showmore){
      //         this.classObj.list = [];
      //         this.classObj.list = res.data.index_ad;
      //         this.$forceUpdate();
      //       }else{
      //         let tempclass = this.allclass;
      //         tempclass.map(itemm=>{
      //           if(itemm.id == mm.id){
      //             itemm.list = [];
      //             let temp = {
      //               list:res.data.index_ad.length>30?res.data.index_ad.slice(0,30):res.data.index_ad,
      //             }
      //             itemm.list = temp.list;

      //             this.allclass = [];
      //             this.allclass = tempclass
      //           }
      //         })
      //       }
      //     })
      //     .catch((err) => {
      //       console.log(err)
      //     })
      // }else{
      //   // 子分类
      //   if(this.showmore){
      //     category_ad({
      //       city: this.city,
      //       classify_id: this.classify_one,
      //       classify_two: this.classify_two,
      //       area: this.region,
      //       area_min: this.area_min,
      //       area_max: this.area_max,
      //     })
      //       .then((res) => {
      //         let temp = {
      //           id:this.classify_one,
      //           list:res.data.list.length>30?res.data.list.slice(0,30):res.data.list,
      //           more_list:res.data.more_list.length>30?res.data.more_list.slice(0,30):res.data.more_list
      //         }
      //         this.classObj.list = [];
      //         this.classObj.more_list = [];
      //         this.classObj.list = temp.list;
      //         this.classObj.more_list = temp.more_list;
      //         this.$forceUpdate();
      //       })
      //       .catch((err) => {
      //         console.log(err)
      //       })
      //   }else{
      //     let tempclass = this.allclass;
      //     tempclass.map(itemm=>{
      //       if(itemm.id == mm.id){
      //         category_ad({
      //           city: this.city,
      //           classify_id: this.classify_one,
      //           classify_two: this.classify_two,
      //           area: this.region,
      //           area_min: this.area_min,
      //           area_max: this.area_max,
      //         })
      //           .then((res) => {
      //             let temp = {
      //               id:this.classify_one,
      //               list:res.data.list.length>30?res.data.list.slice(0,30):res.data.list,
      //               more_list:res.data.more_list.length>30?res.data.more_list.slice(0,30):res.data.more_list
      //             }
      //             itemm.list = temp.list;
      //             itemm.more_list = temp.more_list;

      //             this.allclass = [];
      //             this.allclass = tempclass
      //           })
      //           .catch((err) => {
      //             console.log(err)
      //           })
      //       }
      //     })
      //   }
      // }
    },
    // 搜面积
    changearea(mm, ii, item) {
      this.classIndex = ii;
      this.mjtext = item.area_min + "-" + item.area_max + "平方米";
      this.area_unit = "1";
      this.area_min = item.area_min;
      this.area_max = item.area_max;
      this.classify_one = mm.id;
      this.page = 1;
      if (this.classify_one && !this.classify_two) {
        this.classify_two = this.industryList[ii].child[0].id;
        this.classify_twoName = this.industryList[ii].child[0].name;
        this.industryList2 = this.industryList[ii].child;
      }

      this.isshow = false;
      this.getdata();
      setTimeout(() => {
        this.toshaixuan();
      }, 10);

      // this.isshow = true;
      // if(this.classname=='优选'){
      //   // 优选
      //   ad_three({
      //     city:this.city,
      //     classify_id: this.classify_one,
      //     classify_two: this.classify_two,
      //     region: this.region,
      //     rent_money_min: this.rent_money_min,
      //     rent_money_max: this.rent_money_max,
      //     sell_min: this.sell_min,
      //     sell_max: this.sell_max,
      //     area_min: this.area_min,
      //     area_max: this.area_max,
      //     keywords: this.keyword,
      //   })
      //     .then((res) => {
      //       if(this.showmore){
      //         this.classObj.list = [];
      //         this.classObj.list = res.data.ad_img_list_three;
      //       }else{
      //         let tempclass = this.allclass;
      //         tempclass.map(itemm=>{
      //           if(itemm.id == mm.id){
      //             itemm.list = [];
      //             let temp = {
      //               list:res.data.ad_img_list_three.length>30?res.data.ad_img_list_three.slice(0,30):res.data.ad_img_list_three,
      //             }
      //             itemm.list = temp.list;

      //             this.allclass = [];
      //             this.allclass = tempclass
      //           }
      //         })
      //       }
      //     })
      //     .catch((err) => {
      //       console.log(err)
      //     })
      // }else if(this.classname=='头版'){
      //   // 头版
      //   ad_two({
      //     city:this.city,
      //     classify_id: this.classify_one,
      //     classify_two: this.classify_two,
      //     region: this.region,
      //     rent_money_min: this.rent_money_min,
      //     rent_money_max: this.rent_money_max,
      //     sell_min: this.sell_min,
      //     sell_max: this.sell_max,
      //     area_min: this.area_min,
      //     area_max: this.area_max,
      //     keywords: this.keyword,
      //   })
      //     .then((res) => {
      //       if(this.showmore){
      //         this.classObj.list = [];
      //         this.classObj.list = res.data.ad_img_list_two;
      //       }else{
      //         let tempclass = this.allclass;
      //         tempclass.map(itemm=>{
      //           if(itemm.id == mm.id){
      //             itemm.list = [];
      //             let temp = {
      //               list:res.data.ad_img_list_two.length>30?res.data.ad_img_list_two.slice(0,30):res.data.ad_img_list_two,
      //             }
      //             itemm.list = temp.list;

      //             this.allclass = [];
      //             this.allclass = tempclass
      //           }
      //         })
      //       }
      //     })
      //     .catch((err) => {
      //       console.log(err)
      //     })
      // }else if(this.classname=='急转'){
      //   // 急转
      //   index_pc_sharp_turn({
      //     page: this.page,
      //     city: this.city,
      //     user_lat: this.lat,
      //     user_lng: this.lng,
      //     classify_id: this.classify_one,
      //     classify_two: this.classify_two,
      //     auto_sort: this.auto_sort,
      //     region: this.region,
      //     rent_money_min: this.rent_money_min,
      //     rent_money_max: this.rent_money_max,
      //     rent_time: this.rent_time,
      //     sell_min: this.sell_min,
      //     sell_max: this.sell_max,
      //     area_min: this.area_min,
      //     area_max: this.area_max,
      //     area_unit: this.area_unit,
      //     keywords: this.keyword,
      //     money_unit: this.money_unit,
      //   })
      //     .then((res) => {
      //       if(this.showmore){
      //         this.classObj.list = [];
      //         this.classObj.list = res.data.index_ad;
      //       }else{
      //         let tempclass = this.allclass;
      //         tempclass.map(itemm=>{
      //           if(itemm.id == mm.id){
      //             itemm.list = [];
      //             let temp = {
      //               list:res.data.index_ad.length>30?res.data.index_ad.slice(0,30):res.data.index_ad,
      //             }
      //             itemm.list = temp.list;

      //             this.allclass = [];
      //             this.allclass = tempclass
      //           }
      //         })
      //       }
      //     })
      //     .catch((err) => {
      //       console.log(err)
      //     })
      // }else if(this.classname=='热门'){
      //   // 热门
      //   index_pc_hot({
      //     page: this.page,
      //     city: this.city,
      //     user_lat: this.lat,
      //     user_lng: this.lng,
      //     classify_id: this.classify_one,
      //     classify_two: this.classify_two,
      //     auto_sort: this.auto_sort,
      //     region: this.region,
      //     rent_money_min: this.rent_money_min,
      //     rent_money_max: this.rent_money_max,
      //     rent_time: this.rent_time,
      //     sell_min: this.sell_min,
      //     sell_max: this.sell_max,
      //     area_min: this.area_min,
      //     area_max: this.area_max,
      //     area_unit: this.area_unit,
      //     keywords: this.keyword,
      //     money_unit: this.money_unit,
      //   })
      //     .then((res) => {
      //       if(this.showmore){
      //         this.classObj.list = [];
      //         this.classObj.list = res.data.index_ad;
      //       }else{
      //         let tempclass = this.allclass;
      //         tempclass.map(itemm=>{
      //           if(itemm.id == mm.id){
      //             itemm.list = [];
      //             let temp = {
      //               list:res.data.index_ad.length>30?res.data.index_ad.slice(0,30):res.data.index_ad,
      //             }
      //             itemm.list = temp.list;

      //             this.allclass = [];
      //             this.allclass = tempclass
      //           }
      //         })
      //       }
      //     })
      //     .catch((err) => {
      //       console.log(err)
      //     })
      // }else if(this.classname=='精选'){
      //   // 精选
      //   index_pc_featured({
      //     page: this.page,
      //     city: this.city,
      //     user_lat: this.lat,
      //     user_lng: this.lng,
      //     classify_id: this.classify_one,
      //     classify_two: this.classify_two,
      //     auto_sort: this.auto_sort,
      //     region: this.region,
      //     rent_money_min: this.rent_money_min,
      //     rent_money_max: this.rent_money_max,
      //     rent_time: this.rent_time,
      //     sell_min: this.sell_min,
      //     sell_max: this.sell_max,
      //     area_min: this.area_min,
      //     area_max: this.area_max,
      //     area_unit: this.area_unit,
      //     keywords: this.keyword,
      //     money_unit: this.money_unit,
      //   })
      //     .then((res) => {
      //       if(this.showmore){
      //         this.classObj.list = [];
      //         this.classObj.list = res.data.index_ad;
      //       }else{
      //         let tempclass = this.allclass;
      //         tempclass.map(itemm=>{
      //           if(itemm.id == mm.id){
      //             itemm.list = [];
      //             let temp = {
      //               list:res.data.index_ad.length>30?res.data.index_ad.slice(0,30):res.data.index_ad,
      //             }
      //             itemm.list = temp.list;

      //             this.allclass = [];
      //             this.allclass = tempclass
      //           }
      //         })
      //       }
      //     })
      //     .catch((err) => {
      //       console.log(err)
      //     })
      // }else{
      //   // 子分类
      //   if(this.showmore){
      //     category_ad({
      //       city: this.city,
      //       classify_id: this.classify_one,
      //       classify_two: this.classify_two,
      //       area: this.region,
      //       area_min: this.area_min,
      //       area_max: this.area_max,
      //     })
      //       .then((res) => {
      //         let temp = {
      //           id:this.classify_one,
      //           list:res.data.list.length>30?res.data.list.slice(0,30):res.data.list,
      //           more_list:res.data.more_list.length>30?res.data.more_list.slice(0,30):res.data.more_list
      //         }
      //         this.classObj.list = [];
      //         this.classObj.more_list = [];
      //         this.classObj.list = temp.list;
      //         this.classObj.more_list = temp.more_list;
      //       })
      //       .catch((err) => {
      //         console.log(err)
      //       })
      //   }else{
      //     let tempclass = this.allclass;
      //     tempclass.map(itemm=>{
      //       if(itemm.id == mm.id){
      //         category_ad({
      //           city: this.city,
      //           classify_id: this.classify_one,
      //           classify_two: this.classify_two,
      //           area: this.region,
      //           area_min: this.area_min,
      //           area_max: this.area_max,
      //         })
      //           .then((res) => {
      //             let temp = {
      //               id:this.classify_one,
      //               list:res.data.list.length>30?res.data.list.slice(0,30):res.data.list,
      //               more_list:res.data.more_list.length>30?res.data.more_list.slice(0,30):res.data.more_list
      //             }
      //             itemm.list = temp.list;
      //             itemm.more_list = temp.more_list;

      //             this.allclass = [];
      //             this.allclass = tempclass
      //           })
      //           .catch((err) => {
      //             console.log(err)
      //           })
      //       }
      //     })
      //   }
      // }
    },
    // 招商
    saleonenav(city_area) {
      this.area_unit = "1";
      this.area_min = "";
      this.area_max = "";
      this.classify_one = "";
      this.classify_two = "";
      this.region = "";
      this.mjtext = "";

      this.$router.push({
        path: "/indexer",
        query: { navid: 3 },
      });
      setTimeout(() => {
        this.WinportClick3(1);
        this.cutCity(city_area);
        this.toshaixuan();
      }, 10);
    },
    // 出售
    saletwonav(city_area) {
      this.area_unit = "1";
      this.area_min = "";
      this.area_max = "";
      this.classify_one = "";
      this.classify_two = "";
      this.region = "";
      this.mjtext = "";

      this.$router.push({
        path: "/indexer",
        query: { navid: 3 },
      });
      setTimeout(() => {
        this.WinportClick3(2);
        this.cutCity(city_area);
        this.toshaixuan();
      }, 10);
    },
    // 更多招商出售
    moresale_list() {
      this.$router.push({
        path: "/indexer",
        query: { navid: 3 },
      });
    },
    // 更多找店
    morefindnav() {
      this.$router.push({
        path: "/indexer",
        query: { navid: 4 },
      });
    },
    // 找店-导航
    changefindnav(itemid) {
      this.area_unit = "1";
      this.area_min = "";
      this.area_max = "";
      this.classify_one = "";
      this.classify_two = "";
      this.region = "";
      this.mjtext = "";

      this.$router.push({
        path: "/indexer",
        query: { navid: 4 },
      });
      setTimeout(() => {
        this.classify_one = itemid;
        this.toshaixuan();
      }, 10);
    },
    // 更多写字楼
    moreroomnav() {
      this.$router.push({
        path: "/indexer",
        query: { navid: 2 },
      });
      this.isshow = false;
    },
    // 写字楼-导航
    tocutCity(city_area) {
      this.area_unit = "1";
      this.area_min = "";
      this.area_max = "";
      this.classify_one = "";
      this.classify_two = "";
      this.region = "";
      this.mjtext = "";

      this.$router.push({
        path: "/indexer",
        query: { navid: 2 },
      });
      setTimeout(() => {
        this.isshow = false;
        this.cutCity(city_area);
        this.toshaixuan();
      }, 10);
    },
    // 转让-导航
    changenav2(itemid, index, iteid, itename) {
      this.area_unit = "1";
      this.area_min = "";
      this.area_max = "";
      this.classify_one = "";
      this.classify_two = "";
      this.region = "";
      this.mjtext = "";

      this.$router.push({
        path: "/indexer",
        query: { navid: 2 },
      });
      setTimeout(() => {
        this.isshow = false;
        this.getdata();
        this.cutIndustry(itemid, index);
        this.cutIndustry2(iteid, itename);
        this.toshaixuan();
      }, 10);
    },
    // 更多推荐
    morerecomnav() {
      this.$router.push({
        path: "/indexer",
        query: { navid: 2 },
      });
      this.isshow = false;
    },
    // 推荐-导航
    changerecomnav(itemid, index) {
      this.area_unit = "1";
      this.area_min = "";
      this.area_max = "";
      this.classify_one = "";
      this.classify_two = "";
      this.region = "";
      this.mjtext = "";
      this.page = 1;

      this.$router.push({
        path: "/indexer",
        query: { navid: 2 },
      });
      setTimeout(() => {
        this.isshow = false;
        this.getdata();
        this.cutIndustry(itemid, index);
        this.cutIndustry2(this.classList[index].id, this.classList[index].name);
        this.toshaixuan();
      }, 10);
    },
    // 立即咨询
    tocall(item) {
      // this.$router.push({
      //   path: "/about",
      //   query: {navid: 5, type: 2},
      // })

      // 展示二维码
      console.log(item);
      this.isconcat = true;
      this.show_contact = item.wx_img;
    },
    // 切换快速找店
    handlezhaodian(index) {
      this.findShop = index;
    },
    // 切换shopTop
    handleNumTop(index) {
      this.cNumTop = index;
      let cid = "";
      switch (index) {
        case 0:
          cid = "zdtj";
          break;
        case 1:
          cid = "syzr";
          break;
        case 2:
          cid = "xzl";
          break;
        case 3:
          cid = "zdxz";
          break;
        case 4:
          cid = "zscs";
          break;
        default:
          break;
      }
      if (index != this.topList.length) {
        var el = document.getElementById(cid);
        this.$nextTick(function () {
          window.scrollTo({
            behavior: "smooth",
            top: el.offsetTop + -30,
          });
        });
      } else {
        this.toTop();
      }
    },
    // 到筛选
    toshaixuan() {
      console.log("g");
      var el = document.getElementById("shaixuan");
      this.$nextTick(function () {
        window.scrollTo({
          behavior: "smooth",
          top: el.offsetTop + -60,
        });
      });
    },
    // 回到顶部
    toTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    // 数据加载完成方法
    dataCompleteFun() {
      // 开启滚动
      // this.autoScroll()
      // this.autoScroll2()
      // this.autoScroll3()
    },
    // 左
    autoScroll() {
      const scrollHeight = document.getElementById("scrollId1").scrollHeight;
      const clientHeight = document.getElementById("scrollId1").clientHeight;
      console.log(scrollHeight, clientHeight);
      const scroll = scrollHeight - clientHeight;
      // 滚动长度为0
      if (scroll === 0) {
        return;
      }
      // 触发滚动方法
      this.scrollFun();
      // 去除点击监听
      // window.document.removeEventListener("click", this.pauseScroll);
      // 重设点击监听
      // window.document.addEventListener("click", this.pauseScroll, false);
    },
    pauseScroll() {
      // 定时器不为空
      if (this.scrollTimer) {
        // 清除定时器
        clearInterval(this.scrollTimer);
        this.scrollTimer = null;
        // 一秒钟后重新开始定时器
        this.pauseTimer = setTimeout(() => {
          this.scrollFun();
        }, 2000);
      }
    },
    scrollFun() {
      // 如果定时器存在
      if (this.scrollTimer) {
        // 则先清除
        clearInterval(this.scrollTimer);
        this.scrollTimer = null;
      }
      this.scrollTimer = setInterval(() => {
        const scrollHeight = document.getElementById("scrollId1").scrollHeight;
        const clientHeight = document.getElementById("scrollId1").clientHeight;
        const scroll = scrollHeight - clientHeight;
        // 获取当前滚动条距离顶部高度
        const scrollTop = document.getElementById("scrollId1").scrollTop;
        // 向下滚动
        if (this.scrollDirection === "down") {
          const temp = scrollTop + 1;
          document.getElementById("scrollId1").scrollTop = temp; // 滚动
          // 距离顶部高度  大于等于 滚动长度
          if (scroll <= temp) {
            // 滚动到底部 停止定时器
            clearInterval(this.scrollTimer);
            this.scrollTimer = null;
            // 改变方向
            this.scrollDirection = "up";
            // 一秒后重开定时器
            setTimeout(() => {
              this.scrollFun();
            }, 1000);
          }
          // 向上滚动
        } else if (this.scrollDirection === "up") {
          const temp = scrollTop - 0.5;
          document.getElementById("scrollId1").scrollTop = temp; // 滚动
          // 距离顶部高度  小于等于 0
          if (temp <= 0) {
            // 滚动到底部 停止定时器
            clearInterval(this.scrollTimer);
            this.scrollTimer = null;
            // 改变方向
            this.scrollDirection = "down";
            // 一秒后重开定时器
            setTimeout(() => {
              this.scrollFun();
            }, 1000);
          }
        }
        //150
      }, 20);
    },
    // 右
    autoScroll2() {
      const scrollHeight = document.getElementById("scrollId2").scrollHeight;
      const clientHeight = document.getElementById("scrollId2").clientHeight;
      const scroll = scrollHeight - clientHeight;
      // 滚动长度为0
      if (scroll === 0) {
        return;
      }
      // 触发滚动方法
      this.scrollFun2();
      // 去除点击监听
      // window.document.removeEventListener("click", this.pauseScroll2);
      // 重设点击监听
      // window.document.addEventListener("click", this.pauseScroll2, false);
    },
    pauseScroll2() {
      // 定时器不为空
      if (this.scrollTimer2) {
        // 清除定时器
        clearInterval(this.scrollTimer2);
        this.scrollTimer2 = null;
        // 一秒钟后重新开始定时器
        this.pauseTimer2 = setTimeout(() => {
          this.scrollFun2();
        }, 2000);
      }
    },
    scrollFun2() {
      // 如果定时器存在
      if (this.scrollTimer2) {
        // 则先清除
        clearInterval(this.scrollTimer2);
        this.scrollTimer2 = null;
      }
      this.scrollTimer2 = setInterval(() => {
        const scrollHeight = document.getElementById("scrollId2").scrollHeight;
        const clientHeight = document.getElementById("scrollId2").clientHeight;
        const scroll = scrollHeight - clientHeight;
        // 获取当前滚动条距离顶部高度
        const scrollTop = document.getElementById("scrollId2").scrollTop;
        // 向下滚动
        if (this.scrollDirection2 === "down") {
          const temp = scrollTop + 1;
          document.getElementById("scrollId2").scrollTop = temp; // 滚动
          // 距离顶部高度  大于等于 滚动长度
          if (scroll <= temp) {
            // 滚动到底部 停止定时器
            clearInterval(this.scrollTimer2);
            this.scrollTimer2 = null;
            // 改变方向
            this.scrollDirection2 = "up";
            // 一秒后重开定时器
            setTimeout(() => {
              this.scrollFun2();
            }, 1000);
          }
          // 向上滚动
        } else if (this.scrollDirection2 === "up") {
          const temp = scrollTop - 0.5;
          document.getElementById("scrollId2").scrollTop = temp; // 滚动
          // 距离顶部高度  小于等于 0
          if (temp <= 0) {
            // 滚动到底部 停止定时器
            clearInterval(this.scrollTimer2);
            this.scrollTimer2 = null;
            // 改变方向
            this.scrollDirection2 = "down";
            // 一秒后重开定时器
            setTimeout(() => {
              this.scrollFun2();
            }, 1000);
          }
        }
      }, 20);
    },
    // 下
    autoScroll3() {
      const scrollHeight = document.getElementById("scrollId3").scrollHeight;
      const clientHeight = document.getElementById("scrollId3").clientHeight;
      const scroll = scrollHeight - clientHeight;
      // 滚动长度为0
      if (scroll === 0) {
        return;
      }
      // 触发滚动方法
      this.scrollFun3();
      // 去除点击监听
      // window.document.removeEventListener("click", this.pauseScroll3);
      // 重设点击监听
      // window.document.addEventListener("click", this.pauseScroll3, false);
    },
    pauseScroll3() {
      // 定时器不为空
      if (this.scrollTimer3) {
        // 清除定时器
        clearInterval(this.scrollTimer3);
        this.scrollTimer3 = null;
        // 一秒钟后重新开始定时器
        this.pauseTimer3 = setTimeout(() => {
          this.scrollFun3();
        }, 2000);
      }
    },
    scrollFun3() {
      // 如果定时器存在
      if (this.scrollTimer3) {
        // 则先清除
        clearInterval(this.scrollTimer3);
        this.scrollTimer3 = null;
      }
      this.scrollTimer3 = setInterval(() => {
        const scrollWidth = document.getElementById("scrollId3").scrollWidth;
        const clientWidth = document.getElementById("scrollId3").clientWidth;
        const scroll = scrollWidth - clientWidth;
        // 获取当前滚动条距离顶部高度
        const scrollLeft = document.getElementById("scrollId3").scrollLeft;
        // 向下滚动
        if (this.scrollDirection3 === "down") {
          const temp = scrollLeft + 1;
          document.getElementById("scrollId3").scrollLeft = temp; // 滚动
          // 距离顶部高度  大于等于 滚动长度
          if (scroll <= temp) {
            // 滚动到底部 停止定时器
            clearInterval(this.scrollTimer3);
            this.scrollTimer3 = null;
            // 改变方向
            this.scrollDirection3 = "up";
            // 一秒后重开定时器
            setTimeout(() => {
              this.scrollFun3();
            }, 1000);
          }
          // 向上滚动
        } else if (this.scrollDirection3 === "up") {
          const temp = scrollLeft - 0.5;
          document.getElementById("scrollId3").scrollLeft = temp; // 滚动
          // 距离顶部高度  小于等于 0
          if (temp <= 0) {
            // 滚动到底部 停止定时器
            clearInterval(this.scrollTimer3);
            this.scrollTimer3 = null;
            // 改变方向
            this.scrollDirection3 = "down";
            // 一秒后重开定时器
            setTimeout(() => {
              this.scrollFun3();
            }, 1000);
          }
        }
      }, 20);
    },
    get_ad() {
      let _this = this;
      // ad_one({
      //   city: _this.city,
      // })
      //   .then((res) => {
      //     _this.ad_oneList = res.data.ad_img_list_two
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //   })
      ad_two({
        // city: _this.city,
        page: _this.page,
        city: _this.city,
        user_lat: _this.lat,
        user_lng: _this.lng,
        classify_id: _this.classify_one,
        classify_two: _this.classify_two,
        auto_sort: _this.auto_sort,
        region: _this.region,
        rent_money_min: _this.rent_money_min,
        rent_money_max: _this.rent_money_max,
        rent_time: _this.rent_time,
        sell_min: _this.sell_min,
        sell_max: _this.sell_max,
        area_min: _this.area_min,
        area_max: _this.area_max,
        area_unit: _this.area_unit,
        keywords: _this.keyword,
        money_unit: _this.money_unit,
      })
        .then((res) => {
          // let arr = res.data.ad_img_list_two;
          // let list = arr;
          // if(arr.length<3){
          //   for (let i = 0; i < 3; i++) {
          //     list.push(arr[arr.length - 1])
          //   }
          // }
          // list.length = 3;
          // _this.ad_oneList = list
          // _this.ad_twoList = list
          // _this.touban = list//头版
          _this.touban =
            res.data.front_page_list.length > 3
              ? res.data.front_page_list
              : res.data.front_page_list.concat(res.data.index_ad);
          if (_this.touban.length > 3) {
            _this.touban.length = 3;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      ad_three({
        // city: _this.city,
        page: _this.page,
        city: _this.city,
        user_lat: _this.lat,
        user_lng: _this.lng,
        classify_id: _this.classify_one,
        classify_two: _this.classify_two,
        auto_sort: _this.auto_sort,
        region: _this.region,
        rent_money_min: _this.rent_money_min,
        rent_money_max: _this.rent_money_max,
        rent_time: _this.rent_time,
        sell_min: _this.sell_min,
        sell_max: _this.sell_max,
        area_min: _this.area_min,
        area_max: _this.area_max,
        area_unit: _this.area_unit,
        keywords: _this.keyword,
        money_unit: _this.money_unit,
      })
        .then((res) => {
          // let arr = res.data.ad_img_list_three;
          // let list = arr;
          // if(arr.length<6){
          //   for (let i = 0; i < 6; i++) {
          //     list.push(arr[arr.length - 1])
          //   }
          // }
          // list.length = 6;
          // _this.ad_threeList = list;
          // _this.youxuan = list;//优选
          _this.youxuan =
            res.data.preferred_list.length > 5
              ? res.data.preferred_list
              : res.data.preferred_list.concat(res.data.index_ad);
          if (_this.youxuan.length > 5) {
            _this.youxuan.length = 5;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // setTimeout(() => {
      //   this.dataCompleteFun()
      // }, 1500)
    },
    get_grey() {
      let _this = this;
      set_grey({})
        .then((res) => {
          _this.is_grey = res.data.is_grey;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    matchingClick() {
      // clearInterval(this.youxuanTimer)
      // this.youxuanTimer = null

      // clearInterval(this.toubanTimer)
      // this.toubanTimer = null

      clearInterval(this.bargainTimer);
      this.bargainTimer = null;

      clearInterval(this.openUserTimer);
      this.openUserTimer = null;

      clearInterval(this.shopShowTimer);
      this.shopShowTimer = null;

      clearInterval(this.findshowTimer);
      this.findshowTimer = null;

      // 清理定时器
      // clearTimeout(this.pauseTimer)
      // this.pauseTimer = null
      // clearInterval(this.scrollTimer)
      // this.scrollTimer = null

      // clearTimeout(this.pauseTimer2)
      // this.pauseTimer2 = null
      // clearInterval(this.scrollTimer2)
      // this.scrollTimer2 = null

      // clearTimeout(this.pauseTimer3)
      // this.pauseTimer3 = null
      // clearInterval(this.scrollTimer3)
      // this.scrollTimer3 = null

      this.page = 1;
      this.getdata();
    },
    handleChange(e) {
      console.log(e);
      this.classify_one = e[0];
      this.classify_two = e[1];
    },
    classcut(fid, id) {
      this.classify_one = fid;
      this.industryList.forEach((item) => {
        if (fid == item.id) {
          if (item.child && item.child.length) {
            this.industryList2 = item.child;
            item.child.forEach((items) => {
              if (items.id == id) {
                this.classify_two = items.id;
                this.classify_twoName = items.name;
                this.page = 1;
                this.getdata();
              }
            });
          }
        }
      });
    },
    classclick(fid, id) {
      this.$router.push({
        path: "/indexer",
        query: { navid: 2, classfid: fid, classid: id },
      });
      // let routeData = this.$router.resolve({
      //   path: "/indexer",
      //   query: { navid: 2, classfid: fid, classid: id },
      // });
      // window.open(routeData.href, "_blank");
    },
    videoplay(video) {
      this.video = video;
      this.dialogVisible = true;
    },
    currentChange(e) {
      console.log(e);
      this.page = e;
      this.getdata();
    },
    // 广告跳转
    bannercut(item) {
      if (item.jump_type == 1 || item.jump_type == 2) {
        // this.$router.push({
        //   path: "/transferdetails",
        //   query: { navid: 10, s_type: item.jump_type, id: item.jump_value },
        // });
        let routeData = this.$router.resolve({
          path: "/transferdetails",
          query: { navid: 10, s_type: item.jump_type, id: item.jump_value },
        });
        window.open(routeData.href, "_blank");
      } else if (item.jump_type == 4) {
        // this.$router.push({
        //   path: "/Finddetails",
        //   query: { navid: 10, s_type: item.jump_type, id: item.jump_value },
        // });
        let routeData = this.$router.resolve({
          path: "/Finddetails",
          query: { navid: 10, s_type: item.jump_type, id: item.jump_value },
        });
        window.open(routeData.href, "_blank");
      } else {
        console.log("外联");
      }
    },
    // 跳转详情
    detailsCut(item) {
      console.log("跳转详情", item);
      if (item.s_type == 1 || item.s_type == 2) {
        // this.$router.push({
        //   path: "/transferdetails",
        //   query: { navid: 10, s_type: item.s_type, id: item.id },
        // });
        let routeData = this.$router.resolve({
          path: "/transferdetails",
          query: { navid: 10, s_type: item.s_type, id: item.id },
        });
        window.open(routeData.href, "_blank");
      } else if (item.s_type == 4) {
        // this.$router.push({
        //   path: "/Finddetails",
        //   query: { navid: 10, s_type: item.s_type, id: item.id },
        // });
        let routeData = this.$router.resolve({
          path: "/Finddetails",
          query: { navid: 10, s_type: item.s_type, id: item.id },
        });
        window.open(routeData.href, "_blank");
      } else if (item.s_type == 3 || item.s_type == 5) {
        let routeData = this.$router.resolve({
          path: "/SecondhandGoods",
          query: { navid: 10, s_type: item.s_type, id: item.id },
        });
        window.open(routeData.href, "_blank");
      }
    },
    // 删除筛选
    close(type) {
      if (type == "hy") {
        this.sharp_turn_one = "";
        this.sharp_turn_two = "";
        this.classify_one = "";
        this.classify_two = "";
        this.classify_twoName = "";
        this.classify_twoarr = ["", ""];
        this.industryList2 = "";
      } else if (type == "qy") {
        this.region = "";
      } else if (type == "mj") {
        this.area_min = "";
        this.area_max = "";
        this.area_unit = "1";
        this.mjtext = "";
      } else if (type == "zj") {
        this.rent_money_min = "";
        this.rent_money_max = "";
        this.rent_time = "1";
        this.zjtext = "";
      } else if (type == "zr") {
        this.sell_min = "";
        this.sell_max = "";
        this.zrtext = "";
      }
      this.page = 1;
      this.getdata();
    },
    // 推荐类型
    cutAutosort(id) {
      this.priceFlag = !this.priceFlag;
      if (id == 3) {
        if (this.priceFlag) {
          this.auto_sort = 3;
        } else {
          this.auto_sort = 4;
        }
      } else {
        this.auto_sort = id;
      }
      this.page = 1;
      this.getdata();
    },
    //首页导航旺铺切换
    WinportClick(id) {
      this.type = id;
      this.rent_money_min = "";
      this.rent_money_max = "";
      this.rent_time = "1";
      this.zjtext = "";
      this.sell_min = "";
      this.sell_max = "";
      this.zrtext = "";
      this.area_min = "";
      this.area_max = "";
      this.area_unit = "1";
      this.mjtext = "";
      this.sharp_turn_one = "";
      this.sharp_turn_two = "";
      this.classify_one = "";
      this.classify_two = "";
      this.classify_twoName = "";
      this.classify_twoarr = ["", ""];
      this.auto_sort = "";
      this.region = "";
      this.page = 1;
      this.getdata();
    },
    WinportClick2(id) {
      this.type2 = id;
      this.rent_money_min = "";
      this.rent_money_max = "";
      this.rent_time = "1";
      this.zjtext = "";
      this.sell_min = "";
      this.sell_max = "";
      this.zrtext = "";
      this.area_min = "";
      this.area_max = "";
      this.area_unit = "1";
      this.mjtext = "";
      this.sharp_turn_one = "";
      this.sharp_turn_two = "";
      this.classify_one = "";
      this.classify_two = "";
      this.classify_twoName = "";
      this.classify_twoarr = ["", ""];
      this.auto_sort = "";
      this.region = "";
      this.page = 1;
      this.getdata();
    },
    WinportClick3(id) {
      this.type3 = id;
      this.rent_money_min = "";
      this.rent_money_max = "";
      this.rent_time = "1";
      this.zjtext = "";
      this.sell_min = "";
      this.sell_max = "";
      this.zrtext = "";
      this.area_min = "";
      this.area_max = "";
      this.area_unit = "1";
      this.mjtext = "";
      this.sharp_turn_one = "";
      this.sharp_turn_two = "";
      this.classify_one = "";
      this.classify_two = "";
      this.classify_twoName = "";
      this.classify_twoarr = ["", ""];
      this.auto_sort = "";
      this.region = "";
      this.page = 1;
      this.getdata();
    },
    // 重置
    WinportClick4() {
      this.rent_money_min = "";
      this.rent_money_max = "";
      this.rent_time = "1";
      this.zjtext = "";
      this.sell_min = "";
      this.sell_max = "";
      this.zrtext = "";
      this.area_min = "";
      this.area_max = "";
      this.area_unit = "1";
      this.mjtext = "";
      this.sharp_turn_one = "";
      this.sharp_turn_two = "";
      this.classify_one = "";
      this.classify_two = "";
      this.classify_twoName = "";
      this.classify_twoarr = ["", ""];
      this.auto_sort = "";
      this.region = "";
      this.page = 1;
      this.getdata();
    },
    WinportClick5(id) {
      this.type4 = id;
      this.rent_money_min = "";
      this.rent_money_max = "";
      this.rent_time = "1";
      this.zjtext = "";
      this.sell_min = "";
      this.sell_max = "";
      this.zrtext = "";
      this.area_min = "";
      this.area_max = "";
      this.area_unit = "1";
      this.mjtext = "";
      this.sharp_turn_one = "";
      this.sharp_turn_two = "";
      this.classify_one = "";
      this.classify_two = "";
      this.classify_twoName = "";
      this.classify_twoarr = ["", ""];
      this.auto_sort = "";
      this.region = "";
      this.page = 1;
      this.getdata();
    },
    // 筛选
    screenClick(type) {
      if (type == "mj") {
        let area_unit = "";
        if (this.area_unit == 1) {
          area_unit = "平方米";
        } else {
          area_unit = "亩";
        }
        this.mjtext = this.area_min + "-" + this.area_max + area_unit;
      } else if (type == "zj") {
        let rent_time = "";
        if (this.rent_time == 1) {
          rent_time = "年";
        } else if (this.rent_time == 2) {
          rent_time = "月";
        } else {
          rent_time = "平方";
        }
        this.zjtext = this.rent_money_min + "-" + this.rent_money_max + "元/" + rent_time;
      } else if (type == "zr") {
        this.zrtext = this.sell_min + "-" + this.sell_max + "万元";
      }
      this.page = 1;
      this.getdata();
    },
    // 切换城市
    cutCity(area) {
      console.log(111111, area);
      this.region = area;
      this.page = 1;
      this.getdata();
    },
    // 热门切换行业
    cutsharp_turn2(id, i) {
      this.sharp_turn_one = id;
      if (id) {
        if (this.industryList[i].child && this.industryList[i].child.length) {
          this.classify_two = this.industryList[i].child[0].id;
          this.classify_twoName = this.industryList[i].child[0].name;
          // this.industryList2 = this.industryList[i].child
          this.page = 1;
        } else {
          this.classify_two = "";
          this.industryList2 = "";
          this.classify_twoName = "";
          this.page = 1;
        }
      } else {
        this.classify_two = "";
        this.industryList2 = "";
        this.classify_twoName = "";
        this.page = 1;
      }
      index_pc_hot({
        page: this.page,
        city: this.city,
        user_lat: this.lat,
        user_lng: this.lng,
        classify_id: this.sharp_turn_one,
        classify_two: this.classify_two,
        auto_sort: this.auto_sort,
        region: this.region,
        rent_money_min: this.rent_money_min,
        rent_money_max: this.rent_money_max,
        rent_time: this.rent_time,
        sell_min: this.sell_min,
        sell_max: this.sell_max,
        area_min: this.area_min,
        area_max: this.area_max,
        area_unit: this.area_unit,
        keywords: this.keyword,
        money_unit: this.money_unit,
      })
        .then((res) => {
          // 热门
          console.log("热门", res.data);
          // this.sharp_turn2 = res.data.index_ad
          this.sharp_turn2 =
            res.data.popular_list.length > 6
              ? res.data.popular_list
              : res.data.popular_list.concat(res.data.index_ad);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 精选切换行业
    cutsharp_turn3(id, i) {
      this.sharp_turn_two = id;
      if (id) {
        if (this.industryList[i].child && this.industryList[i].child.length) {
          this.classify_two = this.industryList[i].child[0].id;
          this.classify_twoName = this.industryList[i].child[0].name;
          // this.industryList2 = this.industryList[i].child
          this.page = 1;
        } else {
          this.classify_two = "";
          this.industryList2 = "";
          this.classify_twoName = "";
          this.page = 1;
        }
      } else {
        this.classify_two = "";
        this.industryList2 = "";
        this.classify_twoName = "";
        this.page = 1;
      }
      index_pc_featured({
        page: this.page,
        city: this.city,
        user_lat: this.lat,
        user_lng: this.lng,
        classify_id: this.sharp_turn_two,
        classify_two: this.classify_two,
        auto_sort: this.auto_sort,
        region: this.region,
        rent_money_min: this.rent_money_min,
        rent_money_max: this.rent_money_max,
        rent_time: this.rent_time,
        sell_min: this.sell_min,
        sell_max: this.sell_max,
        area_min: this.area_min,
        area_max: this.area_max,
        area_unit: this.area_unit,
        keywords: this.keyword,
        money_unit: this.money_unit,
      })
        .then((res) => {
          // 精选
          console.log("精选", res.data);
          // this.sharp_turn3 = res.data.index_ad
          this.sharp_turn3 =
            res.data.featured_list.length > 4
              ? res.data.featured_list
              : res.data.featured_list.concat(res.data.index_ad);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 切换行业
    cutIndustry(id, i) {
      this.classify_one = id;
      if (id) {
        if (this.industryList[i].child && this.industryList[i].child.length) {
          this.classify_two = this.industryList[i].child[0].id;
          this.classify_twoName = this.industryList[i].child[0].name;
          this.industryList2 = this.industryList[i].child;
          this.page = 1;
          this.getdata();
        } else {
          console.log(1111);
          this.classify_two = "";
          this.industryList2 = "";
          this.classify_twoName = "";
          this.page = 1;
          this.getdata();
        }
      } else {
        console.log(1111);
        this.classify_two = "";
        this.industryList2 = "";
        this.classify_twoName = "";
        this.page = 1;
        this.getdata();
      }
    },
    cutIndustry2(id, name) {
      this.classify_two = id;
      this.classify_twoName = name;
      this.page = 1;
      this.getdata();
    },
    // 分类-收起
    classless() {
      this.showmore = false;
      this.isshow = true;
      setTimeout(() => {
        this.handleNumTop1(this.cNumTop1);
      }, 300);
    },
    // 分类-更多
    classmore(mm, ii) {
      this.classname = "";
      this.classObj = mm;
      console.log("更多", this.classObj);
      this.classIndex = ii;
      this.showmore = true;
      this.isshow = true;

      this.toTop();
      this.classchange(mm, ii, this.industryList2);
      // category_ad({
      //     city: this.city,
      //     classify_id: mm.id,
      //     classify_two: '',
      //     area: '',
      //     rent_time: 3,//平方3
      //     area_min: '',
      //     area_max: '',
      //   })
      //     .then((res) => {
      //       this.toTop();

      //       let arr1 = res.data.more_list.filter(it=>it.is_view_img==1);
      //       let arr2 = res.data.more_list.filter(it=>it.is_view_img!=1);

      //       let temp = {
      //         id:mm.id,
      //         list:arr1,
      //         more_list:arr2
      //       }
      //       this.classObj.list = temp.list;
      //       this.classObj.more_list = temp.more_list;
      //       console.log(this.classObj);
      //       this.$forceUpdate();
      //     })
      //     .catch((err) => {
      //       console.log(err)
      //     })
    },
    // 分类-筛选
    classchange(mm, ii, m) {
      this.classIndex = ii;
      this.classname = m.name;
      console.log(m.name, m);
      this.classify_one = mm.id;
      if (m.id) {
        this.classify_two = m.id;
        this.classify_twoName = m.name;
        this.industryList2 = m;
        this.page = 1;
      } else {
        this.classify_two = this.industryList[ii].child[0].id;
        this.classify_twoName = this.industryList[ii].child[0].name;
        this.industryList2 = this.industryList[ii].child;
        this.page = 1;
      }

      let data = {};

      if (m.t == 1) {
        // 优选
        data = {
          city: this.city,
          classify_id: this.classify_one,
          // classify_two: this.classify_two,
          area: this.region,
          area_min: this.area_min,
          area_max: this.area_max,
          ad_loc: 2,
        };
      } else if (m.t == 2) {
        // 头版
        data = {
          city: this.city,
          classify_id: this.classify_one,
          // classify_two: this.classify_two,
          area: this.region,
          area_min: this.area_min,
          area_max: this.area_max,
          ad_loc: 1,
        };
      } else if (m.t == 3) {
        // 急转
        data = {
          city: this.city,
          classify_id: this.classify_one,
          // classify_two: this.classify_two,
          area: this.region,
          area_min: this.area_min,
          area_max: this.area_max,
          ad_loc: 1.3,
        };
      } else if (m.t == 4) {
        // 热门
        data = {
          city: this.city,
          classify_id: this.classify_one,
          // classify_two: this.classify_two,
          area: this.region,
          area_min: this.area_min,
          area_max: this.area_max,
          ad_loc: 1.5,
        };
      } else if (m.t == 5) {
        // 精选
        data = {
          city: this.city,
          classify_id: this.classify_one,
          // classify_two: this.classify_two,
          area: this.region,
          area_min: this.area_min,
          area_max: this.area_max,
          ad_loc: 1.7,
        };
      } else {
        // 子分类
        this.classify_two = m.id;
        data = {
          city: this.city,
          classify_id: this.classify_one,
          classify_two: this.classify_two,
          area: this.region,
          area_min: this.area_min,
          area_max: this.area_max,
        };
      }

      category_ad(data)
        .then((res) => {
          if (this.showmore) {
            let arr1 = res.data.more_list.filter((it) => it.is_view_img == 1);
            let arr2 = res.data.more_list.filter((it) => it.is_view_img != 1);
            let temp = {
              id: this.classify_one,
              list: arr1,
              more_list: arr2,
            };
            this.classObj.list = [];
            this.classObj.more_list = [];
            this.classObj.list = temp.list;
            this.classObj.more_list = temp.more_list;
            this.$forceUpdate();
          } else {
            let tempclass = this.allclass;
            tempclass.map((itemm) => {
              if (itemm.id == mm.id) {
                let arr1 = res.data.more_list.filter((it) => it.is_view_img == 1);
                let arr2 = res.data.more_list.filter((it) => it.is_view_img != 1);
                let temp = {
                  id: this.classify_one,
                  list: arr1.length > 30 ? arr1.slice(0, 30) : arr1,
                  more_list: arr2.length > 30 ? arr2.slice(0, 30) : arr2,
                };
                itemm.list = temp.list;
                itemm.more_list = temp.more_list;

                this.allclass = [];
                this.allclass = tempclass;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getclassdata() {
      let _this = this;
      classify_list({
        id: "",
        city: _this.city,
      })
        .then((res) => {
          // console.log(res)
          let arr = [];
          // if (res.data.ad_img_list_two && res.data.ad_img_list_two.length) {
          //   arr.push(res.data.ad_img_list_two);
          // }
          // if (res.data.ad_img_list_three && res.data.ad_img_list_three.length) {
          //   arr.push(res.data.ad_img_list_three);
          // }
          if (res.data.class_list && res.data.class_list.length) {
            res.data.class_list.forEach((item) => {
              if (item.ad_img && item.ad_img.length) {
                arr.push(JSON.parse(JSON.stringify(item.ad_img)));
              }
            });
          }
          this.ad_img_list_two = res.data.ad_img_list_two;
          this.ad_img_list_three = res.data.ad_img_list_three;
          this.classimgList = arr;
          this.classList = res.data.class_list;
          // this.classT = res.data.class_list

          this.classList.forEach((item) => {
            if (this.navid == 2) {
              item.child.unshift(
                {
                  t: 1,
                  name: "优选",
                },
                {
                  t: 2,
                  name: "头版",
                },
                {
                  t: 3,
                  name: "急转",
                },
                {
                  t: 4,
                  name: "热门",
                },
                {
                  t: 5,
                  name: "精选",
                }
              );
            }
            this.allclass.push({
              id: item.id,
            });
          });
          console.log("allclass", this.allclass);

          // // 获取分类列表
          // if(this.navid == 2 && this.isshow){
          //   Loading.service({
          //     text:'加载中...',
          //   });

          //   this.getallclass();
          // }

          this.topList1 = [
            {
              t1: "急转商铺",
              t2: "推荐",
            },
            {
              t1: "热门商铺",
              t2: "推荐",
            },
            {
              t1: "精选商铺",
              t2: "推荐",
            },
          ];

          this.classList.forEach((item) => {
            this.topList1.push({
              t1: item.name,
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
      classify_list({
        id: "",
        city: _this.city,
      })
        .then((res) => {
          this.classT = res.data.class_list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取分类列表
    getallclass() {
      let tempclass = this.allclass;
      this.allclass.forEach((item) => {
        category_ad({
          city: this.city,
          classify_id: item.id,
          classify_two: "",
          area: "",
          area_min: "",
          area_max: "",
        })
          .then((res) => {
            let arr1 = res.data.more_list.filter((it) => it.is_view_img == 1);
            let arr2 = res.data.more_list.filter((it) => it.is_view_img != 1);
            let temp = {
              id: item.id,
              list: arr1.length > 30 ? arr1.slice(0, 30) : arr1,
              more_list: arr2.length > 30 ? arr2.slice(0, 30) : arr2,
            };
            console.log("temp", temp);
            tempclass.map((itemm, indexx) => {
              if (itemm.id == temp.id) {
                // console.log('9999999');
                itemm.list = temp.list;
                itemm.more_list = temp.more_list;

                if (indexx == tempclass.length - 1) {
                  Loading.service().close();
                  this.allclass = [];
                  this.allclass = tempclass;
                  this.isclass = true;
                }
              }
            });
            // console.log('ttt',tempclass);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    // hoveryouxuan(){
    //   clearInterval(this.youxuanTimer)
    //   this.youxuanTimer = null
    // },
    // leaveyouxuan(){
    //   this.youxuanTimer = setInterval(this.scrollyouxuan, 2000)
    // },
    // scrollyouxuan() {
    //   this.youxuanEl.classList.add("transaction2")
    //   this.youxuanEl.style = "margin-top:-120px;"
    //   this.youxuan.push(...this.youxuan.slice(0, 1))
    //   setTimeout(() => {
    //     this.youxuanEl.classList.remove("transaction2")
    //     this.youxuanEl.style = "margin-top:0rem;"
    //     this.youxuan.splice(0, 1)
    //   }, 2000)
    // },
    // hovertouban(){
    //   clearInterval(this.toubanTimer)
    //   this.toubanTimer = null
    // },
    // leavetouban(){
    //   this.toubanTimer = setInterval(this.scrolltouban, 2000)
    // },
    // scrolltouban() {
    //   this.toubanEl.classList.add("transaction2")
    //   this.toubanEl.style = "margin-left:-298px;"
    //   this.touban.push(...this.touban.slice(0, 1))
    //   setTimeout(() => {
    //     this.toubanEl.classList.remove("transaction2")
    //     this.toubanEl.style = "margin-left:0rem;"
    //     this.touban.splice(0, 1)
    //   }, 2000)
    // },
    hoverbargain() {
      clearInterval(this.bargainTimer);
      this.bargainTimer = null;
    },
    leavebargain() {
      this.bargainTimer = setInterval(this.scrollbar, 2000);
    },
    scrollBar() {
      this.bargainEl.classList.add("transaction2");
      this.bargainEl.style = "margin-top:-1.5rem;";
      this.bargain.push(...this.bargain.slice(0, 1));
      setTimeout(() => {
        this.bargainEl.classList.remove("transaction2");
        this.bargainEl.style = "margin-top:0rem;";
        this.bargain.splice(0, 1);
      }, 2000);
    },
    hoveropenUser() {
      clearInterval(this.openUserTimer);
      this.openUserTimer = null;
    },
    leaveopenUser() {
      this.openUserTimer = setInterval(this.scrollopenUser, 2000);
    },
    scrollopenUser() {
      this.openUserEl.classList.add("transaction2");
      this.openUserEl.style = "margin-top:-5.3125rem;";
      this.openUser.push(...this.openUser.slice(0, 1));
      setTimeout(() => {
        this.openUserEl.classList.remove("transaction2");
        this.openUserEl.style = "margin-top:0rem;";
        this.openUser.splice(0, 1);
      }, 2000);
    },
    hoverfindshow() {
      clearInterval(this.findshowTimer);
      this.findshowTimer = null;
    },
    leavefindshow() {
      this.findshowTimer = setInterval(this.scrollfindshow, 2000);
    },
    scrollfindshow() {
      this.findshowEl.classList.add("transaction2");
      this.findshowEl.style = "margin-top:-2.5625rem;";
      this.findshow.push(...this.findshow.slice(0, 1));
      setTimeout(() => {
        this.findshowEl.classList.remove("transaction2");
        this.findshowEl.style = "margin-top:0rem;";
        this.findshow.splice(0, 1);
      }, 2000);
    },
    getdata() {
      let _this = this;
      this.store_list = [];
      this.list = [];
      this.industryList = [];
      this.areaList = [];
      console.log(this.navid);
      if (this.navid == 1) {
        index({
          type: _this.type,
          page: _this.page,
          city: _this.city,
          lat: _this.lat,
          lng: _this.lng,
          classify_id: _this.classify_one,
          classify_two: _this.classify_two,
          auto_sort: _this.auto_sort,
          region: _this.region,
          rent_money_min: _this.rent_money_min,
          rent_money_max: _this.rent_money_max,
          rent_time: _this.rent_time,
          sell_min: _this.sell_min,
          sell_max: _this.sell_max,
          area_min: _this.area_min,
          area_max: _this.area_max,
          area_unit: _this.area_unit,
          keywords: _this.keyword,
          ad_loc:'1,1.3',// 头版急转
          pc_push:1,
        })
          .then((res) => {
            console.log('重点推荐',res);
            // this.store_list = res.data.store_list

            let recomAll = res.data.store_list; //重点推荐
            this.recomAll = recomAll.length>30?recomAll.slice(0,30):recomAll;

            // this.openUser = res.data.store_list;//已开通用户
            // this.shopShow = res.data.store_list;//生意转让-店铺动态

            if (this.recomAll.length <= 10) {
              this.recomlist = this.recomAll;
            } else {
              this.recomlist = this.recomAll.slice(0, 10);
            }

            this.list = res.data.ad_img_list;
            if (res.data.t && res.data.t.length) {
              let arr = [];
              arr.push({
                id: "",
                name: "不限",
                child: [
                  {
                    id: "",
                    name: "不限",
                  },
                ],
              });
              res.data.t.forEach((item) => {
                arr.push(item);
              });
              _this.industryList = arr;
              _this.recomnav =
                _this.industryList.length > 12
                  ? _this.industryList.slice(0, 12)
                  : _this.industryList;
            }
            if (res.data.region && res.data.region.length) {
              let arr2 = [];
              arr2.push({
                city_area: "不限",
              });
              res.data.region.forEach((item) => {
                arr2.push(item);
              });
              _this.areaList = arr2;
              _this.roomnav =
                _this.areaList.length > 18 ? _this.areaList.slice(0, 18) : _this.areaList;
              _this.sale_list_onenav =
                _this.areaList.length > 10 ? _this.areaList.slice(0, 10) : _this.areaList;
              _this.sale_list_twonav =
                _this.areaList.length > 10 ? _this.areaList.slice(0, 10) : _this.areaList;

              get_index_office({
                city: _this.city,
                keywords: _this.keyword,
              })
                .then((respo) => {
                  // console.log("resp1", resp1)
                  _this.roomlist = respo.data; //写字楼 厂房
                })
                .catch((err) => {
                  console.log(err);
                });
              // 已成交
              index({
                type: 4,
                page: _this.page,
                city: _this.city,
                lat: _this.lat,
                lng: _this.lng,
                classify_id: _this.classify_one,
                classify_two: _this.classify_two,
                auto_sort: _this.auto_sort,
                region: _this.region,
                rent_money_min: _this.rent_money_min,
                rent_money_max: _this.rent_money_max,
                rent_time: _this.rent_time,
                sell_min: _this.sell_min,
                sell_max: _this.sell_max,
                area_min: _this.area_min,
                area_max: _this.area_max,
                area_unit: _this.area_unit,
                keywords: _this.keyword,
              })
                .then((respo) => {
                  _this.bargain = respo.data.store_list; //已成交列表
                  // console.log('bag',_this.bargain);
                })
                .catch((err) => {
                  console.log(err);
                });
              // 已开通用户
              index({
                type: _this.type,
                page: _this.page,
                city: _this.city,
                lat: _this.lat,
                lng: _this.lng,
                classify_id: _this.classify_one,
                classify_two: _this.classify_two,
                auto_sort: _this.auto_sort,
                region: _this.region,
                rent_money_min: _this.rent_money_min,
                rent_money_max: _this.rent_money_max,
                rent_time: _this.rent_time,
                sell_min: _this.sell_min,
                sell_max: _this.sell_max,
                area_min: _this.area_min,
                area_max: _this.area_max,
                area_unit: _this.area_unit,
                keywords: _this.keyword,
              })
                .then((respo) => {
                  this.openUser = respo.data.store_list; //已开通用户
                })
                .catch((err) => {
                  console.log(err);
                });
              // 生意转让-店铺动态
              index({
                type: _this.type,
                page: _this.page,
                city: _this.city,
                lat: _this.lat,
                lng: _this.lng,
                classify_id: _this.classify_one,
                classify_two: _this.classify_two,
                auto_sort: _this.auto_sort,
                region: _this.region,
                rent_money_min: _this.rent_money_min,
                rent_money_max: _this.rent_money_max,
                rent_time: _this.rent_time,
                sell_min: _this.sell_min,
                sell_max: _this.sell_max,
                area_min: _this.area_min,
                area_max: _this.area_max,
                area_unit: _this.area_unit,
                keywords: _this.keyword,
              })
                .then((respo) => {
                  this.shopShow = respo.data.store_list; //生意转让-店铺动态
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            console.log(_this.areaList);
            _this.page_nums = res.data.page_nums;
          })
          .catch((err) => {
            console.log(err);
          });
        // 改
        if (_this.region == "不限") _this.region = "";
        lease_list_one({
          page: _this.page,
          city: _this.city,
          lat: _this.lat,
          lng: _this.lng,
          classify_two: _this.classify_two,
          auto_sort: _this.auto_sort,
          region: _this.region,
          rent_money_min: _this.rent_money_min,
          rent_money_max: _this.rent_money_max,
          rent_time: _this.rent_time,
          sell_min: _this.sell_min,
          sell_max: _this.sell_max,
          area_min: _this.area_min,
          area_max: _this.area_max,
          area_unit: _this.area_unit,
          title: _this.keyword,
        })
          .then((res) => {
            console.log(res);
            // this.store_list = res.data.store_list

            let arr = [];
            arr.push({
              id: "",
              name: "不限",
              child: [
                {
                  id: "",
                  name: "不限",
                },
              ],
            });
            res.data.t.forEach((item) => {
              arr.push(item);
            });
            _this.industryList = arr;
            let arr2 = [];
            arr2.push({
              city_area: "",
            });
            res.data.region.forEach((item) => {
              arr2.push(item);
            });
            _this.areaList = arr2;
            _this.page_nums = res.data.page_nums;
            _this.index_ad = res.data.index_ad;
            console.log("_this.index_ad", _this.index_ad);
          })
          .catch((err) => {
            console.log(err);
          });
        ivn_sale_list_one({
          page: _this.page,
          city: _this.city,
          lat: _this.lat,
          lng: _this.lng,
          classify_two: _this.classify_two,
          auto_sort: _this.auto_sort,
          region: _this.region,
          rent_money_min: _this.rent_money_min,
          rent_money_max: _this.rent_money_max,
          rent_time: _this.rent_time,
          sell_min: _this.sell_min,
          sell_max: _this.sell_max,
          area_min: _this.area_min,
          area_max: _this.area_max,
          area_unit: _this.area_unit,
          title: _this.keyword,
        })
          .then((res) => {
            console.log(res);
            this.sale_list_one =
              res.data.store_list.length > 9
                ? res.data.store_list.slice(0, 9)
                : res.data.store_list;
            // let arr = []
            // arr.push({
            //   id: "",
            //   name: "不限",
            //   child: [
            //     {
            //       id: "",
            //       name: "不限",
            //     },
            //   ],
            // })
            // res.data.t.forEach((item) => {
            //   arr.push(item)
            // })
            // _this.industryList = arr
            // let arr2 = []
            // arr2.push({
            //   city_area: "",
            // })
            // res.data.region.forEach((item) => {
            //   arr2.push(item)
            // })
            // _this.areaList = arr2
            // _this.page_nums = res.data.page_nums
            // _this.index_ad = res.data.index_ad
          })
          .catch((err) => {
            console.log(err);
          });
        ivn_sale_list_two({
          page: _this.page,
          city: _this.city,
          lat: _this.lat,
          lng: _this.lng,
          classify_two: _this.classify_two,
          auto_sort: _this.auto_sort,
          region: _this.region,
          rent_money_min: _this.rent_money_min,
          rent_money_max: _this.rent_money_max,
          rent_time: _this.rent_time,
          sell_min: _this.sell_min,
          sell_max: _this.sell_max,
          area_min: _this.area_min,
          area_max: _this.area_max,
          area_unit: _this.area_unit,
          title: _this.keyword,
        })
          .then((res) => {
            console.log(res);
            this.sale_list_two =
              res.data.store_list.length > 9
                ? res.data.store_list.slice(0, 9)
                : res.data.store_list;
            // let arr = []
            // arr.push({
            //   id: "",
            //   name: "不限",
            //   child: [
            //     {
            //       id: "",
            //       name: "不限",
            //     },
            //   ],
            // })
            // res.data.t.forEach((item) => {
            //   arr.push(item)
            // })
            // _this.industryList = arr
            // let arr2 = []
            // arr2.push({
            //   city_area: "",
            // })
            // res.data.region.forEach((item) => {
            //   arr2.push(item)
            // })
            // _this.areaList = arr2
            // _this.page_nums = res.data.page_nums
            // _this.index_ad = res.data.index_ad
          })
          .catch((err) => {
            console.log(err);
          });
        find_shop_list({
          page: _this.page,
          city: _this.city,
          city_area: "",
          lat: _this.lat,
          lng: _this.lng,
          classify_two: _this.classify_two,
          auto_sort: _this.auto_sort,
          expect_area: _this.region,
          rent_money_min: _this.rent_money_min,
          rent_money_max: _this.rent_money_max,
          rent_time: _this.rent_time,
          sell_min: _this.sell_min,
          sell_max: _this.sell_max,
          area_min: _this.area_min,
          area_max: _this.area_max,
          area_unit: _this.area_unit,
          title: _this.keyword,
        })
          .then((res) => {
            console.log("找店", res);
            this.find_list =
              res.data.list.length > 6 ? res.data.list.slice(0, 6) : res.data.list;
            this.all_find_shop_nums = res.data.all_find_shop_nums;
            this.today_find_shop_nums = res.data.today_find_shop_nums;
            this.findshow = res.data.list; //找店li

            let arr = [];
            arr.push({
              id: "",
              name: "不限",
              child: [
                {
                  id: "",
                  name: "不限",
                },
              ],
            });
            res.data.t.forEach((item) => {
              arr.push(item);
            });
            _this.industryList = arr;
            let arr2 = [];
            arr2.push({
              city_area: "",
            });
            res.data.region.forEach((item) => {
              arr2.push(item);
            });
            _this.areaList = arr2;
            _this.page_nums = res.data.all_page;
            _this.index_ad = res.data.index_ad;
          })
          .catch((err) => {
            console.log(err);
          });
        // 立即咨询列表
        get_consultant_list({
          city: this.city,
        }).then((res) => {
          console.log("立即咨询", res.data);
          this.consultant_list = res.data;
          // 补缺
          if (this.consultant_list.length < 5) {
            let temp = this.consultant_list[this.consultant_list.length - 1];
            let arr = this.consultant_list;
            for (let i = 0; i <= 5 - this.consultant_list.length + 1; i++) {
              // console.log('temp',i);
              arr.push(temp);
            }
            this.consultant_list = arr;
          }
          // console.log('111111',this.consultant_list);
          // 处理数据
          this.contactlist.map((item, index) => {
            item.phone = "咨询电话：" + this.consultant_list[index].phone;
            item.wx_img = this.consultant_list[index].wx_img;
          });
          // console.log('111',this.contactlist);
        });
      } else if (this.navid == 2) {
        if (this.type2 == 1) {
          lease_list_one({
            page: _this.page,
            city: _this.city,
            lat: _this.lat,
            lng: _this.lng,
            classify_two: _this.classify_two,
            auto_sort: _this.auto_sort,
            region: _this.region,
            rent_money_min: _this.rent_money_min,
            rent_money_max: _this.rent_money_max,
            rent_time: _this.rent_time,
            sell_min: _this.sell_min,
            sell_max: _this.sell_max,
            area_min: _this.area_min,
            area_max: _this.area_max,
            area_unit: _this.area_unit,
            title: _this.keyword,
          })
            .then((res) => {
              console.log(res);
              this.store_list = res.data.store_list;
              let arr = [];
              arr.push({
                id: "",
                name: "不限",
                child: [
                  {
                    id: "",
                    name: "不限",
                  },
                ],
              });
              res.data.t.forEach((item) => {
                arr.push(item);
              });
              _this.industryList = arr;
              let arr2 = [];
              arr2.push({
                city_area: "",
              });
              res.data.region.forEach((item) => {
                arr2.push(item);
              });
              _this.areaList = arr2;
              _this.page_nums = res.data.page_nums;
              _this.index_ad = res.data.index_ad;
              console.log("_this.index_ad", _this.index_ad);
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (this.type2 == 2) {
          lease_list_two({
            page: _this.page,
            city: _this.city,
            lat: _this.lat,
            lng: _this.lng,
            classify_two: _this.classify_two,
            auto_sort: _this.auto_sort,
            region: _this.region,
            rent_money_min: _this.rent_money_min,
            rent_money_max: _this.rent_money_max,
            rent_time: _this.rent_time,
            sell_min: _this.sell_min,
            sell_max: _this.sell_max,
            area_min: _this.area_min,
            area_max: _this.area_max,
            area_unit: _this.area_unit,
            title: _this.keyword,
          })
            .then((res) => {
              console.log(res);
              this.store_list = res.data.store_list;
              let arr = [];
              arr.push({
                id: "",
                name: "不限",
                child: [
                  {
                    id: "",
                    name: "不限",
                  },
                ],
              });
              res.data.t.forEach((item) => {
                arr.push(item);
              });
              _this.industryList = arr;
              let arr2 = [];
              arr2.push({
                city_area: "",
              });
              res.data.region.forEach((item) => {
                arr2.push(item);
              });
              _this.areaList = arr2;
              _this.page_nums = res.data.page_nums;
              _this.index_ad = res.data.index_ad;
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          // console.log('navid2');
          index({
            page: _this.page,
            city: _this.city,
            lat: _this.lat,
            lng: _this.lng,
            classify_one: _this.classify_one,
            classify_two: _this.classify_two,
            auto_sort: _this.auto_sort,
            region: _this.region,
            rent_money_min: _this.rent_money_min,
            rent_money_max: _this.rent_money_max,
            rent_time: _this.rent_time,
            sell_min: _this.sell_min,
            sell_max: _this.sell_max,
            area_min: _this.area_min,
            area_max: _this.area_max,
            area_unit: _this.area_unit,
            title: _this.keyword,
          })
            .then((res) => {
              console.log(res);
              this.store_list = res.data.store_list;
              let arr = [];
              arr.push({
                id: "",
                name: "不限",
                child: [
                  {
                    id: "",
                    name: "不限",
                  },
                ],
              });
              res.data.t.forEach((item) => {
                arr.push(item);
              });
              _this.industryList = arr;

              // 热门商铺nav
              let arrhot = [];
              arrhot.push({
                id: "",
                name: "热门商铺",
                child: [
                  {
                    id: "",
                    name: "热门商铺",
                  },
                ],
              });
              res.data.t.forEach((item) => {
                arrhot.push(item);
              });
              _this.industryHot = arrhot;
              // 精选商铺nav
              let arrfeature = [];
              arrfeature.push({
                id: "",
                name: "精选商铺",
                child: [
                  {
                    id: "",
                    name: "精选商铺",
                  },
                ],
              });
              res.data.t.forEach((item) => {
                arrfeature.push(item);
              });
              _this.industryFeature = arrfeature;

              let arr2 = [];
              arr2.push({
                city_area: "",
              });
              res.data.region.forEach((item) => {
                arr2.push(item);
              });
              _this.areaList = arr2;
              _this.page_nums = res.data.page_nums;
              // _this.index_ad = res.data.index_ad
              lease_list({
                page: _this.page,
                city: _this.city,
                lat: _this.lat,
                lng: _this.lng,
                classify_one: _this.classify_one,
                classify_two: _this.classify_two,
                auto_sort: _this.auto_sort,
                region: _this.region,
                rent_money_min: _this.rent_money_min,
                rent_money_max: _this.rent_money_max,
                rent_time: _this.rent_time,
                sell_min: _this.sell_min,
                sell_max: _this.sell_max,
                area_min: _this.area_min,
                area_max: _this.area_max,
                area_unit: _this.area_unit,
                title: _this.keyword,
              })
                .then((res) => {
                  console.log(res);
                  _this.index_ad = res.data.index_ad;
                })
                .catch((err) => {
                  console.log(err);
                });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else if (this.navid == 3) {
        if (this.type3 == 1) {
          ivn_sale_list_one({
            page: _this.page,
            city: _this.city,
            lat: _this.lat,
            lng: _this.lng,
            classify_two: _this.classify_two,
            auto_sort: _this.auto_sort,
            region: _this.region,
            rent_money_min: _this.rent_money_min,
            rent_money_max: _this.rent_money_max,
            rent_time: _this.rent_time,
            sell_min: _this.sell_min,
            sell_max: _this.sell_max,
            area_min: _this.area_min,
            area_max: _this.area_max,
            area_unit: _this.area_unit,
            title: _this.keyword,
          })
            .then((res) => {
              console.log(res);
              this.store_list = res.data.store_list;
              let arr = [];
              arr.push({
                id: "",
                name: "不限",
                child: [
                  {
                    id: "",
                    name: "不限",
                  },
                ],
              });
              res.data.t.forEach((item) => {
                arr.push(item);
              });
              _this.industryList = arr;
              let arr2 = [];
              arr2.push({
                city_area: "",
              });
              res.data.region.forEach((item) => {
                arr2.push(item);
              });
              _this.areaList = arr2;
              _this.page_nums = res.data.page_nums;
              _this.index_ad = res.data.index_ad;
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (this.type3 == 2) {
          ivn_sale_list_two({
            page: _this.page,
            city: _this.city,
            lat: _this.lat,
            lng: _this.lng,
            classify_two: _this.classify_two,
            auto_sort: _this.auto_sort,
            region: _this.region,
            rent_money_min: _this.rent_money_min,
            rent_money_max: _this.rent_money_max,
            rent_time: _this.rent_time,
            sell_min: _this.sell_min,
            sell_max: _this.sell_max,
            area_min: _this.area_min,
            area_max: _this.area_max,
            area_unit: _this.area_unit,
            title: _this.keyword,
          })
            .then((res) => {
              console.log(res);
              this.store_list = res.data.store_list;
              let arr = [];
              arr.push({
                id: "",
                name: "不限",
                child: [
                  {
                    id: "",
                    name: "不限",
                  },
                ],
              });
              res.data.t.forEach((item) => {
                arr.push(item);
              });
              _this.industryList = arr;
              let arr2 = [];
              arr2.push({
                city_area: "",
              });
              res.data.region.forEach((item) => {
                arr2.push(item);
              });
              _this.areaList = arr2;
              _this.page_nums = res.data.page_nums;
              _this.index_ad = res.data.index_ad;
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          index({
            page: _this.page,
            city: _this.city,
            lat: _this.lat,
            lng: _this.lng,
            classify_two: _this.classify_two,
            auto_sort: _this.auto_sort,
            region: _this.region,
            rent_money_min: _this.rent_money_min,
            rent_money_max: _this.rent_money_max,
            rent_time: _this.rent_time,
            sell_min: _this.sell_min,
            sell_max: _this.sell_max,
            area_min: _this.area_min,
            area_max: _this.area_max,
            area_unit: _this.area_unit,
            title: _this.keyword,
          })
            .then((res) => {
              console.log(res);
              this.store_list = res.data.store_list;
              let arr = [];
              arr.push({
                id: "",
                name: "不限",
                child: [
                  {
                    id: "",
                    name: "不限",
                  },
                ],
              });
              res.data.t.forEach((item) => {
                arr.push(item);
              });
              _this.industryList = arr;
              let arr2 = [];
              arr2.push({
                city_area: "",
              });
              res.data.region.forEach((item) => {
                arr2.push(item);
              });
              _this.areaList = arr2;
              _this.page_nums = res.data.page_nums;
              _this.index_ad = res.data.ad_img_list;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else if (this.navid == 4) {
        find_shop_list({
          page: _this.page,
          city: _this.city,
          city_area: "",
          lat: _this.lat,
          lng: _this.lng,
          classify_two: _this.classify_two,
          auto_sort: _this.auto_sort,
          expect_area: _this.region,
          rent_money_min: _this.rent_money_min,
          rent_money_max: _this.rent_money_max,
          rent_time: _this.rent_time,
          sell_min: _this.sell_min,
          sell_max: _this.sell_max,
          area_min: _this.area_min,
          area_max: _this.area_max,
          area_unit: _this.area_unit,
          title: _this.keyword,
        })
          .then((res) => {
            console.log(res);
            this.store_list = res.data.list;
            let arr = [];
            arr.push({
              id: "",
              name: "不限",
              child: [
                {
                  id: "",
                  name: "不限",
                },
              ],
            });
            res.data.t.forEach((item) => {
              arr.push(item);
            });
            _this.industryList = arr;
            let arr2 = [];
            arr2.push({
              city_area: "",
            });
            res.data.region.forEach((item) => {
              arr2.push(item);
            });
            _this.areaList = arr2;
            _this.page_nums = res.data.all_page;
            _this.index_ad = res.data.index_ad;
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (this.navid == 7) {
        if (this.type4 == 1) {
          old_goods_list({
            page: _this.page,
            city: _this.city,
            auto_sort: _this.auto_sort,
            city_area: _this.region,
            title: _this.keyword,
          })
            .then((res) => {
              console.log(res);
              this.store_list = res.data.list;
              let arr2 = [];
              arr2.push({
                city_area: "",
              });
              let regiontemp = res.data.region;
              regiontemp.forEach((item) => {
                arr2.push(item);
              });
              _this.areaList = arr2;
              _this.page_nums = res.data.all_page;
              _this.index_ad = res.data.index_ad;
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          acquisition_list({
            page: _this.page,
            city: _this.city,
            auto_sort: _this.auto_sort,
            city_area: _this.region,
            title: _this.keyword,
          })
            .then((res) => {
              console.log(res);
              this.store_list = res.data.list;
              let arr2 = [];
              arr2.push({
                city_area: "",
              });
              res.data.region.forEach((item) => {
                arr2.push(item);
              });
              _this.areaList = arr2;
              _this.page_nums = res.data.all_page;
              _this.index_ad = res.data.index_ad;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
      if (this.isshow && this.navid == 2) {
        // 获取急转/热门/精选
        index_pc_sharp_turn({
          page: _this.page,
          city: _this.city,
          user_lat: _this.lat,
          user_lng: _this.lng,
          rent_time: _this.rent_time,
        })
          .then((res) => {
            // 急转
            console.log("急转", res.data);
            let datalist = res.data.sharp_turn;
            let adlist = res.data.index_ad;
            let ad1 = [];
            let ad2 = [];
            if(adlist.length>0){
              if(adlist.length>=5 && adlist.length<10){
                ad1 = adlist.slice(0,5);
                ad2 = adlist.concat(adlist.slice(0,10 - adlist.length));
              }else{
                ad1 = adlist.slice(0,5);
                ad2 = adlist.slice(0,10);
              }
              // console.log(ad1,ad2);
              if(datalist.length==0){
                this.sharp_turn1 = adlist.length>5 && adlist.length<11?adlist.concat(adlist.slice(0,11 - adlist.length)):adlist.slice(0,11);
              }else if(datalist.length>0 && datalist.length<11){
                this.sharp_turn1 = (11 - datalist.length) > 5?datalist.concat(ad2.slice(0,11 - datalist.length)):datalist.concat(ad1.slice(0,11 - datalist.length))
              }else if((datalist.length>11) && ((datalist.length - 1)%10)){
                this.sharp_turn1 = ((datalist.length - 1)%10) > 5?datalist.concat(ad2.slice(0,((datalist.length - 1)%10))):datalist.concat(ad1.slice(0,((datalist.length - 1)%10)))
              }else{
                this.sharp_turn1 = datalist;
              }
            }else{
              this.sharp_turn1 = datalist;
            }
            // console.log(this.sharp_turn1);
            // this.sharp_turn1 =
            //   res.data.sharp_turn.length > 11
            //     ? res.data.sharp_turn
            //     : res.data.sharp_turn.concat(res.data.index_ad);
          })
          .catch((err) => {
            console.log(err);
          });
        index_pc_hot({
          page: _this.page,
          city: _this.city,
          user_lat: _this.lat,
          user_lng: _this.lng,
          classify_id: _this.classify_one,
          classify_two: _this.classify_two,
          auto_sort: _this.auto_sort,
          region: _this.region,
          rent_money_min: _this.rent_money_min,
          rent_money_max: _this.rent_money_max,
          rent_time: _this.rent_time,
          sell_min: _this.sell_min,
          sell_max: _this.sell_max,
          area_min: _this.area_min,
          area_max: _this.area_max,
          area_unit: _this.area_unit,
          keywords: _this.keyword,
          money_unit: _this.money_unit,
        })
          .then((res) => {
            // 热门
            console.log("热门", res.data);
            let datalist = res.data.popular_list;
            let adlist = res.data.index_ad;
            let ad1 = [];
            let ad2 = [];
            if(adlist.length>0){
              if(adlist.length>=6 && adlist.length<12){
                ad1 = adlist.slice(0,6);
                ad2 = adlist.concat(adlist.slice(0,12 - adlist.length));
              }else{
                ad1 = adlist.slice(0,6);
                ad2 = adlist.slice(0,12);
              }
              console.log(ad1,ad2);
              if(datalist.length==0){
                this.sharp_turn2 = ad2;
              }else if(datalist.length>0 && datalist.length<12){
                this.sharp_turn2 = (12 - datalist.length) > 6?datalist.concat(ad2.slice(0,12 - datalist.length)):datalist.concat(ad1.slice(0,12 - datalist.length))
              }else if((datalist.length>12) && (datalist.length%12)){
                this.sharp_turn2 = (datalist.length%12) > 6?datalist.concat(ad2.slice(0,(datalist.length%12))):datalist.concat(ad1.slice(0,(datalist.length%12)))
              }else{
                this.sharp_turn2 = datalist;
              }
            }else{
              this.sharp_turn2 = datalist;
            }
            // this.sharp_turn2 =
            //   res.data.popular_list.length > 11
            //     ? res.data.popular_list
            //     : res.data.popular_list.concat(res.data.index_ad);
          })
          .catch((err) => {
            console.log(err);
          });
        index_pc_featured({
          page: _this.page,
          city: _this.city,
          user_lat: _this.lat,
          user_lng: _this.lng,
          classify_id: _this.classify_one,
          classify_two: _this.classify_two,
          auto_sort: _this.auto_sort,
          region: _this.region,
          rent_money_min: _this.rent_money_min,
          rent_money_max: _this.rent_money_max,
          rent_time: _this.rent_time,
          sell_min: _this.sell_min,
          sell_max: _this.sell_max,
          area_min: _this.area_min,
          area_max: _this.area_max,
          area_unit: _this.area_unit,
          keywords: _this.keyword,
          money_unit: _this.money_unit,
        })
          .then((res) => {
            // 精选
            console.log("精选", res.data);
            let datalist = res.data.featured_list;
            let adlist = res.data.index_ad;
            let ad1 = [];
            let ad2 = [];
            if(adlist.length>0){
              if(adlist.length>=4 && adlist.length<8){
                ad1 = adlist.slice(0,4);
                ad2 = adlist.concat(adlist.slice(0,8 - adlist.length));
              }else{
                ad1 = adlist.slice(0,4);
                ad2 = adlist.slice(0,8);
              }
              // console.log(ad1,ad2);
              if(datalist.length==0){
                this.sharp_turn3 = ad2;
              }else if(datalist.length>0 && datalist.length<8){
                this.sharp_turn3 = (8 - datalist.length) > 4?datalist.concat(ad2.slice(0,8 - datalist.length)):datalist.concat(ad1.slice(0,8 - datalist.length))
              }else if((datalist.length>8) && (datalist.length%8)){
                this.sharp_turn3 = (datalist.length%8) > 4?datalist.concat(ad2.slice(0,(datalist.length%8))):datalist.concat(ad1.slice(0,(datalist.length%8)))
              }else{
                this.sharp_turn3 = datalist;
              }
            }else{
              this.sharp_turn3 = datalist;
            }
            // this.sharp_turn3 =
            //   res.data.featured_list.length > 8
            //     ? res.data.featured_list
            //     : res.data.featured_list.concat(res.data.index_ad);

            let jingxuanEl = document.querySelector("#jingxuan");
            let offsetTop = jingxuanEl.offsetTop;

            window.addEventListener("scroll", () => {
              let scrollTop = document.documentElement.scrollTop;

              if (scrollTop > 500) {
                this.istopnav = true;
              } else {
                this.istopnav = false;
              }

              // console.log('scroll',scrollTop);
              if (
                offsetTop <= scrollTop &&
                _this.navid == 2 &&
                _this.isshow &&
                !_this.isclass
              ) {
                console.log(offsetTop, "scroll", scrollTop);
                // 获取分类列表
                Loading.service({
                  text: "加载中...",
                });
                _this.getallclass();
                setTimeout(() => {
                  _this.isclass = true;
                }, 30);
              }
            });
          })
          .catch((err) => {
            console.log(err);
          });

        // 获取首页弹窗
        home_index({
          city: _this.city,
          area: _this.city_area,
          lat: _this.lat,
          lng: _this.lng,
        })
          .then((res) => {
            _this.index_pop = res.data.index_ad;
            // _this.ispop = true;
            // _this.onepop = true;
            if (_this.onepop) {
              _this.ispop = false;
            } else {
              _this.ispop = true;
              _this.onepop = true;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    //获取定位
    getLocation() {
      var geolocation = new window.qq.maps.Geolocation(
        "56EBZ-EQQEX-ITM4I-TG262-WXVIJ-QKFBT",
        "key_map"
      );
      geolocation.getLocation(this.showPosition, this.errorPosition);
    },
    showPosition(position) {
      // console.log('成功',position)
      // this.lng = position.lng
      // this.lat = position.lat
      // this.city = position.city
      // this.province = position.province
      // window.localStorage.setItem("lng", position.lng)
      // window.localStorage.setItem("lat", position.lat)
      // window.localStorage.setItem("city", position.city)
      // // window.localStorage.setItem("city_area", position.district);
      // // window.localStorage.setItem("address", position.address);
      // window.localStorage.setItem("province", position.province)
      // this.page = 1
      // this.getdata()
      // this.getclassdata()
      // if (this.navid != 1) {
      //   this.get_ad()
      // }
      console.log(position);
      this.lng = position.lng;
      this.lat = position.lat;
      get_add({
        lat: position.lat,
        lng: position.lng,
      })
        .then((res) => {
          console.log(res);
          this.city = res.data.address_component.city;
          this.province = res.data.address_component.province;
          this.city_area = res.data.address_component.district;
          window.localStorage.setItem("lng", position.lng);
          window.localStorage.setItem("lat", position.lat);
          window.localStorage.setItem("province", res.data.address_component.province);
          window.localStorage.setItem("city", res.data.address_component.city);
          window.localStorage.setItem("city_area", res.data.address_component.district);
          window.localStorage.setItem("address", res.data.address);
          this.page = 1;
          this.getdata();
          this.getclassdata();
          if (this.navid != 1) {
            this.get_ad();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    errorPosition() {
      console.log("定位失败");
      //继续定位
      this.getLocation();
    },
    getPosition() {
      //点击一次获取一次
      this.getLocation();
    },
  },
};
</script>

<style lang="scss">
#zdtj {
  .el-carousel__arrow {
    width: 3.75rem;
    height: 3.75rem;
    color: #d8252d;
    font-size: 2.75rem;
    background-color: rgba(255, 255, 255, 0);
  }
  .el-carousel__arrow:hover {
    background-color: rgba(255, 255, 255, 0);
  }
  .el-carousel__arrow--left,
  .el-notification.left {
    left: -1.25rem;
  }
  .el-carousel__arrow--right,
  .el-notification.right {
    right: -1.25rem;
  }
}
#jizhuan {
  .el-carousel__arrow {
    width: 3.75rem;
    height: 3.75rem;
    color: #d8252d;
    font-size: 2.75rem;
    background-color: rgba(255, 255, 255, 0);
    top: 50%;
    transform: translateY(-105%);
  }
  .el-carousel__arrow:hover {
    background-color: rgba(255, 255, 255, 0);
  }
  .el-carousel__arrow--left,
  .el-notification.left {
    left: -1.125rem;
  }
  .el-carousel__arrow--right,
  .el-notification.right {
    right: -1.25rem;
  }
}
.bannertitle{
  padding:.3125rem .625rem;
  background:rgba(0,0,0,.5);
  color: #FFFFFF;
  font-size:.875rem;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index:2;
  width:100%;

}
.jingxuan-li {
  width: 25%;
  height: 6.25rem;
  border: 1px solid #dadada;
  img {
    width: 8.4375rem;
    height: 100%;
  }
  .a_img_r {
    // width: 9.0625rem;
    flex:1;
    height: 100%;
    border-left: 0px;
    padding: .375rem .5rem 0px .625rem;
    .s1 {
      width: 100%;
      height: 2.75rem;
      line-height: 1.3125rem;
      overflow: hidden;
      color: #333333;
    }
    .s1-2 {
      width: 100%;
      height: 1.25rem;
      line-height: 1.3125rem;
      overflow: hidden;
      color: #333333;
    }
    .s2 {
      width: 100%;
      height: 2.4375rem;
      line-height: 1.8125rem;
      color: #333333;
      .s3 {
        // width: 5rem;
        display: inline-block;
        overflow: hidden;
        float: left;
        text-align: right;
        text-wrap: nowrap;
      }
      .s4 {
        width: 3.3125rem;
        display: inline-block;
        overflow: hidden;
        float: left;
        text-wrap: nowrap;
      }
    }
  }
}
.remen-li {
  width: 16.66%;
  height: 11.125rem;
  padding: .625rem .625rem 0px .625rem;
  border: 1px solid #dadada;
}
.jizhuan-li {
  width: 20%;
  height: 11.125rem;
  padding: .625rem .625rem 0px .625rem;
  border: 1px solid #dadada;
  margin-bottom: 1.25rem;
}
.jizhuan-li > img,
.remen-li > img {
  width: 100%;
  height: 6.5625rem;
}
.sp {
  width: 100%;
  height: 2.6875rem;
  line-height: 2.6875rem;
  display: inline-block;
  overflow: hidden;
  color: #333333;
  font-size: .875rem;
}
.zxl_div_l {
  width: 11.5rem;
  height: 21.0625rem;
  border: 1px solid #dadada;
  margin-right: .9375rem;
  float: left;
  background: url(../../assets/img/e.jpg) bottom -2.8125rem center no-repeat;
  padding: .4375rem 1.25rem 0px 1.25rem;
  img {
    width: 11.5rem;
    height: 11.5rem;
    border-radius: 100%;
  }
  .s1 {
    width: 100%;
    height: 4.375rem;
    line-height: 2.1875rem;
    margin-bottom: .625rem;
    text-align: center;
    overflow: hidden;
    color: #cc0000;
    display: inline-block;
    font-size: 1.5rem;
  }
  .s2 {
    width: 11.5rem;
    height: 12.25rem;
    text-align: center;
  }
  .s3 {
    width: 100%;
    height: 2.75rem;
    line-height: 1.375rem;
    overflow: hidden;
    color: #fff;
    font-size: 1rem;
  }
}
.shopTop {
  position: fixed;
  top: 88%;
  right: 2%;
  z-index: 100;
  margin-top: -20%;
  ul {
    width: 3.125rem;
    height: 18.75rem;
    > li {
      width: 3.125rem;
      height: 3.125rem;
      line-height: 0.9375rem;
      border: 0.0625rem solid #cdcdcd;
      border-bottom: 0;
    }
    > li:last-of-type {
      border-bottom: 0.0625rem solid #cdcdcd;
    }
    > li:hover {
      background: #ff0000;
      color: #fff;
    }
  }
}
.bgh {
  filter: grayscale(1);
}
.el-radio {
  margin-right: 0.3125rem;
}
.ycj {
  position: relative;
}
.yichengjiao {
  position: absolute;
  top: 0;
  left: 0;
  width: 4.0625rem;
  height: 3.125rem;
}
.videobox {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.5);
  padding: 0.9375rem 0.5rem;
  cursor: pointer;
}
.video {
  width: 2.5rem;
  height: 2.5rem;
}
.fx {
  margin-top: 0.625rem;
  padding: 0.5rem 0.75rem;
  color: #11a0a0;
  border: 0.0625rem solid #11a0a0;
  border-radius: 0.625rem;
  font-size: 0.75rem;
}
.classlist2 {
  /* 隐藏滚动条 */
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.classlist2::-webkit-scrollbar {
  display: none;
}
.classlist {
  .el-button {
    line-height: 2.5rem;
    padding: 0;
    width: 100%;
    height: 2.5rem;
    border-radius: 0;
    background: #fafafa;
    border: 1px solid #dadada;
    font-size: 0.75rem;
    font-weight: bold;
    color: #333333;
  }
  .el-button:focus,
  .el-button:hover {
    color: #cc0000;
    border-color: #cc0000 transparent #cc0000 #cc0000;
    background-color: #ffffff;
  }
}
// .el-popover {
//   width: 20.3125rem !important;
//   padding: 0.3125rem 0.9375rem 0.9375rem 0.9375rem !important;
//   height: 18.75rem !important;
//   // overflow-y: auto;
// }
// .el-popover::-webkit-scrollbar {
//   display: none;
// }
.screen {
  .el-input__inner {
    padding: 0 !important;
    padding-right: 0 !important;
    padding-left: 0.625rem !important;
  }
}

.el-carousel__indicator--horizontal{
  padding: 0.75rem 0.25rem !important;
  .el-carousel__button{
    width: 1.875rem;
  }
}
.el-dialog__header{
  padding: 0px;
}
.wh30 {
  .is-circle{
    padding:0.75rem !important;
  }
}
.el-input{
  font-size:.875rem !important;
  .el-input__inner{
    height: 2.5rem !important;
    line-height: 2.5rem !important;
    padding: 0 .9375rem !important;
  }
  .el-input__icon{
    line-height: 2.5rem !important;
  }
  .el-select__caret{
    font-size:.875rem !important;
  }
}
.el-cascader{
  line-height: 2.5rem !important;
  .el-input .el-icon-arrow-down{
    font-size:.875rem !important;
  }
}
.el-radio{
  .el-radio__inner{
    width: .875rem !important;
    height: .875rem !important;
    font-size:.875rem !important;
  }
  .el-radio__label{
    font-size:.875rem !important;
  }
}
.el-pagination{
  .btn-prev,.number,.btn-next{
    min-width: 1.875rem !important;
    height: 1.75rem !important;
    line-height: 1.75rem !important;
    font-size:.875rem !important;
  }
  .el-icon{
    font-size:.875rem !important;
  }
}
</style>
